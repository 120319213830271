const yup = require("../interface/yup-br");

export const CompanySchema = yup.object().shape({
    nomeFantasia: yup.string().required(),
    razaoSocial: yup.string().required(),
    endereco: yup.string(),
    numero: yup.string().required(),
    bairro: yup.string(),
    cep: yup.string(),
    cidade: yup.string(),
    estado: yup.string(),
    responsavel: yup.string().required(),
    email: yup.string().required(),
    telefone: yup.string(),
    celular: yup.string(),
    whatsapp: yup.string(),
    skype: yup.string(),
    logotipo: yup.mixed(),    
});

