import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { BiMenu } from 'react-icons/bi';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { v4 as uuid } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';
import noImage from '../assets/no-image.png';
import Grid from '../components/Grid';
import { BasicInput, CepInput, CheckBoxInput, DBSelectInput, DateInput, DropDownProcessosinput, FormInput, GroupInput, Input, MaskInput, SelectInput } from '../components/Input';
import { showConfirm } from '../components/Messages';
import { ModalManager } from '../components/Modais';
import { dateToString } from '../helper/dates';
import { CompanySchema } from '../validation/CompanySchema';

import { MenuISO_JSON, MenuLGPD_JSON, MenuRDC_JSON, MenuSGI_JSON } from './Database';

const yup = require('../interface/yup-br');

const periodos = [
    { value: 0, option: 'Permanente' },
    { value: 30, option: 'Mensal' },
    { value: 60, option: 'Bimestral' },
    { value: 90, option: 'Trimestral' },
    { value: 180, option: 'Semestral' },
    { value: 365, option: 'Anual' },
    { value: 730, option: 'Bienal' },
    { value: 1095, option: 'Trienal' },
    { value: 1460, option: '4 anos' },
    { value: 1825, option: '5 anos' },
    { value: 3650, option: '10 anos' }
];

const CadastroEmpresa = () => {
    const System = useSystemContext();
    let navigate = useNavigate();
    const [Menu, setMenu] = React.useState(1);
    const [company, setCompany] = React.useState(null);
    const [ImagemEmpresa, setImagemEmpresa] = React.useState(noImage);

    useEffect(() => {
        (async () => {
            try {
                let c = await System.property.company;
                setCompany(c);
                setImagemEmpresa(await System.methods.apiGetImg(c.logotipo));
            } catch (error) {
                console.log(error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    async function handleSubmit(values) {
        var response = null;
        response = await System.apiFormPut('/company/' + System.property.user.companyId, values);
        if (response.status === 200) {
            await System.methods.refreshToken();
            setCompany(values);
            System.methods.notificationSuccess(response.data.message);
            navigate('/empresa', { replace: false });
        } else {
            System.methods.notificationError(response.data.message);
        }
        return false;
    }
    if (System.property.user.level < 2) {
        return <div>Sem Permissão</div>;
    }
    return (
        <Layout>
            <div className="min-h-screen grow-0 bg-yellow-50">
                {MenuEsquerdo()}
                {System.property.user.level >= 10 && MenuCopyPaste()}
                {System.property.user.level >= 10 && MenuDashBoardGenerator()}
            </div>
            <div className="grow bg-yellow-50">
                {Menu === 1 && TelaEmpresa()}
                {Menu === 2 && TelaFuncoes()}
                {Menu === 3 && TelaFuncionario()}
                {Menu === 4 && TelaFornecedores()}
                {Menu === 5 && TelaProcessos()}
                {Menu === 6 && TelaTreinamentos()}
                {Menu === 101 && TelaCopyPaste()}
                {Menu === 103 && TelaForcaMailSender()}
                {Menu === 301 && RebuildDashboard()}
            </div>
            <div className="w-20"></div>
        </Layout>
    );
    function TelaFornecedores() {
        const operacoes = [
            { value: 'lt', option: '< (Menor quê)' },
            { value: 'le', option: '<= (Menor ou igual)' },
            { value: 'eq', option: '= (Igual)' },
            { value: 'ge', option: '>= (Maior ou igual)' },
            { value: 'gt', option: '> (Maior quê)' },
        ];
        return (
            <div className="p-2 m-2 bg-white">
                <Tabs>
                    <TabList>
                        <Tab>
                            <div className="inline-flex justify-center">Tipo de Serviço</div>
                        </Tab>
                        <Tab>
                            <div className="inline-flex justify-center">
                                <span>Categorias</span>
                            </div>
                        </Tab>
                    </TabList>
                    <TabPanel name="servicos">
                        <Grid
                            url={'/griddocument/grid84-servicos'}
                            GridTitle={'Tipos de Serviço'}
                            GridHeaders={[{ title: 'Tipo', field: 'tipo', sort: true, className: 'w-full text-left pl-1' }]}
                            LineDataPrint={(data) => {
                                return [data.tipo];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <Input name="tipo" label="Tipo" />
                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                tipo: yup.string().required('Tipo é obrigatório'),
                            })}
                            FormTabs={[
                                () => {
                                    return <div>Home</div>;
                                },
                                () => {
                                    return <div>Critérios</div>;
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    // Critérios
                                    return (
                                        <Grid
                                            url={`/griddocument/grid84-${item._id}-criterios`}
                                            GridTitle={'Critérios'}
                                            GridHeaders={[
                                                { title: 'Critério', field: 'criterio', sort: true, className: 'w-10/12 text-left pl-1' },
                                                { title: 'Peso', field: 'peso', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Demérito', field: 'demerito', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.criterio, data.peso.toString(), data.demerito.toString()];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <Input name="criterio" label="Critério" />
                                                        <GroupInput>
                                                            <Input type="number" name="peso" label="Peso" />
                                                            <Input type="number" name="demerito" label="Demérito" />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                criterio: yup.string().required('Critério é obrigatório'),
                                                peso: yup.number().required('Peso é obrigatório'),
                                                demerito: yup.number().required('Demérito é obrigatório'),
                                            })}
                                        />
                                    );
                                },
                            ]}
                            onBeforeDelete={async (item) => {
                                await System.apiDelete(`/griddocument/grid84-${item._id}-criterios`);
                            }}
                        />
                    </TabPanel>
                    <TabPanel name="Categorias">
                        <Grid
                            url={'/griddocument/grid84-categorias'}
                            GridTitle={'Categorias'}
                            GridHeaders={[
                                { title: 'Título', field: 'titulo', sort: true, className: 'w-8/12 text-left pl-1' },
                                { title: 'Operação', field: 'operacao', sort: false, className: 'w-3/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Meta', field: 'meta', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            ]}
                            LineDataPrint={(data) => {
                                return [data.titulo, operacoes.find((item) => item.value === data.operacao)?.option, data.meta ? data.meta.toString() : ''];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <Input name="titulo" label="Título" />
                                        <GroupInput>
                                            <SelectInput name="operacao" label="Operação" className={'w-1/3'} listOptions={operacoes} />
                                            <Input type="number" name="meta" label="Meta" />
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                titulo: yup.string().required('Título é obrigatório'),
                            })}
                        />
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
    function MenuEsquerdo() {
        return (
            <div className="ml-2 rounded w-72 bg-yellow-50">
                <div className="inline-flex w-full pl-2 mt-4 text-xl rounded-t h-7 bg-fundo-grupo-menu-lateral text-fonte-grupo-menu-lateral">
                    <BiMenu className="mt-1 mr-1" /> Configuração
                </div>
                <div className="text-left border-b border-l border-r">
                    <div className={`w-full cursor-pointer hover:bg-gray-300 ${Menu === 1 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(1)}>
                        1 - Informações da Empresa
                    </div>
                    <div className={`w-full cursor-pointer hover:bg-gray-300 ${Menu === 2 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(2)}>
                        2 - Funções
                    </div>
                    <div
                        className={`w-full cursor-pointer hover:bg-gray-300 ${Menu === 3 ? 'bg-gray-200' : 'bg-white'}`}
                        onClick={() => {
                            // remove o cache para forçar atualizacao
                            localStorage.removeItem('cache_crew');
                            localStorage.removeItem('cache_crewactive');
                            setMenu(3);
                        }}
                    >
                        3 - Colaboradores
                    </div>
                    <div className={`w-full cursor-pointer hover:bg-gray-300 ${Menu === 4 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(4)}>
                        4 - Provedores Externos
                    </div>
                    <div className={`w-full cursor-pointer hover:bg-gray-300 ${Menu === 5 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(5)}>
                        5 - Processos
                    </div>
                    <div className={`w-full cursor-pointer hover:bg-gray-300 ${Menu === 5 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(6)}>
                        6 - Treinamentos
                    </div>
                </div>
            </div>
        );
    }

    function MenuDashBoardGenerator() {
        return (
            <div className="ml-2 rounded w-72 bg-yellow-50">
                <div className="inline-flex w-full pl-2 mt-4 text-xl rounded-t h-7 bg-fundo-grupo-menu-lateral text-fonte-grupo-menu-lateral">
                    <BiMenu className="mt-1 mr-1" /> Dashboard
                </div>
                <div className={`w-full cursor-pointer pl-2 hover:bg-gray-300 ${Menu === 301 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(301)}>
                    • Forçar atualização
                </div>
            </div>
        );
    }

    function MenuCopyPaste() {
        return (
            <div className="ml-2 rounded w-72 bg-yellow-50">
                <div className="inline-flex w-full pl-2 mt-4 text-xl rounded-t h-7 bg-fundo-grupo-menu-lateral text-fonte-grupo-menu-lateral">
                    <BiMenu className="mt-1 mr-1" /> Outros
                </div>
                <div className="text-left border-b border-l border-r">
                    <div className={`w-full cursor-pointer pl-2 hover:bg-gray-300 ${Menu === 101 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(101)}>
                        1 - Importar dados de outra empresa
                    </div>
                    {'pendenciaMail' in System.property.user && System.property.user.pendenciaMail && (
                        <div className={`w-full cursor-pointer pl-2 hover:bg-gray-300 ${Menu === 103 ? 'bg-gray-200' : 'bg-white'}`} onClick={() => setMenu(103)}>
                            2 - Envio de pendências
                        </div>
                    )}
                </div>
            </div>
        );
    }
    function RebuildDashboard() {
        return (
            <div className="mt-4 ml-2 bg-white rounded grow">
                <div className="pl-2 rounded-t bg-amarelo-eq">Dashboard</div>
                <div className="pt-2 pb-2 pl-2 border shadow">
                    Atualização forçada do Dashboard.
                    <div>
                        <button
                            className="px-4 py-2 mt-2 ml-2 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover"
                            onClick={async () => {
                                showConfirm('Deseja forçar a atualização do Dashboard?', async () => {
                                    setTimeout(async () => {
                                        let response = await System.apiGet('/dashboard-total');
                                        if (response.error) {
                                            System.methods.notificationError(response.error);
                                        } else {
                                            System.methods.notificationInformation('Dashboard recarregado com sucesso.');
                                        }
                                    }, 1);
                                });
                            }}
                        >
                            Executar
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    function TelaCopyPaste() {
        return (
            <div className="mt-4 ml-2 bg-white rounded grow">
                <div className="pl-2 rounded-t bg-amarelo-eq">Importação de dados</div>
                <div className="pt-2 border shadow">
                    <Formik
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        initialValues={{
                            origem: '',
                        }}
                        validationSchema={yup.object().shape({
                            origem: yup.string().required('Informe a origem dos dados.'),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            showConfirm(
                                'Deseja Efetuar a importação dos dados?',
                                async () => {
                                    setTimeout(async () => {
                                        let response = await System.apiPost(`/copypaste/${values.origem}`, values);
                                        if (response.status === 200) {
                                            System.methods.showInfo('Dados importados com sucesso');
                                        } else {
                                            System.methods.showError(response.data.message);
                                        }
                                        setSubmitting(false);
                                    }, 400);
                                },
                                () => {
                                    ModalManager.close();
                                },
                                'Importar',
                                'Cancelar'
                            );
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                            <Form>
                                <div className="p-2 m-4 font-semibold text-center text-red-500 bg-yellow-300 border shadow-md">
                                    Atenção: Este processo é irreversivel e os dados atuais serão substituídos.
                                </div>
                                <DBSelectInput
                                    name="origem"
                                    label="Selecione a empresa de origem dos dados"
                                    url={System.property.user.level > 10 ? '/company?order=nomeFantasia' : `/consulant/${System.property.user.id}`}
                                    displaySelectField="nomeFantasia"
                                />
                                <div className="flex justify-end gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                    <button
                                        type="submit"
                                        className="w-1/3 h-8 text-botao-primario-fonte bg-botao-primario border-1 hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover"
                                        onClick={() => {
                                            displayErrors(errors, touched);
                                        }}
                                    >
                                        Importar
                                    </button>
                                    <button className="w-1/3 h-8 text-black bg-gray-400 rounded-md shadow-md hover:text-gray-100" onClick={() => navigate('/')}>
                                        Cancelar
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
    function TelaForcaMailSender() {
        return (
            <div className="mt-4 ml-2 bg-white rounded grow">
                <div className="pl-2 rounded-t bg-amarelo-eq">Pendencias por email</div>
                <div className="pt-2 border shadow">
                    <Formik
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        initialValues={{}}
                        validationSchema={yup.object().shape({})}
                        onSubmit={(values, { setSubmitting }) => {
                            showConfirm(
                                'Deseja solicitar o envio de pendências?',
                                async () => {
                                    setTimeout(async () => {
                                        let response = await System.apiPost(`/dashboard-force-mailsender`, values);
                                        if (response.status === 200) {
                                            System.methods.showInfo('Solicitação enviada. Processamento sendo feito.');
                                        } else {
                                            System.methods.showError(response.data.message);
                                        }
                                        setSubmitting(false);
                                    }, 400);
                                },
                                () => {
                                    ModalManager.close();
                                },
                                'Solicitar',
                                'Cancelar'
                            );
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                            <Form>
                                <div className="p-2 m-4 font-semibold text-center text-red-500 bg-yellow-300 border shadow-md">
                                    Atenção: Este processo fara o envio <u>imediato</u> de pendências do Dashboard para todos os colaboradores.
                                </div>
                                <div className="ml-4">Este processo é feito normalmente na madrugada de toda segunda-feira.</div>
                                <div className="flex justify-end gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                    <button
                                        type="submit"
                                        className="w-1/3 h-8 text-black bg-yellow-500 rounded-md shadow-md border-1 hover:text-gray-100"
                                        onClick={() => {
                                            displayErrors(errors, touched);
                                        }}
                                    >
                                        Solicitar
                                    </button>
                                    <button className="w-1/3 h-8 text-black bg-gray-400 rounded-md shadow-md hover:text-gray-100" onClick={() => navigate('/')}>
                                        Cancelar
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }

    function displayErrors(errors, touched) {
        if (touched) {
            let lst = Object.keys(errors);
            lst.map((key) => {
                if (errors[key] !== undefined) {
                    System.methods.notificationError(key + ' ' + errors[key]);
                }
                return null;
            });
        }
    }
    function TelaEmpresa() {
        return (
            <>
                <div className="mt-4 ml-2 bg-white grow">
                    <div className="pt-2 border shadow">
                        <Formik
                            _initialValues={{
                                nomeFantasia: '',
                                razaoSocial: '',
                                cnpj: '',
                                endereco: '',
                                numero: '',
                                bairro: '',
                                cep: '',
                                cidade: '',
                                estado: '',
                                responsavel: '',
                                email: '',
                                telefone: '',
                                celular: '',
                                whatsapp: '',
                                skype: '',
                                logotipo: '',
                            }}
                            initialValues={company}
                            enableReinitialize={true}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validationSchema={CompanySchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(async () => {
                                    await handleSubmit(values);
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <GroupInput>
                                        <Input name="nomeFantasia" label="Nome Fantasia" />
                                        <Input name="razaoSocial" label="Razão Social" />
                                    </GroupInput>
                                    <MaskInput
                                        name="cnpj"
                                        label="CNPJ"
                                        className={'w-1/5'}
                                        mask="99.999.999/9999-99"
                                    />
                                    <GroupInput>
                                        <CepInput
                                            name="cep"
                                            label="CEP"
                                            className="w-44"
                                            onReceivedCep={(received) => {
                                                setFieldValue('endereco', received.logradouro);
                                                setFieldValue('cidade', received.localidade);
                                                setFieldValue('estado', received.uf);
                                                setFieldValue('bairro', received.bairro);
                                            }}
                                        />
                                        <Input name="endereco" label="Endereço" />
                                        <Input name="numero" label="Número" className="w-32" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="cidade" label="Cidade" />
                                        <MaskInput
                                            name="estado"
                                            label="Estado"
                                            className="w-48"
                                            mask="aa"
                                            beforeMaskedValueChange={function (newState) {
                                                newState.value = newState.value.toUpperCase();
                                                return newState;
                                            }}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="bairro" label="Bairro" />
                                        <Input name="responsavel" label="Responsável" />
                                    </GroupInput>
                                    <Input name="email" label="E-mail" />
                                    <GroupInput>
                                        <MaskInput name="telefone" label="Telefone" mask="(99) 9999-9999" />
                                        <MaskInput name="celular" label="Celular" mask="(99) 9 9999-9999" />
                                        <MaskInput name="whatsapp" label="Whatsapp" mask="(99) 9 9999-9999" />
                                        <Input name="skype" label="Skype" />
                                    </GroupInput>
                                    <GroupInput>
                                        <img src={ImagemEmpresa} alt="Logotipo" style={{ maxWidth: '150px' }} className="w-40 my-2 ml-2 mr-8" />
                                        <BasicInput
                                            name="logotipo"
                                            label="Logotipo"
                                            type="file"
                                            onChange={(e) => {
                                                setFieldValue('logotipo', e.target.files[0]);
                                                //setImagemEmpresa(URL.createObjectURL(e.target.files[0]));
                                            }}
                                        />
                                    </GroupInput>
                                    <div className="flex justify-end gap-4 pt-4 pb-3 pr-4 mt-4 border-t">
                                        <button
                                            type="submit"
                                            className="w-1/3 h-8 rounded-md shadow-md text-botao-primario-fonte bg-botao-primario border-1 hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover"
                                            onClick={() => {
                                                displayErrors(errors, touched);
                                            }}
                                        >
                                            Salvar
                                        </button>
                                        <button className="w-1/3 h-8 text-black bg-gray-400 rounded-md shadow-md hover:text-gray-100" onClick={() => navigate('/')}>
                                            Cancelar
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </>
        );
    }
    function TelaFuncionario() {
        return (
            <>
                <div className="mt-4 ml-2 bg-white grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/crew'}
                            canDelete={false}
                            disableModal={true}
                            defaultSortField="createdAt"
                            GridHeaders={[
                                { title: 'Id', field: 'identificacao', sort: true, className: 'w-1/12 text-left pl-1' },
                                { title: 'Nome', field: 'nome', sort: true, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Função', field: 'role', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'E-mail', field: 'mail', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Admissão', field: 'admissao', sort: false, type: 'date', className: 'w-1/12 pl-1 text-left border-l border-r border-gray-300' },
                                { title: 'Master', field: 'master', sort: false, className: 'w-1/12 pl-1 text-center border-l border-r border-gray-300' },
                                { title: 'Status', field: 'status', sort: false, className: 'w-1/12 pl-1 text-center border-l border-r border-gray-300' },
                            ]}
                            GridTitle="Lista de Colaboradores"
                            LineDataPrint={(data) => {
                                return [
                                    data.identificacao,
                                    data.nome,
                                    data.funcao?.nome,
                                    data.email,
                                    dateToString(data.admissao),
                                    data?.master ? '#color=blue:Master' : '',
                                    data?.status,
                                ];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                        </div>
                                        <Input name="nome" label="Nome" />
                                        <GroupInput>
                                            <Input name="identificacao" label="Identificação" />
                                            <DBSelectInput name="role" label="Função" url="/role" displaySelectField="nome" />
                                            <DateInput name="admissao" label="Admissão" />
                                        </GroupInput>
                                        <div className={'ml-2 mt-4 bg-amarelo-eq'}>Acesso ao Sistema</div>
                                        <GroupInput>
                                            <Input name="email" label="Email" type="email" className={'w-4/6'} />
                                            <Input name="senha" label="Senha" className={'w-2/6'} />
                                        </GroupInput>
                                        <GroupInput>
                                            {System.property.user.companyNorma &&
                                                <DropDownProcessosinput
                                                    name="vinculoProcessos"
                                                    label="Vinculo com Processos/Subprocessos"
                                                    options={[
                                                        {
                                                            label: "Gerente e Gestão",
                                                            options: [
                                                                { label: "QSMS", option: "15fb0fd319df3b52df3fc205" },
                                                                { label: "Departamento Pessoal", option: "249adc110e2bfbfd10d668e4" },
                                                                { label: "Recursos Humanos", option: "35fb02d319df3b52df3fc205" },
                                                            ],
                                                        },
                                                        {
                                                            label: "CRM",
                                                            options: [
                                                                { label: "CRM (Petróleo Marítimo Comercial)", option: "45fb0fde19df3b52df3fc212" },
                                                            ],
                                                        },
                                                    ]}
                                                />
                                            }
                                            <SelectInput
                                                name="status"
                                                label="Status colaborador"
                                                className={'inline-block'}
                                                listOptions={[{ value: 'Inativo' }, { value: 'Ativo' }]}
                                            />
                                            <div className="px-2 pt-3 mt-2 ml-4 mr-2 bg-yellow-200 border rounded shadow min-w-fit">
                                                <CheckBoxInput name="master" label="Usuário master" />
                                            </div>
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                nome: yup.string().required('Nome é obrigatório'),
                                identificacao: yup.string().required('Identificação é obrigatório'),
                                role: yup.string().required('Função é obrigatório'),
                                admissao: yup.string().required('Admissão é obrigatório'),
                                email: yup.string(),
                                senha: yup.string(),
                                status: yup.string().required('Status é obrigatório'),
                                master: yup.boolean(),
                            })}
                        />
                    </div>
                </div>
            </>
        );
    }

    function TelaFuncoes() {
        let menu_json = [];
        let permissoes = [];
        switch (System.property.user.companyNorma) {
            case 'RDC-665':
                menu_json = MenuRDC_JSON();
                break;
            case 'SGI':
                menu_json = MenuSGI_JSON();
                break;
            case 'ISO-9001':
                menu_json = MenuISO_JSON();
                break;
            case 'LGPD':
                menu_json = MenuLGPD_JSON();
                break;
            default:
                menu_json = MenuSGI_JSON();
                break;
        }
        Object.entries(menu_json).map(([key, value]) => {
            let clickSelf = null;
            if (value.hasOwnProperty('self')) {
                clickSelf = value.self;
            }
            if (clickSelf) {
                permissoes[permissoes.length] = { id: clickSelf, dsc: `${value.menu}` };
            }
            return value.submenu.map((sub) => {
                let [id, dsc] = Object.entries(sub)[0];
                if (System.property.user.companyNorma === 'RDC-665') {
                    permissoes[permissoes.length] = { id: id, dsc: `${key} - ${dsc}` };
                } else {
                    permissoes[permissoes.length] = { id: id, dsc: dsc };
                }
                return true;
            });
        });

        function btnFunc(setFieldValue, value) {
            permissoes.map((item) => {
                setFieldValue(`menu${item.id}`, value);
                return true;
            });
        }
        function btnFuncAlterar(setFieldValue, value) {
            permissoes.map((item) => {
                if (value) setFieldValue(`menu${item.id}`, value);
                setFieldValue(`alterar${item.id}`, value);
                return true;
            });
        }
        function btnFuncDeletar(setFieldValue, value) {
            permissoes.map((item) => {
                if (value) setFieldValue(`menu${item.id}`, value);
                if (value) setFieldValue(`alterar${item.id}`, value);
                setFieldValue(`deletar${item.id}`, value);
                return true;
            });
        }
        function lineGrid(item, setFieldValue) {
            return (
                <div className="flex border-gray-200 rounded">
                    <div className="flex w-1/12 text-center border-t border-l">
                        <CheckBoxInput name={`menu${item.id}`} />
                    </div>
                    <div className="flex w-1/12 text-center border-t border-l">
                        <CheckBoxInput
                            name={`alterar${item.id}`}
                            onChange={(e) => {
                                if (e.target.checked) setFieldValue(`menu${item.id}`, true);
                                setFieldValue(`alterar${item.id}`, e.target.checked);
                            }}
                        />
                    </div>
                    <div className="flex w-1/12 text-center border-t border-l">
                        <CheckBoxInput
                            name={`deletar${item.id}`}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setFieldValue(`menu${item.id}`, true);
                                    setFieldValue(`alterar${item.id}`, true);
                                }
                                setFieldValue(`deletar${item.id}`, e.target.checked);
                            }}
                        />
                    </div>
                    <div className="flex w-9/12 border-t border-l border-r">
                        <span className="w-full ml-2">{item.dsc}</span>
                    </div>
                </div>
            );
        }

        return (
            <>
                <div className="mt-4 ml-2 bg-white rounded grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/role/' + System.property.company._id}
                            GridTitle="Funções dos Colaboradores"
                            canDelete={true}
                            GridHeaders={[{ title: 'Nome da Função', field: 'nome', sort: true, className: 'w-full text-left pl-1' }]}
                            disableModal={true}
                            LineDataPrint={(data) => {
                                return [data.nome];
                            }}
                            validationSchema={yup.object().shape({
                                nome: yup.string().required('Nome da Função é obrigatória'),
                            })}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <GroupInput>
                                            <Input name="nome" label="Nome da função" />
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            FormTabs={[
                                () => {
                                    return <div>Home</div>;
                                },
                                () => {
                                    return <div>Permissões</div>;
                                },
                                () => {
                                    return <div>Matriz de Treinamentos</div>;
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    // Permissões
                                    return (
                                        <div>
                                            <FormInput
                                                document={`/role/${System.property.company._id}/${item._id}`}
                                                validationSchema={yup.object().shape({
                                                    nome: yup.string(),
                                                })}
                                                initialValues={{
                                                    nome: '',
                                                }}
                                                onRenderFields={(setFieldValue, values) => {
                                                    return (
                                                        <div key={uuid()}>
                                                            <div className="container mx-auto mt-4 ml-2 mr-2">
                                                                <div className="flex border-gray-200">
                                                                    <div className="flex w-1/12 bg-gray-100 border-t border-l">
                                                                        <span className="w-full text-center">Consultar</span>
                                                                    </div>
                                                                    <div className="flex w-1/12 bg-gray-100 border-t border-l">
                                                                        <span className="w-full text-center">Alterar</span>
                                                                    </div>
                                                                    <div className="flex w-1/12 bg-gray-100 border-t border-l">
                                                                        <span className="w-full text-center">Deletar</span>
                                                                    </div>
                                                                    <div className="flex w-9/12 text-left bg-gray-100 border-t border-l border-r">
                                                                        <span className="w-full ml-2">Descrição</span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex border-gray-200 rounded">
                                                                    <div className="flex justify-center w-1/12 border-t border-l">
                                                                        <ImCheckboxChecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-blue-700"
                                                                            title="Marcar todos"
                                                                            onClick={() => btnFunc(setFieldValue, true)}
                                                                        />
                                                                        <ImCheckboxUnchecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-red-700"
                                                                            title="Desmarcar todos"
                                                                            onClick={() => btnFunc(setFieldValue, false)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex justify-center w-1/12 border-t border-l">
                                                                        <ImCheckboxChecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-blue-700"
                                                                            title="Marcar todos"
                                                                            onClick={() => btnFuncAlterar(setFieldValue, true)}
                                                                        />
                                                                        <ImCheckboxUnchecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-red-700"
                                                                            title="Desmarcar todos"
                                                                            onClick={() => btnFuncAlterar(setFieldValue, false)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex justify-center w-1/12 border-t border-l">
                                                                        <ImCheckboxChecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-blue-700"
                                                                            title="Marcar todos"
                                                                            onClick={() => btnFuncDeletar(setFieldValue, true)}
                                                                        />
                                                                        <ImCheckboxUnchecked
                                                                            className="mr-1 text-sm hover:cursor-pointer hover:text-red-700"
                                                                            title="Desmarcar todos"
                                                                            onClick={() => btnFuncDeletar(setFieldValue, false)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex w-9/12 border-t border-l"></div>
                                                                </div>

                                                                {permissoes.map((item) => {
                                                                    return lineGrid(item, setFieldValue);
                                                                })}
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                },
                                (GridModes, item, GridRefresh) => {
                                    // Treinamentos
                                    return (
                                        <div>
                                            <Grid
                                                url={`/treinamentofuncao/${item._id}`}
                                                key={uuid()}
                                                GridTitle="Treinamentos aplicados por função"
                                                disableModal={true}
                                                canDelete={false}
                                                GridHeaders={[
                                                    { title: 'Treinamento', field: 'treinamento_id', sort: true, filter: true, className: 'w-1/2 text-left pl-1' },
                                                    { title: 'Periodicidade', field: 'periodicidade', sort: true, filter: true, className: 'w-1/2 text-left pl-1' },
                                                ]}
                                                LineDataPrint={(item) => {
                                                    return [
                                                        item.treinamento.treinamento,
                                                        periodos.find((x) => x.value === item.treinamento.periodicidade)?.option
                                                    ];
                                                }}
                                                onRenderForm={(GridModes, SetFieldValue, values) => {
                                                    return (
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="treinamento_id"
                                                                label="Treinamento"
                                                                url={`/treinamento`}
                                                                displaySelectField="treinamento"
                                                                returnField={'_id'}
                                                            />

                                                        </GroupInput>
                                                    );
                                                }}
                                                validationSchema={yup.object().shape({
                                                    treinamento_id: yup.string().required('Treinamento é obrigatório'),
                                                })}
                                            />

                                        </div>
                                    );
                                },
                            ]}
                        />
                    </div>
                </div>
            </>
        );
    }
    function TelaProcessos() {
        return (
            <>
                <div className="mt-4 ml-2 bg-white rounded grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/process'}
                            GridTitle="Funções dos Colaboradores"
                            canDelete={true}
                            disableModal={true}
                            GridHeaders={[
                                {
                                    title: 'Processo', field: 'processo', sort: true, filter: true, className: 'w-full text-left pl-1'
                                }]}
                            LineDataPrint={(item) => {
                                return [item.processo];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <Input name="processo" label="Processo" />
                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                processo: yup.string().required('Nome do processo é obrigatório'),
                            })}
                            FormTabs={[
                                () => {
                                    return (<div>
                                        Processo
                                    </div>)
                                        ;
                                },
                                () => {
                                    return (
                                        <div>Subprocessos</div>);
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <>
                                            <div className='pl-1 mt-1 mb-1 ml-2 mr-2 border rounded'>
                                                Processo atual: {item.processo}
                                            </div>
                                            <Grid
                                                url={`/subprocess/${item._id}`}
                                                GridTitle="Subprocessos"
                                                canDelete={true}
                                                GridHeaders={[
                                                    {
                                                        title: 'Subprocesso', field: 'subprocesso', sort: true, filter: true, className: 'w-full text-left pl-1'
                                                    }]}
                                                LineDataPrint={(item) => {
                                                    return [item.subprocesso];
                                                }}
                                                onRenderForm={(GridModes, SetFieldValue, values) => {
                                                    return (
                                                        <div>
                                                            <Input name="subprocesso" label="Subprocesso" />
                                                        </div>
                                                    );
                                                }}
                                                validationSchema={yup.object().shape({
                                                    subprocesso: yup.string().required('Nome do Subprocesso é obrigatório'),
                                                })}
                                            />
                                        </>
                                    );
                                },
                            ]}
                        />
                    </div>
                </div>
            </>
        );
    }
    function TelaTreinamentos() {
        return (
            <>
                <div className="mt-4 ml-2 bg-white rounded grow">
                    <div className="w-full border shadow">
                        <Grid
                            url={'/treinamento'}
                            key={uuid()}
                            GridTitle="Treinamentos aplicados a colaboradores"
                            disableModal={true}
                            canDelete={true}
                            GridHeaders={[
                                {
                                    title: 'Treinamento', field: 'treinamento', sort: true, filter: true, className: 'w-1/2 text-left pl-1'
                                }, {
                                    title: 'Periodicidade', field: 'periodicidade', sort: true, filter: true, className: 'w-1/2 text-left pl-1'
                                }, {
                                    title: 'Alerta', field: 'alerta', sort: true, filter: true, className: 'w-1/2 text-left pl-1'
                                }]}
                            LineDataPrint={(item) => {
                                return [
                                    item.treinamento,
                                    periodos.find((x) => x.value === item.periodicidade)?.option,
                                    `${item.alerta} dias`
                                ];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <GroupInput>
                                            <Input name="treinamento" label="Treinamento" />
                                        </GroupInput>
                                        <GroupInput>
                                            <SelectInput
                                                name="periodicidade"
                                                label="Periodicidade"
                                                className={'w-2/6'}
                                                listOptions={periodos}
                                            />
                                        </GroupInput>
                                        <GroupInput>
                                            <SelectInput
                                                name="alerta"
                                                label="Antecedência do alerta"
                                                className={'w-2/6'}
                                                disabled={values.periodicidade === 0}
                                                listOptions={[
                                                    { value: 10, option: '10 dias' },
                                                    { value: 30, option: '30 dias' },
                                                    { value: 60, option: '60 dias' },
                                                    { value: 90, option: '90 dias' },
                                                    { value: 180, option: '180 dias' },
                                                    { value: 365, option: '1 ano' }
                                                ]}
                                            />
                                        </GroupInput>

                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                treinamento: yup.string().required('Treinamento é obrigatório'),
                            })}
                        />
                    </div>
                </div>
            </>
        );
    }
};

export default CadastroEmpresa;
