//import api from "../interface/api";
import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import { useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { BsShieldFillCheck, BsShieldFillExclamation, BsShieldFillX } from 'react-icons/bs';
import Grid from '../components/Grid';
import { ErrorModal, InfoModal } from '../components/Messages';
import { Modal, ModalManager } from '../components/Modais';
import { companyMenu } from '../pages/Database';

import logotipoPadrao from '../assets/logo-escola.png';

import { Config } from '../config/config';

export const useSystemContext = () => {
    const baseURL = Config().apiUrl;
    const api = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });

    let metadata = [];

    api.interceptors.request.use(async (config) => {
        let token = await localStorage.getItem('token');
        if (!token) {
            return config;
        } else {
            config.headers.authorization = 'Bearer ' + localStorage.getItem('token');
            config.withCredentials = false;
            const user = jwt_decode(token);
            const isExpired = dayjs.unix(user.exp).isBefore(dayjs());
            if (!isExpired) return config;
            console.log('---[ Referesh Token ]---');
            await axios
                .post(`${baseURL}/token-refresh`, { refresh: token })
                .then((response) => {
                    if (response.status === 200) {
                        localStorage.setItem('token', response.data.token);
                        config.headers.authorization = 'Bearer ' + localStorage.getItem('token');
                        return config;
                    } else {
                        console.log('Token [-_-]', response);
                        return null;
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        if (window.location.href.indexOf('/login') === -1) {
                            window.location.href = '/login';
                        }
                    }
                    console.log('[RT 1]', error);
                });
            return config;
        }
    });

    api.interceptors.response.use(
        (response) => {
            if (response.data.status === 401) {
                if (window.location.href.indexOf('/login') === -1) {
                    window.location.href = '/login';
                }
            }
            return response;
        },
        (error) => {
            if (error.response?.status === 401 || !error.response?.status) {
                internalNotifyError('Sessão expirada, faça login novamente');
                window.location.href = '/login';
            }
            return Promise.reject(error);
        }
    );

    function _decodeUser() {
        const token = localStorage.getItem('token');
        if (!token) return {};
        return jwt_decode(localStorage.getItem('token'));
    }

    function pesquisaMenuItem(objeto, valor) {
        for (var chave in objeto) {
            let item = objeto[chave];
            if ('self' in item && item.self === valor) return item.menu;
            if ('submenu' in item) {
                let subMenu = item.submenu;
                for (let i = 0; i < subMenu.length; i++) {
                    let subItem = subMenu[i];
                    let subItemValue = Object.values(subItem)[0];
                    if (parseInt(Object.keys(subItem)[0]) === parseInt(valor)) return subItemValue;
                }
            }
        }
        return null;
    }

    const [property, setProperty] = useState({
        apiUrl: Config().apiUrl,
        user: useMemo(() => {
            return _decodeUser();
        }, []),
        userRules: useMemo(() => {
            try {
                let rules = localStorage.getItem('userRules');
                return JSON.parse(rules);
            } catch (error) {
                return [];
            }
        }, []),
        logged: useMemo(() => localStorage.getItem('token') !== null, []),
        company: useMemo(async () => {
            try {
                let c = await JSON.parse(localStorage.getItem('company'));
                return c;
            } catch (error) {
                return null;
            }
        }, []),
    });

    const methods = {
        metadata: (data) => {
            metadata = [...metadata, data];
            return metadata;
        },
        setProperty: (data) => {
            setProperty({ ...property, ...data });
        },
        getUserRule: (rule = null) => {
            if (property.user.level >= 5) return true;
            let res = false;
            if (!property?.userRules) return false;
            if (rule in property?.userRules) res = property?.userRules[rule] ? true : false;
            return res;
        },
        selectCompany: internalSelectCompany,
        getCompany: internalGetCompany,
        apiGetImg: internalApiGetImg,
        notificationError: (text) => {
            internalNotifyError(text);
        },
        getCompanyLogo: internalGetCompanyLogo,
        /** 
         * @argument text { string }
         **/
        showInfo: (text) => {
            ModalManager.open(<InfoModal text={text} onRequestClose={() => true} />);
        },
        /**
         * @argument text { string }
         **/
        showError: (text) => {
            ModalManager.open(<ErrorModal text={text} onRequestClose={() => true} />);
        },
        /**
         * @argument text { string }
         **/
        notificationLoading: (text) => {
            toast.loading(text);
        },
        /** 
         * @argument loading { boolean }
         **/
        Loading: (loading) => {
            SetLoading(loading);
        },
        refreshToken: () => {
            const token = localStorage.getItem('token');
            if (token) {
                return api.post('/token-refresh', { refresh: token }).then((response) => {
                    if (response.status === 200) {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('company', JSON.stringify(response.data.company));
                        localStorage.setItem('userRules', JSON.stringify(response.data.userRules));
                        methods.setProperty({ user: _decodeUser(), company: response.data.company, userRules: response.data.userRules });
                        return true;
                    }
                    if (response.status === 401) {
                        //window.location.href = "/login";
                        console.log('zzz 7');
                        return false;
                    }
                });
            } else {
                return false;
            }
        },
        notificationSuccess: (text) => {
            toast.custom(
                <div className="flex rounded shadow border-1">
                    <div className="w-10 bg-green-500 rounded-l">
                        <BsShieldFillCheck className="mt-2 ml-2 text-2xl text-white" />
                    </div>
                    <div className="inline-flex px-6 py-2 text-green-700 bg-green-300 rounded-r">{text}</div>
                </div>
            );
        },
        notificationInformation: (text) => {
            toast.custom(
                <div className="flex rounded shadow border-1">
                    <div className="w-10 bg-yellow-500 rounded-l">
                        <BsShieldFillExclamation className="mt-2 ml-2 text-2xl text-white" />
                    </div>
                    <div className="inline-flex px-6 py-2 text-yellow-700 bg-yellow-300 rounded-r">{text}</div>
                </div>
            );
        },
    };

    function internalSetCache(key, data) {
        const now = new Date().getTime();
        let cache = {
            data: data,
            timestamp: now,
        };
        localStorage.setItem(`cache_${key}`, JSON.stringify(cache));
        return data;
    }
    function internalGetCache(key) {
        const cacheContent = localStorage.getItem(`cache_${key}`);
        if (!cacheContent) {
            return null; // Retorna null se não houver cache
        }
        const cache = JSON.parse(cacheContent);
        const now = new Date().getTime(); // Tempo atual
        const CACHE_EXPIRATION = 30 * 60 * 1000; // 30 minutos em milissegundos
        const diff = now - cache.timestamp; // Calcula a diferença desde o último cache

        if (diff < CACHE_EXPIRATION) {
            return cache.data; // Retorna os dados se ainda estiverem válidos
        }
        return null; // Retorna null se o cache expirou
    }

    async function internalGetCompanyLogo() {
        if (property.company instanceof Promise) {
            property.company = await property.company;
        }
        if ('consultor' in property.company) {
            if (property.company.consultor?.usarlogotipo) {
                return await api.get('/rawimage/' + property.company?.consultor?.logotipo, { responseType: 'blob' }).then((response) => {
                    const file = new Blob([response.data]);
                    return URL.createObjectURL(file);
                });
            }
        }
        return logotipoPadrao;
    }

    async function internalApiGetImg(fileId, query = null) {
        return await api
            .get('/rawimage/' + fileId, { responseType: 'blob' })
            .then((response) => {
                const file = new Blob([response.data]);
                return URL.createObjectURL(file);
            })
            .catch((error) => {
                return error.response;
            });
    }

    async function internalGetCompany() {
        let _user = _decodeUser();
        let response = await api.get(`/company/${_user.companyId}`);
        if (response.status === 200) {
            if (response.data.consulant) {
                let consultor = await api.get('/user/' + response.data.consulant?.user_id);
                if (consultor.status === 200) {
                    response.data.consultor = consultor.data;
                }
            }
        }

        return response.data;
    }

    function internalSelectCompany(_userHook) {
        ModalManager.open(
            <Modal onRequestClose={() => true} style={{ content: { maxHeight: '600px', minWidth: '400px', maxWidth: '600px' } }}>
                <div className="absolute z-auto cursor-pointer top-1 right-1 hover:bg-gray-500" onClick={() => ModalManager.close()}>
                    <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="#000000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        name="feather feather-x-square"
                    >
                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                    </svg>
                </div>
                <Grid
                    url={property.user.level > 10 ? `/company` : `/consulant/${property.user.id}`}
                    GridHeaders={[
                        { title: 'Nome Fantasia', field: 'nomeFantasia', sort: true, className: 'w-11/12 text-left pl-1' },
                        { title: 'Norma', field: 'norma', sort: true, className: 'w-1/12 text-left pl-1' },
                    ]}
                    GridTitle="Empresas"
                    canEdit={false}
                    canDelete={false}
                    canInsert={false}
                    canShow={false}
                    hideActionsButtons={true}
                    defaultSortField="nomeFantasia"
                    hideIndexColumn={false}
                    LineDataPrint={(item) => {
                        return [item.nomeFantasia ?? item.company.nomeFantasia, item.norma];
                    }}
                    onSelectItem={async (item) => {
                        let token = localStorage.getItem('token');
                        let cID = 0;
                        if (property.user.level === 99) cID = item._id; // Admin
                        else cID = item.company._id; // Consultor
                        api.post('/user/setcompany/' + property.user.id, { company_id: cID }).then((companyResponse) => {
                            localStorage.setItem('company', JSON.stringify(companyResponse.data.company));
                            api.post('/token-refresh', { refresh: token }).then((tokenResponse) => {
                                if (tokenResponse.status === 200) {
                                    localStorage.removeItem('cache_crew');
                                    localStorage.removeItem('cache_crewactive');
                                    localStorage.setItem('token', tokenResponse.data.token);
                                    methods.setProperty({ user: jwt_decode(tokenResponse.data.token), company: companyResponse.data.company });
                                    ModalManager.close();
                                    _userHook(tokenResponse.data);
                                }
                            });
                        });
                    }}
                />
            </Modal>
        );
    }

    function internalNotifyError(text) {
        toast.custom(
            <div className="flex rounded shadow border-1">
                <div className="w-10 bg-red-500 rounded-l">
                    <BsShieldFillX className="mt-2 ml-2 text-2xl text-white" />
                </div>
                <div className="inline-flex px-6 py-2 text-red-700 bg-red-300 rounded-r">
                    {text}
                </div>
            </div>
        );
    }

    function SetLoading(loading) {
        const loadingElement = document.getElementById('loading');
        if (loading) {
            if (!loadingElement) {
                // Cria o elemento de loading se não existir
                const loadingDiv = document.createElement('div');
                loadingDiv.id = 'loading';
                loadingDiv.style.position = 'fixed';
                loadingDiv.style.top = '0';
                loadingDiv.style.left = '0';
                loadingDiv.style.width = '100%';
                loadingDiv.style.height = '100%';
                loadingDiv.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
                loadingDiv.style.display = 'flex';
                loadingDiv.style.alignItems = 'center';
                loadingDiv.style.justifyContent = 'center';
                loadingDiv.style.zIndex = '9999';

                const loadingSpinner = document.createElement('div');
                loadingSpinner.style.border = '4px solid #f3f3f3';
                loadingSpinner.style.borderRadius = '50%';
                loadingSpinner.style.borderTop = '4px solid #00AFEF'; // Substitua com a cor desejada
                loadingSpinner.style.width = '32px';
                loadingSpinner.style.height = '32px';
                loadingSpinner.style.animation = 'spin 0.5s linear infinite';

                loadingDiv.appendChild(loadingSpinner);
                document.body.appendChild(loadingDiv);
            } else {
                // Torna o elemento de loading visível
                loadingElement.style.visibility = 'visible';
            }
        } else {
            // Oculta o elemento de loading
            if (loadingElement) {
                loadingElement.style.visibility = 'hidden';
            }
        }
    }

    return {
        property,
        methods,
        getBaseUrl() {
            return baseURL;
        },
        getAutoEdit() {
            let AE = localStorage.getItem('autoEdit');
            if (AE === null) AE = false;
            return AE;
        },
        delAutoEdit() {
            localStorage.removeItem('autoEdit');
            return true;
        },
        isAutoEdit() {
            let AE = localStorage.getItem('autoEdit');
            if (typeof AE === 'string' && AE.length === 24) {
                return true;
            }
            return false;
        },
        setAutoEdit(value) {
            if (value.length !== 24) value = false;
            localStorage.setItem('autoEdit', value);
        },
        setCurrentCompany(companyId) {
            let _user = _decodeUser();
            if (companyId === _user.companyId) return true;
            let token = localStorage.getItem('token');
            return api.post('/user/setcompany/' + property.user.id, { company_id: companyId })
                .then((companyResponse) => {
                    localStorage.setItem('company', JSON.stringify(companyResponse.data.company));
                    return api.post('/token-refresh', { refresh: token })
                        .then((tokenResponse) => {
                            if (tokenResponse.status === 200) {
                                localStorage.removeItem('cache_crew');
                                localStorage.removeItem('cache_crewactive');
                                localStorage.setItem('token', tokenResponse.data.token);
                                return true;
                            }
                            return false;
                        })
                });

        },
        getMenuTitle(item) {
            let menu = companyMenu(property.company.norma);
            let res = pesquisaMenuItem(menu, item);
            return res;
        },
        async getUserCrew() {
            let _user = _decodeUser();
            await api
                .get(`/crewbyuserid/${_user.id}`)
                .then((response) => {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return null;
                    }
                })
                .catch((error) => {
                    console.log('decodeUserRule error', error);
                    return null;
                });
        },
        isLogged() {
            if (!localStorage.getItem('token')) return false;
            return api.get('/token-check').then((response) => {
                if (response.status === 200) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        login(payload) {
            let newForm = new FormData();
            for (let key in payload) {
                newForm.append(key, payload[key]);
            }
            return api
                .post('/login', payload)
                .then((response) => {
                    if (response.status === 200) {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('company', JSON.stringify(response.data.company));
                        localStorage.setItem('userRules', JSON.stringify(response.data.userRules));
                        // zera cache
                        localStorage.removeItem('cache_crew');
                        localStorage.removeItem('cache_crewactive');
                        localStorage.removeItem('autoEdit');
                        methods.setProperty({ user: _decodeUser(), company: response.data.company, userRules: response.data.userRules });
                        return response;
                    } else {
                        console.log('delete token');
                        localStorage.removeItem('token');
                        return response;
                    }
                })
                .catch((error) => {
                    console.log('login error', error);
                    return error.response;
                });
        },
        async logout() {
            await api.post('/logout');
            localStorage.removeItem('token');
            return true;
        },
        apiFormPost(url, payload) {
            let newForm = new FormData();
            for (let key in payload) {
                newForm.append(key, payload[key]);
            }
            return api
                .post(url, newForm)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        //localStorage.removeItem('token');
                        //window.location.href = "/login";
                        console.log('zzz 2');
                    }
                    return error.response;
                });
        },
        apiPost(url, payload) {
            return api
                .post(url, payload)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        //localStorage.removeItem('token');
                        //window.location.href = "/login";
                        console.log('zzz 3');
                    }
                    return error.response;
                });
        },
        apiPut(url, payload) {
            return api
                .put(url, payload)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        //localStorage.removeItem('token');
                        //window.location.href = "/login";
                        console.log('zzz 4');
                    }
                    return error.response;
                });
        },
        apiFormPut(url, payload) {
            let newForm = new FormData();
            for (let key in payload) {
                newForm.append(key, payload[key]);
            }
            return api
                .put(url, newForm)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        //localStorage.removeItem('token');
                        //window.location.href = "/login";
                        console.log('zzz 5');
                    }
                    return error.response;
                });
        },
        apiGet(url, query = null, ignoreCache = false) {
            if (!ignoreCache) {
                if (url === '/_crew') {
                    let cache = internalGetCache('crew');
                    if (cache !== null) {
                        return new Promise((resolve, reject) => {
                            resolve({ status: 200, data: cache });
                        });
                    }
                }
                if (url === '/_crewactive') {
                    let cache = internalGetCache('crewactive');
                    if (cache !== null) {
                        return new Promise((resolve, reject) => {
                            resolve({ status: 200, data: cache });
                        });
                    }
                }
            }

            return api
                .get(url, { params: { filter: query } })
                .then((response) => {
                    if (response && response.data) {
                        if (url === '/_crew') internalSetCache('crew', response.data);
                        if (url === '/_crewactive') internalSetCache('crewactive', response.data);
                    }
                    return response;
                })
                .catch((error) => {
                    console.log('Erro capturado (api.get):', error);
                    if (error.response?.status === 401) {
                        //localStorage.removeItem('token');
                        window.location.href = '/login';
                        console.log('zzz 6');
                        return error;
                    }
                    if (error.response?.status === 500) {
                        if (error?.response?.data?.message) {
                            internalNotifyError(error.response.data.message);
                        } else {
                            internalNotifyError('500: Erro interno no servidor');
                        }
                        return error;
                    }
                    return error.response;
                });
        },
        apiGetFileInfo(file, query = null) {
            return api
                .get('/fileinfo/' + file)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return error.response;
                });
        },
        apiGetRawFile(file, query = null) {
            /*
            return api.get('/rawimage/' + file, { responseType: 'blob' }).then((response) => {
                return response.data;
            }).catch((error) => {
                return error.response;
            });
            */
            return api.get('/rawimage/' + file, { responseType: 'blob' });
        },
        apiGetFile(file, query = null) {
            return api
                .get('/file/' + file, { responseType: 'blob' })
                .then((response) => {
                    const file = new Blob([response.data]);
                    return file;
                })
                .catch((error) => {
                    return error.response;
                });
        },
        apiDelete(url) {
            return api
                .delete(url)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        console.log('delete token apiDelete');
                        localStorage.removeItem('token');
                    }
                    return error.response;
                });
        },
        getURLParameters(paramName) {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var c = url.searchParams.get(paramName);
            return c;
        },
    };
};
