export function normalizeText(text) {
    let decodedStr = decodeURIComponent(text);
    let normalizedStr = decodedStr.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    normalizedStr = normalizedStr.replace(/\./g, '');

    return normalizedStr;
}

/*
    if (!text) return '';
    const normalizedText = text
        .replaceAll(/[áàâãä]/gi, "a")
        .replaceAll(/[éèêë]/gi, "e")
        .replaceAll(/[íìîï]/gi, "i")
        .replaceAll(/[óòôõö]/gi, "o")
        .replaceAll(/[ç]/gi, "c")
        .replaceAll(/[úùûü]/gi, "u")
        .replace(/[^\w\s]/gi, "")
        .replace(/\s+/g, "-")
        .toLowerCase();
    return normalizedText;
}
*/
