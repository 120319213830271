import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSystemContext } from '../Context/SystemContext';

const ClassItem = 'pl-2 py-4 pr-2 cursor-pointer hover:text-white hover:bg-fundo-menu-hover';

export const BaseMenu = () => {
    let navigate = useNavigate();
    let System = useSystemContext();
    const [logo, setLogo] = React.useState();

    useEffect(() => {
        (async () => {
            try {
                setLogo(await System.methods.getCompanyLogo());
            } catch (error) {
                console.log(error);
            }
        })();

        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="absolute left-0 min-w-120 min-h-53">
                {logo && (
                    <img src={logo} onClick={() => navigate('/', { replace: false })} alt="logo" className="w-40 pl-4 pr-6 overflow-hidden cursor-pointer max-w-120 max-h-53" />
                )}
            </div>
            <div className="inline-flex ml-36">
                <ul className="flex items-center h-14">
                    <li onClick={() => navigate('/dashboard', { replace: false })} className={ClassItem}>
                        Dashboard
                    </li>
                    <li onClick={() => navigate('/database', { replace: false })} className={ClassItem}>
                        Banco de Dados
                    </li>
                    {System.property.user.level >= 5 && (
                        <li onClick={() => navigate('/empresa', { replace: false })} className={ClassItem}>
                            Configuração
                        </li>
                    )}
                    <li onClick={() => navigate('/registrofotografico', { replace: false })} className={ClassItem}>
                        Registro Fotográfico
                    </li>
                    {System.property.user.level === 99 && (
                        <li onClick={() => navigate('/admin', { replace: false })} className={ClassItem}>
                            Admin
                        </li>
                    )}
                    {System.property.user.level >= 10 && (
                        <li onClick={() => navigate('/consultor', { replace: false })} className={ClassItem}>
                            Consultor
                        </li>
                    )}
                    {System.property.user.level < 10 && (
                        <li onClick={() => navigate('/ajuda', { replace: false })} className={ClassItem}>
                            Solicitar Suporte
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};
