import { Toaster } from 'react-hot-toast';
import Router from './pages/Router';

function App() {
    return (
        <div className="h-screen bg-yellow-50 text-sm">
            <Router />
            <Toaster position="top-right" reverseOrder={true} />
        </div>
    );
}

export default App;
