import { useEffect, useState } from 'react';
import { MdOutlineWbIncandescent } from 'react-icons/md';
import { VscDebug, VscDiffModified } from 'react-icons/vsc';
import { v4 as uuid } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import { cn } from '../helper/utils';

const getType = (tipo, data) => {
    const bar = 'inline-flex w-full font-semibold text-orange-500 bg-gray-100 border shadow';
    switch (tipo) {
        case 'CHANGE':
            return <div title='Modificação' className={cn(bar, 'text-orange-500')}><VscDiffModified size={18} className='mr-2' />{data}</div>
        case 'BUG':
            return <div title='Correção de bug' className={cn(bar, 'text-red-500')}><VscDebug size={18} className='mr-2' />{data}</div>
        case 'NEW':
            return <div title='Novo recurso' className={cn(bar, 'text-green-500')}><MdOutlineWbIncandescent size={18} className='mr-2' />{data}</div>
        default:
            return <div className={cn(bar, 'text-blue-500')}><VscDiffModified size={18} className='mr-2' />{data}</div>
    }
}

export const Changelog = () => {
    const System = useSystemContext();
    const [logs, setLogs] = useState(<div>Lendo...</div>);
    useEffect(() => {
        System.apiGet('/changelog').then((response) => {
            setLogs(
                <div>
                    <h1 className='p-2 bg-amarelo-eq'>Changelog - Últimas alterações no sistema</h1>
                    {response?.data?.map((registro, index) => (
                        <div key={index} className="mt-2 bg-white border border-gray-700">
                            {getType(registro.tipo, registro.data)}
                            <ul className=''>
                                {registro.descricao.map((item, index) => (
                                    <li key={uuid()}>• {item}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            );
        }
        );
    }
        // eslint-disable-next-line
        , []);
    return logs;
}