import _ from 'lodash';
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';

const defaultStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999999,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },

    content: {
        position: 'relative',
        margin: '15% auto',
        width: '60%',
        border: '1px solid rgba(0, 0, 0, .2)',
        background: '#fff',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        boxShadow: '0 5px 10px rgba(0, 0, 0, .3)',
    },
};

const defaultTransition = {
    property: 'all',
    duration: 300,
    timingfunction: 'linear',
};

const stopPropagation = (e) => e.stopPropagation();

let onClose;

export class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    close() {
        if (!this.props.onRequestClose || this.props.onRequestClose()) {
            ModalManager.close();
        }
    }

    handleKeyDown(event) {
        if (event.keyCode === 27 /*esc*/) this.close();
    }

    componentDidMount() {
        setTimeout(() => this.setState({ open: true }), 0);
        onClose = (callback) => {
            this.setState({ open: false }, () => {
                this.closeTimer = setTimeout(callback, defaultTransition.duration);
            });
        };
    }

    componentWillUnmount() {
        onClose = null;
        clearTimeout(this.closeTimer);
    }

    render() {
        const { style } = this.props;
        const { open } = this.state;

        const transitionStyle = {
            transition: `${defaultTransition.property} ${defaultTransition.duration / 1000}s ${defaultTransition.timingfunction}`,
        };

        return (
            <div
                style={_.defaultsDeep({ opacity: open ? 1 : 0 }, style?.overlay, defaultStyles.overlay, transitionStyle)}
                onClick={() => { }
                    //this.close.bind(this) 
                }>
                <div
                    style={_.defaultsDeep({}, style?.content, defaultStyles.content, transitionStyle)}
                    onClick={stopPropagation}
                    onKeyDown={this.handleKeyDown.bind(this)}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

let node;
let root;
const modals = [];

const renderModal = () => {
    if (modals.length === 0) return;

    const component = modals.shift();
    if (!node) {
        node = document.createElement('div');
        document.body.appendChild(node);
    }
    root = createRoot(node);
    root.render(component);
};



export const ModalManager = {
    open(component) {
        modals.push(component);
        if (modals.length === 1) {
            renderModal();
        }
    },
    close() {
        onClose && onClose(() => {
            root.unmount();

            // by ChatGPT 4
            modals.pop(); // Remove o último modal aberto da pilha
            if (modals.length > 0) { // Se ainda houver modals na pilha, renderiza o próximo
                renderModal();
            }
        });
    },
};