import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useSystemContext } from '../Context/SystemContext';
import { normalizeText } from '../helper/Text';
import { dateToString } from '../helper/dates';

const Chart84 = ({ item }) => {
    const System = useSystemContext();
    const [chartData, setChartData] = useState([]);
    const [meta, setMeta] = useState(0);
    var criterios = [];

    function readValues(item) {
        var ret = [];
        System.apiGet(`/griddocument/grid84-${item.tipo}-criterios`).then((response) => {
            criterios = response.data;
        });
        System.apiGet(`/griddocument/grid84-ipde-${item._id}?filter=%7B%22sortby%22:%7B%22data%22:1%7D%7D`).then((response) => {
            if (response.status === 200) {
                var _notaanterior = 10;
                var ini = new Date(item.data);
                var fim = new Date(item.reavaliacao);
                let dados = response.data.sort((a, b) => a.data.localeCompare(b.data));
                dados.forEach((item) => {
                    var _dt = new Date(item.data);
                    if (_dt >= ini && _dt <= fim) {
                        var nota = _notaanterior;
                        criterios.forEach((criterio) => {
                            let criterioNome = normalizeText(criterio.criterio);
                            if (item[criterioNome] !== 'Atende') {
                                nota -= criterio.demerito;
                            }
                        });
                        _notaanterior = nota;
                        ret.push({
                            data: dateToString(item.data),
                            Nota: nota.toFixed(1),
                        });
                    }
                });
                setChartData(ret);
            }
        });
    }

    useEffect(() => {
        System.apiGet('/griddocument/grid84-categorias').then((response) => {
            response.data.forEach((categ) => {
                if (categ._id === item.categoria) {
                    setMeta(categ.meta);
                }
            });
            return true;
        });
        readValues(item);
        // eslint-disable-next-line
    }, []);

    return (
        <div className="w-full h-96">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="data" />
                    <YAxis domain={[0, 10]} ticks={[2, 4, 6, 8, 10]} />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="Nota" legendType="none" fill="#93c6f9">
                        {chartData.map((entry, index) => (
                            <Cell cursor="pointer" fill={parseFloat(entry.Nota) < parseFloat(meta) ? '#df2233' : '#93c6f9'} key={`cell-${index}`} />
                        ))}
                    </Bar>
                    <ReferenceLine y={meta} stroke="black" strokeDasharray="5 5" label={{ value: 'Meta', position: 'top' }} ifOverflow="extendDomain" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Chart84;
