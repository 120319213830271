import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';
import Grid from '../components/Grid';
import { dateToString } from '../helper/dates';

export default function Consultor() {
    const System = useSystemContext();
    const navigate = useNavigate();
    if (System.property.user.level < 10) {
        return (
            <div>Sem permissão.</div>
        );
    }
    const OpenItem = (item) => {
        if (System.property.user.companyId === item.companyId) {
            System.setAutoEdit(item.autoEdit);
            navigate('/database/75', { replace: false });
        } else {
            System.setCurrentCompany(item.companyId)
                .then((changed) => {
                    localStorage.setItem('autoEdit', item.autoEdit);

                })
                .then(() => {
                    navigate('/database/75', { replace: false });
                });
        };
    }
    return (
        <Layout>
            <div className="w-full ml-2 mr-4">
                <div className={`ml-2 w-full `}>
                    <div className="block w-full pl-2 pr-2 text-xl font-semibold">Dashboard do Consultor</div>
                    <div className="w-full bg-white">
                        <Grid
                            url={'/dashboard-consultor'}
                            hideIndexColumn={true}
                            preventAutoEdit={true}
                            canEdit={false}
                            canDelete={false}
                            defaultSortField="@quando"
                            defaultItemsPerPage={20}
                            showSearch={true}
                            canInsert={false}
                            titlePDF={'Dashboard do Consultor'}
                            showPDFExport={true}
                            showCSVExport={true}
                            showPDFLogo={false}
                            GridHeaders={[
                                { title: 'CNPJ', field: 'company.cnpj', sort: true, className: 'w-2/12 text-center pl-1' },
                                { title: 'Empresa', field: 'company.nomeFantasia', sort: true, className: 'w-2/12 text-center pl-1' },
                                { title: 'Cidade', field: 'company.cidade', sort: true, className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
                                { title: 'O que', field: 'oque', sort: true, className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
                                { title: 'Quem', field: 'quem', sort: true, className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
                                { title: 'Quando', field: 'quando', sort: true, type: 'date', className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                                { title: 'Ações', field: 'acoes', sort: false, filter: false, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            ]}
                            hideActionsButtons={true}
                            LineDataPrint={(item) => {
                                let _dt = dateToString(item.quando);
                                let dateObject = new Date(item.quando + ' 00:00:00');
                                dateObject.setDate(dateObject.getDate() + 1);
                                let _vencido = dateObject < new Date();
                                return [
                                    item.company.cnpj,
                                    item.company.nomeFantasia,
                                    item.company.estado ? item.company.cidade + '/' + item.company.estado : item.company.cidade
                                    ,
                                    item.oque,
                                    item.quem,
                                    _vencido ? `#color=red:${_dt}` : _dt,
                                    <div onClick={() => OpenItem(item)}>
                                        <BiEdit className="w-full mt-1 cursor-pointer hover:text-blue-800" title="Editar" key={uuidv4()} />
                                    </div>,
                                ];
                            }}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}
