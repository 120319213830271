import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';
import Grid from '../components/Grid';
import { dateToString } from '../helper/dates';

export default function Dashboard() {
    const System = useSystemContext();
    let $_headers = [];
    switch (System.property.user.companyNorma) {
        case 'RDC-665':
            $_headers = [
                { title: 'Capítulo', field: 'requisito', sort: true, className: 'w-1/12 text-center pl-1' },
                { title: 'Tema', field: 'tema', sort: true, className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
                { title: 'Fase', field: 'fase', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                { title: 'O que', field: 'oque', sort: true, className: 'w-4/12 pl-1 text-center border-l border-gray-300' },
                { title: 'Quem', field: 'quem', sort: true, className: 'w-3/12 pl-1 text-center border-l border-gray-300' },
                { title: 'Quando', field: 'quando', sort: true, type: 'date', className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
                { title: 'Ações', field: 'acoes', sort: false, className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
            ];
            break;
        case 'LGPD':
        case 'SGI':
        case 'ISO-9001':
        default:
            $_headers = [
                { title: 'Requisito', field: 'requisito', sort: true, className: 'w-1/12 text-center pl-1' },
                { title: 'Tema', field: 'tema', sort: true, className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
                { title: 'Fase', field: 'fase', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                { title: 'O que', field: 'oque', sort: true, className: 'w-4/12 pl-1 text-center border-l border-gray-300' },
                { title: 'Quem', field: 'quem', sort: true, className: 'w-3/12 pl-1 text-center border-l border-gray-300' },
                { title: 'Quando', field: 'quando', sort: true, type: 'date', className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
                { title: 'Ações', field: 'acoes', sort: false, className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
            ];
            break;
    }
    return (
        <Layout>
            <div className="w-full ml-2 mr-4">
                <div className={`ml-2 w-full `}>
                    <div className="block w-24 pl-2 pr-2 text-xl font-semibold">Dashboard</div>
                    <div className="w-full bg-white">
                        <Grid
                            url={'/dashboard'}
                            hideIndexColumn={true}
                            preventAutoEdit={true}
                            canEdit={false}
                            canDelete={false}
                            defaultSortField="@quando"
                            defaultItemsPerPage={20}
                            showSearch={true}
                            canInsert={false}
                            titlePDF={'Dashboard'}
                            showPDFExport={false}
                            showCSVExport={true}
                            GridHeaders={$_headers}
                            hideActionsButtons={true}
                            LineDataPrint={(item) => {
                                let _dt = dateToString(item.quando);
                                let dateObject = new Date(item.quando + ' 00:00:00');
                                dateObject.setDate(dateObject.getDate() + 1);
                                let _vencido = dateObject < new Date();
                                return [
                                    item.requisito,
                                    item.tema,
                                    item.fase,
                                    item.oque,
                                    item.quem,
                                    _vencido ? `#color=red:${_dt}` : _dt,
                                    <Link to={item.link} onClick={() => System.setAutoEdit(item.autoEdit)}>
                                        <BiEdit className="w-full mt-1 cursor-pointer hover:text-blue-800" title="Editar" key={uuidv4()} />
                                    </Link>,
                                ];
                            }}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}
