import React, { useState } from 'react';
import { IoLogoWhatsapp } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';

const Ajuda = () => {
    const System = useSystemContext();
    const [empresa, setEmpresa] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [mensagem, setMensagem] = useState(
        `Olá, ${'consultor' in System.property.company ? System.property.company?.consultor?.name : ''}\n\nEstou com uma dúvida sobre o sistema:\n\n`
    );

    function send() {
        let numero = '';
        if (System.property.user.companyNorma === 'SGI') {
            numero = '5547984961035'; // Glederson
        } else {
            numero = '5547992441103'; // Elemar
        }
        if ('consultor' in System.property.company) {
            numero = `55${System.company.consultor.whatsapp.replace(/\D/g, '')}`;
        }
        window.open(
            `https://api.whatsapp.com/send?phone=${numero}&text=Dados%20de%20contato%3A%0A%0AEmpresa%20%3A%20${empresa}%0ANome%20%3A%20${nome}%0AE-mail%20%3A%20${email}%0AMensagem%20%3A%20${mensagem}%0A`,
            '_blank',
            'noopener,noreferrer'
        );
        return true;
    }
    async function sendMail() {
        let destEmail = '';
        if (System.property.user.companyNorma === 'SGI') {
            destEmail = 'glederson.hort@gmail.com'; // Glederson
        } else {
            destEmail = 'contato@escoladaqualidade.com'; // Elemar
        }
        if ('consultor' in System.property.company && System.property.company?.consultor?.suporte) {
            destEmail = System.company.consultor.email;
        }
        if (!email || !email.includes('@') || !email.includes('.')) {
            System.methods.notificationError('E-mail inválido');
            return;
        }
        if (!nome) {
            System.methods.notificationError('Nome inválido');
            return;
        }

        await System.apiPost('/mail', {
            from: email,
            to: destEmail,
            subject: `(eqsgt.com.br) Suporte - ${empresa}`,
            text: `Dados de contato:\n\nEmpresa : ${empresa}\nNome : ${nome}\nE-mail : ${email}\nMensagem : ${mensagem}\n`,
            html: `<p>Dados de contato:</p><p>Empresa : ${empresa}</p><p>Nome : ${nome}</p><p>E-mail : ${email}</p><p>Mensagem : ${mensagem}</p>`,
        }).then((res) => {
            if (res.status === 200) {
                System.methods.notificationSuccess('E-mail enviado com sucesso');
            } else {
                System.methods.notificationError('Erro ao enviar e-mail');
            }
            return;
        });
    }

    return (
        <Layout>
            <div className="flex w-full items-center justify-center">
                <div className="mt-4 ml-4 w-1/2 rounded border border-gray-300 bg-white shadow">
                    <div className="bg-amarelo-eq text-center">
                        <h2>Solicitar Suporte</h2>
                    </div>
                    <div className="px-3 py-2">
                        <div className="center">
                            <label htmlFor="empresa">Empresa</label>
                            <input
                                type="text"
                                id="empresa"
                                name="empresa"
                                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                                value={empresa}
                                onChange={(e) => {
                                    setEmpresa(e.target.value);
                                }}
                            />
                        </div>
                        <div className="center">
                            <label htmlFor="nome">Seu nome</label>
                            <input
                                type="text"
                                id="nome"
                                name="nome"
                                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                                value={nome}
                                onChange={(e) => {
                                    setNome(e.target.value);
                                }}
                            />
                        </div>
                        <div className="center">
                            <label htmlFor="email">Seu e-mail</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <div className="center">
                            <label htmlFor="text">Mensagem</label>
                            <textarea
                                id="text"
                                name="text"
                                rows="4"
                                cols="50"
                                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                                value={mensagem}
                                onChange={(e) => {
                                    setMensagem(e.target.value);
                                }}
                            />
                        </div>
                        <button onClick={(e) => send()} className="w-48 rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
                            <div className="inline-flex ">
                                <IoLogoWhatsapp className="mt-1 mr-2" />
                                Enviar WhatsApp
                            </div>
                        </button>
                        <button onClick={sendMail} className="ml-2 w-48 rounded bg-green-500 px-4 py-2 font-bold text-white hover:bg-blue-700">
                            <div className="inline-flex">
                                <MdEmail className="mt-1 mr-2" />
                                Enviar E-mail
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Ajuda;
