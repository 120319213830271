import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { useSystemContext } from '../Context/SystemContext';
import { dateToString } from '../helper/dates';

const headerTitle = 'IDENTIFICAÇÃO E ANÁLISE DE ASPECTOS E IMPACTOS AMBIENTAIS E OCUPACIONAIS';

const getPageParameters = () => {
    return `
    div.page-footer {
        text-align: center;
        height: 50px;
        font-size: 10px;
        opacity: 0.8;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    div.page-footer p {
        margin: 0;
    }
    .watermark {
        display: none;
        top: 50vh;
        z-index: -9;
        width: 50vw;
        page-break-after: always;
        left: 50%;
    }
    .page-break {
        margin-top: 3rem;
        display: block;
        page-break-before: auto;
    }        
    table.report-container {
        page-break-after: always;
        width: 100%;
    }
    thead.report-header {
        display: table-header-group;
    }
    tfoot.report-footer {
        display: table-footer-group;
    }
    div.footer-info, div.page-footer {
        display: none;
        height: 80px;
    }
    @media print {
        @page {
            size: A4;
            margin: 18mm 9mm 10mm 9mm;
        }
        .watermark {
            display: block;
            counter-increment: page;
            position: fixed;
        }
        div.page-footer, div.footer-info {
            display: block;
        }
        div.header {
            position: fixed;
            top: 0;
        }
    }
    `;
    };

    // cabeçalho

const Print612 = ({ item }) => {
    const System = useSystemContext();
    const riscos = [{ value: 0, option: 'Desprezível' }, { value: 1, option: 'Menor' }, { value: 2, option: 'Moderado' }, { value: 4, option: 'Sério' }, { value: 8, option: 'Crítico' }];
    const severidade = [{
        '1': 'I',
        '2': 'II',
        '4': 'III',
        '8': 'IV'
    }];
    const frequencia = [{
        '1': 'A',
        '2': 'B',
        '4': 'C',
        '8': 'D',
        '16': 'E'
    }];
    const componentRef = useRef();
    const [data, setData] = useState([]);

    useEffect(() => {
        System.apiGet('/griddocument/grid612-ambiental-' + item._id).then((response) => {
            setData(response.data);
            return true;
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div className='h-28'>
            <ReactToPrint
                trigger={() => <button className='px-4 my-4 ml-4 border border-gray-800 rounded shadow hover:bg-gray-300 bg-amarelo-eq'>Visualizar a impressão dos dados!</button>}
                content={() => componentRef.current}
            />  
            <div style={{ display: "none" }}>
                <style>{getPageParameters()}</style>

                <div ref={componentRef}>
                    <title>{headerTitle}</title>
                    <div className='w-11/12 px-4 ml-8 mr-8 border border-black rounded'>
                        <div className='inline-block'>
                            Número Documento: {item.numero}
                        </div>
                        <div className='inlin-block'>
                            Data Cadastro/Atualização: {dateToString(item.cadastro)}
                        </div>
                        <div className='inlin-block'>
                            Processo: {item.processo}
                        </div>
                        <div className='inlin-block'>
                            Área: {item.area}
                        </div>
                        <div className='inlin-block'>
                            Responsável: {item.crew?.nome}
                        </div>
                        <div className='inlin-block'>
                            Participantes: {item.participantes}
                        </div>
                    </div>
                    {data && data.map((subitem) => (
                        <div className='w-11/12 px-4 mt-4 ml-8 mr-8 border border-black rounded'>
                            <div className='inlin-block'>
                                <div className='inlin-block'>Tipo: {subitem.tipo}</div>
                                <div className='inlin-block'>Descrição do Aspecto:</div>
                                <div className='ml-2 inlin-block'>{subitem.aspecto}</div>
                                <div className='inlin-block'>Descrição do Possível Impacto:</div>
                                <div className='ml-2 inlin-block'>{subitem.impacto}</div>
                                <div className='inlin-block'>Severidade: {severidade.find((item) => item[subitem.severidade])?.[subitem.severidade]}</div>
                                <div className='inlin-block'>Frequência: {frequencia.find((item) => item[subitem.frequencia])?.[subitem.frequencia]}</div>
                                <div className='inlin-block'>Risco: {riscos.find(x => x.value === subitem.risco)?.option}</div>
                                <div className='inlin-block'>Possível(is) Causa(s) ou Potencial(is):</div>
                                <div className='ml-2 inlin-block'>{subitem.causas}</div>
                                <div className='mt-2 inlin-block'>Controle(s) sobre a(s) possível(is) causa(s) e/ou no(s) possível(is) impacto(s):</div>
                                <div className='ml-2 inlin-block'>{subitem.controle}</div>
                                <div className='mt-2 inlin-block'>Significância/Aceitabilidade:</div>
                                <div className='ml-2 inlin-block'>{subitem.significancia}</div>
                            </div>
                        </div>
                    ))}                                
                </div>
            </div>
        </div>
    );
};

export default Print612;