import { FormInput, TextInput } from '../../components/Input';
import { useSystemContext } from '../../Context/SystemContext';

const yup = require('../../interface/yup-br');

export const Form21 = () => {
    const System = useSystemContext();
    return (
        <FormInput document="/document/form21" perms={'21'}
            title={System.getMenuTitle(21)}
            initialValues={{
                objetivo: '',
            }}
            validationSchema={yup.object().shape({
                objetivo: yup.string(),
            })}
            onRenderFields={() => {
                return (
                    <div className="w-full">
                        <TextInput name="objetivo" label="Termos" className="w-full" />
                    </div>
                );
            }}
        />
    );
};
