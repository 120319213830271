import { FormInput, TextInput } from '../../components/Input';
import { useSystemContext } from '../../Context/SystemContext';
const yup = require("../../interface/yup-br");

export const Form31 = () => {
    const System = useSystemContext();
    return (
        <FormInput document="/document/form31" perms={'31'}
            title={System.getMenuTitle(31)}
            initialValues={{
                normas: '',
            }}
            validationSchema={
                yup.object().shape({
                    normas: yup.string(),
                })
            }
            onRenderFields={() => {
                return (
                    <div className='w-full'>
                        <TextInput name="normas" label="Normas" className="w-full" />
                    </div>
                );
            }}
        />
    );
}