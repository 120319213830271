import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import { GrDocumentPdf } from 'react-icons/gr';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useSystemContext } from '../../Context/SystemContext';
import Grid from '../../components/Grid';
import { Grid5W2H } from '../../components/Grid5W2H';
import { GridAnexos } from '../../components/GridAnexos';
import { GridDiario } from '../../components/GridDiario';
import { AttachmentInput, DBSelectInput, DateInput, FormInput, GroupInput, Input, SelectInput, TabsInput, TextInput } from '../../components/Input';
import { dateToString } from '../../helper/dates';

const yup = require('../../interface/yup-br');

export const Form91 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form91"
                perms={'91'}
                title={System.getMenuTitle(91)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form911 = () => {
    const System = useSystemContext();
    const chartRef = useRef(null);


    function toFloat(value) {
        if (value) return parseFloat(value);
        return 0;
    }
    function calcMedia(values) {
        let atual = 0;
        let anterior = 0;
        let divisor = 0;
        let adivisor = 0;
        switch (values.frequencia) {
            case 'Mensal':
                // atual
                if (toFloat(values?.valor1) > 0) divisor += 1;
                if (toFloat(values?.valor2) > 0) divisor += 1;
                if (toFloat(values?.valor3) > 0) divisor += 1;
                if (toFloat(values?.valor4) > 0) divisor += 1;
                if (toFloat(values?.valor5) > 0) divisor += 1;
                if (toFloat(values?.valor6) > 0) divisor += 1;
                if (toFloat(values?.valor7) > 0) divisor += 1;
                if (toFloat(values?.valor8) > 0) divisor += 1;
                if (toFloat(values?.valor9) > 0) divisor += 1;
                if (toFloat(values?.valor10) > 0) divisor += 1;
                if (toFloat(values?.valor11) > 0) divisor += 1;
                if (toFloat(values?.valor12) > 0) divisor += 1;
                // anterior
                if (toFloat(values?.avalor1) > 0) adivisor += 1;
                if (toFloat(values?.avalor2) > 0) adivisor += 1;
                if (toFloat(values?.avalor3) > 0) adivisor += 1;
                if (toFloat(values?.avalor4) > 0) adivisor += 1;
                if (toFloat(values?.avalor5) > 0) adivisor += 1;
                if (toFloat(values?.avalor6) > 0) adivisor += 1;
                if (toFloat(values?.avalor7) > 0) adivisor += 1;
                if (toFloat(values?.avalor8) > 0) adivisor += 1;
                if (toFloat(values?.avalor9) > 0) adivisor += 1;
                if (toFloat(values?.avalor10) > 0) adivisor += 1;
                if (toFloat(values?.avalor11) > 0) adivisor += 1;
                if (toFloat(values?.avalor12) > 0) adivisor += 1;

                atual =
                    (toFloat(values.valor1) +
                        toFloat(values.valor2) +
                        toFloat(values.valor3) +
                        toFloat(values.valor4) +
                        toFloat(values.valor5) +
                        toFloat(values.valor6) +
                        toFloat(values.valor7) +
                        toFloat(values.valor8) +
                        toFloat(values.valor9) +
                        toFloat(values.valor10) +
                        toFloat(values.valor11) +
                        toFloat(values.valor12)) /
                    divisor;
                anterior =
                    (toFloat(values.avalor1) +
                        toFloat(values.avalor2) +
                        toFloat(values.avalor3) +
                        toFloat(values.avalor4) +
                        toFloat(values.avalor5) +
                        toFloat(values.avalor6) +
                        toFloat(values.avalor7) +
                        toFloat(values.avalor8) +
                        toFloat(values.avalor9) +
                        toFloat(values.avalor10) +
                        toFloat(values.avalor11) +
                        toFloat(values.avalor12)) /
                    adivisor;
                return { Anterior: anterior.toFixed(2), Atual: atual.toFixed(2), label: 'Média' };
            case 'Bimestral':
                atual = (toFloat(values.valor1) + toFloat(values.valor2)) / 2;
                anterior = (toFloat(values.avalor1) + toFloat(values.avalor2)) / 2;
                return { Anterior: anterior.toFixed(2), Atual: atual.toFixed(2), label: 'Média' };
            case 'Trimestral':
                atual = (toFloat(values.valor1) + toFloat(values.valor2) + toFloat(values.valor3)) / 3;
                anterior = (toFloat(values.avalor1) + toFloat(values.avalor2) + toFloat(values.avalor3)) / 3;
                return { Anterior: anterior.toFixed(2), Atual: atual.toFixed(2), label: 'Média' };
            case 'Semestral':
                atual = (toFloat(values.valor1) + toFloat(values.valor2)) / 2;
                anterior = (toFloat(values.avalor1) + toFloat(values.avalor2)) / 2;
                return { Anterior: anterior.toFixed(2), Atual: atual.toFixed(2), label: 'Média' };
            default:
                return { Anterior: 0, Atual: 0, label: 'Média' };
        }
    }

    function mountBars(values) {
        let ret = [];
        if (values.frequencia === 'Mensal') {
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: 'Jan' },
                { Anterior: values.avalor2, Atual: values.valor2, label: 'Fev' },
                { Anterior: values.avalor3, Atual: values.valor3, label: 'Mar' },
                { Anterior: values.avalor4, Atual: values.valor4, label: 'Abr' },
                { Anterior: values.avalor5, Atual: values.valor5, label: 'Mai' },
                { Anterior: values.avalor6, Atual: values.valor6, label: 'Jun' },
                { Anterior: values.avalor7, Atual: values.valor7, label: 'Jul' },
                { Anterior: values.avalor8, Atual: values.valor8, label: 'Ago' },
                { Anterior: values.avalor9, Atual: values.valor9, label: 'Set' },
                { Anterior: values.avalor10, Atual: values.valor10, label: 'Out' },
                { Anterior: values.avalor11, Atual: values.valor11, label: 'Nov' },
                { Anterior: values.avalor12, Atual: values.valor12, label: 'Dez' },
                calcMedia(values),
            ];
        }
        if (values.frequencia === 'Bimestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Bim' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Bim' },
                { Anterior: values.avalor3, Atual: values.valor3, label: '3º Bim' },
                { Anterior: values.avalor4, Atual: values.valor4, label: '4º Bim' },
                { Anterior: values.avalor5, Atual: values.valor5, label: '5º Bim' },
                { Anterior: values.avalor6, Atual: values.valor6, label: '6º Bim' },
                calcMedia(values),
            ];
        if (values.frequencia === 'Trimestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Tri' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Tri' },
                { Anterior: values.avalor3, Atual: values.valor3, label: '3º Tri' },
                { Anterior: values.avalor4, Atual: values.valor4, label: '4º Tri' },
                calcMedia(values),
            ];
        if (values.frequencia === 'Semestral')
            ret = [{ Anterior: values.avalor1, Atual: values.valor1, label: '1º Sem' }, { Anterior: values.avalor2, Atual: values.valor2, label: '2º Sem' }, calcMedia(values)];
        if (values.frequencia === 'Anual') ret = [{ Anterior: values.avalor1, Atual: values.valor1, label: 'Ano' }];
        return ret;
    }

    async function ChartToPDF(values) {
        const chartNode = chartRef.current;
        const canvas = await html2canvas(chartNode);
        let data = mountBars(values);

        const pdf = new jsPDF('landscape');
        pdf.text(`${values.descricao}`, 20, 20);
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 25, 220, 100); // Insira a imagem do gráfico
        pdf.setFontSize(12);
        pdf.text('Anterior:', 10, 146);
        pdf.text('Atual:', 14, 152);
        let hz = 30;
        data.forEach((item, index) => {
            pdf.text(`${item.label}`, hz, 140);
            pdf.text(`${item.Anterior || '---'}`, hz, 146);
            pdf.text(`${item.Atual || '---'}`, hz, 152);
            hz += 14;
        });
        // Baixe ou exiba o PDF
        pdf.save('Grafico.pdf');
    }

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form911"
                        perms={'911'}
                        title={System.getMenuTitle(911)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Objectivos">
                    <Grid
                        url={'/griddocument/grid911'}
                        perms={'911'}
                        clearAutoEdit={true}
                        showPDFExport={true}
                        showCSVExport={true}
                        modalHeight={'55%'}
                        modalWidth={'60%'}
                        modalMargin={'5% auto'}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-2/4 text-left pl-1' },
                            { title: 'Etapa', field: 'etapa', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Frequência', field: 'frequencia', sort: true, className: 'w-1/6 w-32 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao.substr(0, 50), data.etapa, data.frequencia, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" className="w-full" rows="8" />
                                    <Input name="etapa" label="Etapa" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <GroupInput>
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            className={'w-1/4'}
                                            listOptions={[{ value: 'Mensal' }, { value: 'Bimestral' }, { value: 'Trimestral' }, { value: 'Semestral' }, { value: 'Anual' }]}
                                        />
                                        <SelectInput
                                            name="operacao"
                                            label="Operação"
                                            className={'w-1/3'}
                                            listOptions={[
                                                { value: 'lt', option: '< (Menor quê)' },
                                                { value: 'le', option: '<= (Menor ou igual)' },
                                                { value: 'eq', option: '= (Igual)' },
                                                { value: 'ge', option: '>= (Maior ou igual)' },
                                                { value: 'gt', option: '> (Maior quê)' },
                                            ]}
                                        />
                                        <Input name="meta" label="Meta" className={'w-44'} />
                                    </GroupInput>
                                    <Input name="unidade" label="Unidade" />
                                    <TextInput name="memoria" label="Memória de Cálculo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            etapa: yup.string(),
                            responsavel: yup.string(),
                            frequencia: yup.string(),
                            operacao: yup.string(),
                            meta: yup.string(),
                            unidade: yup.string(),
                            memoria: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Gráfico</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de bordo</div>;
                            },
                            () => {
                                return <div>Análise e Avaliação</div>;
                            },

                            () => {
                                return <div>Anexos</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Grafico                                
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/grid911/${item._id}`}
                                            perms={'911'}
                                            title="Gráfico"
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <div>
                                                            <GroupInput>
                                                                <div className="mt-8 ml-2 w-36">Anterior</div>
                                                                {values.frequencia === 'Mensal' && (
                                                                    <div className="inline-flex ml-4">
                                                                        <Input name="avalor1" label="Jan" />
                                                                        <Input name="avalor2" label="Fev" />
                                                                        <Input name="avalor3" label="Mar" />
                                                                        <Input name="avalor4" label="Abr" />
                                                                        <Input name="avalor5" label="Mai" />
                                                                        <Input name="avalor6" label="Jun" />
                                                                        <Input name="avalor7" label="Jul" />
                                                                        <Input name="avalor8" label="Ago" />
                                                                        <Input name="avalor9" label="Set" />
                                                                        <Input name="avalor10" label="Out" />
                                                                        <Input name="avalor11" label="Nov" />
                                                                        <Input name="avalor12" label="Dez" />
                                                                    </div>
                                                                )}
                                                                {values.frequencia === 'Bimestral' && (
                                                                    <>
                                                                        <Input name="avalor1" label="1º Bimestre" />
                                                                        <Input name="avalor2" label="2º Bimestre" />
                                                                        <Input name="avalor3" label="3º Bimestre" />
                                                                        <Input name="avalor4" label="4º Bimestre" />
                                                                        <Input name="avalor5" label="5º Bimestre" />
                                                                        <Input name="avalor6" label="6º Bimestre" />
                                                                    </>
                                                                )}
                                                                {values.frequencia === 'Trimestral' && (
                                                                    <>
                                                                        <Input name="avalor1" label="1º Trimestre" />
                                                                        <Input name="avalor2" label="2º Trimestre" />
                                                                        <Input name="avalor3" label="3º Trimestre" />
                                                                        <Input name="avalor4" label="4º Trimestre" />
                                                                    </>
                                                                )}
                                                                {values.frequencia === 'Semestral' && (
                                                                    <>
                                                                        <Input name="avalor1" label="1º Semestre" />
                                                                        <Input name="avalor2" label="2º Semestre" />
                                                                    </>
                                                                )}
                                                                {values.frequencia === 'Anual' && (
                                                                    <>
                                                                        <Input name="avalor1" label="Anual" />
                                                                    </>
                                                                )}
                                                            </GroupInput>
                                                            <GroupInput>
                                                                <div className="pl-5 mt-8 w-36">Atual</div>
                                                                {values.frequencia === 'Mensal' && (
                                                                    <div className="inline-flex ml-4">
                                                                        <Input name="valor1" label="Jan" />
                                                                        <Input name="valor2" label="Fev" />
                                                                        <Input name="valor3" label="Mar" />
                                                                        <Input name="valor4" label="Abr" />
                                                                        <Input name="valor5" label="Mai" />
                                                                        <Input name="valor6" label="Jun" />
                                                                        <Input name="valor7" label="Jul" />
                                                                        <Input name="valor8" label="Ago" />
                                                                        <Input name="valor9" label="Set" />
                                                                        <Input name="valor10" label="Out" />
                                                                        <Input name="valor11" label="Nov" />
                                                                        <Input name="valor12" label="Dez" />
                                                                    </div>
                                                                )}
                                                                {values.frequencia === 'Bimestral' && (
                                                                    <>
                                                                        <Input name="valor1" label="1º Bimestre" />
                                                                        <Input name="valor2" label="2º Bimestre" />
                                                                        <Input name="valor3" label="3º Bimestre" />
                                                                        <Input name="valor4" label="4º Bimestre" />
                                                                        <Input name="valor5" label="5º Bimestre" />
                                                                        <Input name="valor6" label="6º Bimestre" />
                                                                    </>
                                                                )}
                                                                {values.frequencia === 'Trimestral' && (
                                                                    <>
                                                                        <Input name="valor1" label="1º Trimestre" />
                                                                        <Input name="valor2" label="2º Trimestre" />
                                                                        <Input name="valor3" label="3º Trimestre" />
                                                                        <Input name="valor4" label="4º Trimestre" />
                                                                    </>
                                                                )}
                                                                {values.frequencia === 'Semestral' && (
                                                                    <>
                                                                        <Input name="valor1" label="1º Semestre" />
                                                                        <Input name="valor2" label="2º Semestre" />
                                                                    </>
                                                                )}
                                                                {values.frequencia === 'Anual' && (
                                                                    <>
                                                                        <Input name="valor1" label="Anual" />
                                                                    </>
                                                                )}
                                                            </GroupInput>
                                                            <div className="w-full h-96" ref={chartRef}>
                                                                <ResponsiveContainer width="100%" height="100%">
                                                                    <BarChart
                                                                        width={500}
                                                                        height={300}
                                                                        data={mountBars(values)}
                                                                        margin={{
                                                                            top: 5,
                                                                            right: 30,
                                                                            left: 20,
                                                                            bottom: 5,
                                                                        }}
                                                                    >
                                                                        <CartesianGrid strokeDasharray="3 3" />
                                                                        <XAxis dataKey="label" />
                                                                        <YAxis domain={[0, dataMax => (Math.ceil(dataMax * 2) / 10) * 10]} />
                                                                        <Tooltip />
                                                                        <Legend />

                                                                        <Bar dataKey="Anterior" legendType="none" fill="#83af83" />
                                                                        <Bar dataKey="Atual" legendType="none" fill="#93c6f9" />
                                                                        <ReferenceLine
                                                                            y={values.meta}
                                                                            stroke="black"
                                                                            strokeDasharray="5 5"
                                                                            label={{ value: 'Meta', position: 'top' }}
                                                                            ifOverflow="extendDomain"
                                                                        />
                                                                    </BarChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </div>
                                                        <GroupInput>
                                                            <button
                                                                type="submit"
                                                                className="w-12 h-10 ml-4 rounded-md shadow-md bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                                title="Exportar para PDF"
                                                                onClick={() => {
                                                                    ChartToPDF(values);
                                                                }}
                                                            >
                                                                <GrDocumentPdf className="ml-4 -mt-2 h-14" />
                                                            </button>
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({})}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/form911-${item._id}`} perms="911" />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div>
                                                <GridDiario url={'/griddocument/grid911-' + item._id} perms={'911'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Análise e Avaliação
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid911-analiseavaliacao-${item._id}`}
                                            perms={'911'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Data', field: 'data', sort: true, className: 'w-1/6 text-left pl-1' },
                                                { title: 'Período avaliado', field: 'periodo', sort: false, className: 'w-4/6 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.data), data.periodo];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <DateInput name="data" label="Data" className={'inline-block'} />
                                                        <Input name="periodo" label="Período avaliado" />
                                                        <TextInput name="registro" label="Registro" rows={'5'} />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    data: yup.date(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },

                            (GridModes, item, GridRefresh) => {
                                // Anexos
                                return (
                                    <div>
                                        <GridAnexos url={`/attachment/form911-${item._id}`} disableModal={true} perms="911" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/form911-${item._id}`);
                            await System.apiDelete(`/attachment/form911-${item._id}`);
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={`/attachment/form911`} perms="911" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form912 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form912"
                perms="912"
                title={System.getMenuTitle(912)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form912SGI = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form9121"
                perms="9121"
                title={System.getMenuTitle(912001)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form913 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form913"
                perms="913"
                title={System.getMenuTitle(913)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form92 = () => {
    const System = useSystemContext();
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Gestão</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form92"
                        perms="92"
                        title={System.getMenuTitle(92)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestao">
                    <Grid
                        url={'/griddocument/grid92'}
                        perms={'92'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Número', field: 'numero', sort: true, className: 'w-1/5 text-left pl-1' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.numero, dateToString(data.data), data.descricao ? data.descricao.substr(0, 50) : '', data.crew?.nome, `#link=${data.attachment}`];
                        }}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="numero" label="Número" />
                                        <DateInput name="data" label="Data" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <AttachmentInput name="attachment" perms={'92'} label="Anexo" />
                                </div>
                            );
                        }}
                        onPrepareSaveRecord={(values) => {
                            let newForm = new FormData();
                            for (let key in values) {
                                newForm.append(key, values[key]);
                            }
                            return newForm;
                        }}
                        validationSchema={yup.object().shape({
                            numero: yup.string(),
                            data: yup.string(),
                            descricao: yup.string(),
                            responsavel: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/form92-${item._id}`} perms="92" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/form92-${item._id}`);
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={`/attachment/form92`} perms="92" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form93 = () => {
    const System = useSystemContext();
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Gestão</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form93"
                        perms="93"
                        title={System.getMenuTitle(93)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestao">
                    <Grid
                        url={'/griddocument/grid93'}
                        perms={'93'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Número', field: 'numero', sort: true, className: 'w-1/5 text-left pl-1' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.numero, dateToString(data.data), data.descricao?.substr(0, 50), data.crew?.nome, `#link=${data.attachment}`];
                        }}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="numero" label="Número" />
                                        <DateInput name="data" label="Data" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <AttachmentInput name="attachment" perms={'93'} label="Anexo" />
                                </div>
                            );
                        }}
                        onPrepareSaveRecord={(values) => {
                            let newForm = new FormData();
                            for (let key in values) {
                                newForm.append(key, values[key]);
                            }
                            return newForm;
                        }}
                        validationSchema={yup.object().shape({
                            numero: yup.string(),
                            data: yup.string(),
                            descricao: yup.string(),
                            responsavel: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/form93-${item._id}`} perms="93" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/form93-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
