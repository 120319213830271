import React from 'react';
import { MdChangeCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSystemContext } from '../Context/SystemContext';
import { BaseMenu } from '../components/BaseMenu';
import { DisplayUser } from '../components/DisplayUser';

const Header = () => {
    let navigate = useNavigate();
    const System = useSystemContext();

    return (
        <>
            <div className="flex w-full h-14 bg-fundo-menu-top text-fonte-menu-top ">
                <div className="justify-between h-full grow-0">
                    <BaseMenu />
                </div>
                <div className="text-center grow"></div>
                <div className="text-right grow-0">
                    <DisplayUser />
                </div>
            </div>
            <div className="inline-flex justify-center w-full bg-fundo-select-empresa text-fonte-select-empresa">
                {System.property.user.level > 5 && (
                    <div
                        title="Alterar Empresa"
                        onClick={() => {
                            System.methods.selectCompany(() => {
                                navigate('/redir', { replace: false });
                            });
                        }}
                        className="px-2 mt-1 mb-1 mr-2 text-gray-800 bg-gray-300 border border-gray-600 rounded-md shadow cursor-pointer order hover:bg-yellow-500"
                    >
                        <MdChangeCircle />
                    </div>
                )}
                <div>{System.property.user?.companyNomeFantasia}</div>
            </div>
        </>
    );
};
export default Header;
