import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';

const Home = () => {
    const System = useSystemContext();
    let acessoRapido = '';
    let items = [];
    const items_ISO = [
        ['Documentos - 7.5', '/database/75', 75],
        ['Documentos externos - 7.5.3', '/database/753', 753],
        ['Não conformidades - 10.2', '/database/102001', 102001],
        ['Calibrações - 7.1.5', '/database/715', 715],
        ['Objetivos da qualidade - 6.2', '/database/62', 62],
        ['Indicadores - 9.1.1', '/database/911', 911],
        ['Pessoas - 7.1.2', '/database/712'],
        ['Riscos - 6.1', '/database/61', 61],
        ['Mudanças - 6.3', '/database/63', 63],
        ['SWOT - 4.1', '/database/41', 41],
        ['Partes interessadas - 4.2', '/database/42', 42],
        ['Avaliação de Fornecedores - 8.4', '/database/84', 84],
        ['Infraestrutura - 7.1.3', '/database/713', 713],
        ['Projetos - 8.3', '/database/83', 83],
        ['Comunicação - 7.4', '/database/74', 74],
        ['Propriedade do cliente - 8.5.3', '/database/853', 853],
    ];

    const items_SGI = [
        ['Documentos - 7.5', '/database/75', 75],
        ['Documentos externos - 7.5.3', '/database/753', 753],
        ['Não conformidades - 10.2', '/database/102001', 102001],
        ['Calibrações - 7.1.5', '/database/715', 715],
        ['Objetivos da qualidade - 6.2', '/database/62', 62],
        ['Indicadores - 9.1.1', '/database/911', 911],
        ['Pessoas - 7.1.2', '/database/712', 712],
        ['Riscos - 6.1', '/database/61', 61],
        ['Aspectos/Impactos, Perigos/Riscos - 6.1.2', '/database/612', 612],
        ['Requisitos legais - 6.1.3', '/database/613', 613],
        ['Mudanças - 6.3', '/database/63', 63],
        ['SWOT - 4.1', '/database/41', 41],
        ['Partes interessadas - 4.2', '/database/42', 42],
        ['Emergências - 8.2', '/database/82001', 82001],
        ['Avaliação de Fornecedores - 8.4', '/database/84', 84],
        ['Infraestrutura - 7.1.3', '/database/713', 713],
        ['Projetos - 8.3', '/database/83', 83],
        ['Comunicação - 7.4', '/database/74', 74],
        ['Propriedade do cliente - 8.5.3', '/database/853', 853],
        ['Consulta e participação de trabalhadores - 5.4', '/database/54', 54],
    ];

    const items_RDC = [
        ['Documentos - 3.1', '/database/75', 75],
        ['Documentos externos - 3.2', '/database/753', 753],
        ['Não conformidades - 7.1', '/database/102001', 102001],
        ['Equipamentos de medição - 5.5', '/database/715', 715],
        ['Objetivos da qualidade - 2.4', '/database/62', 62],
        ['Indicadores - 9.1', '/database/911', 911],
        ['Pessoas - 2.8', '/database/712', 712],
        ['Riscos - 2.9', '/database/61', 61],
        ['Requisitos legais - 2.2', '/database/613', 613],
        ['Mudanças - 5.7', '/database/63', 63],
        ['SWOT - 1.6', '/database/41', 41],
        ['Partes interessadas - 1.7', '/database/42', 42],
        ['Avaliação de Fornecedores - 2.10', '/database/84', 84],
        ['Infraestrutura / manutenção - 5.2', '/database/713', 713],
        ['Projetos - 4.1', '/database/83', 83],
        ['Comunicação - 2.6', '/database/74', 74],
        ['Propriedade do cliente - 6.3', '/database/853', 853],
    ];

    switch (System.property.user.companyNorma) {
        case 'RDC-665':
            items = items_RDC;
            break;
        case 'SGI':
            items = items_SGI;
            break;
        case 'ISO-9001':
            items = items_ISO;
            break;
        case 'LGPD':
            items = [];
            break;
        default:
            break;
    }
    items = items.filter((item) => {
        return System.methods.getUserRule('menu' + item[2]);
    });

    acessoRapido = (
        <div>
            <div className="pl-4 pr-2 text-xl font-semibold ">Acesso rápido</div>
            <div className="grid grid-cols-4 ml-2">
                {items.map((item, index) => (
                    <div key={index} className="p-2 m-2 bg-gray-100 border rounded-lg cursor-pointer border-1 hover:bg-gray-200 hover:shadow-lg">
                        <a href={`${item[1]}#1`} className="block p-2 text-black">
                            {item[0]}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );

    return <Layout>{acessoRapido}</Layout>;
};
export default Home;
