import { Component } from 'react';
import { HiExclamation, HiExclamationCircle, HiQuestionMarkCircle } from 'react-icons/hi';
import { Modal, ModalManager } from '../components/Modais';



export const showConfirm = (text, onConfirm, onCancel, confirmText, cancelText) => {
	ModalManager.open(
		<ModalConfirm
			title='Confirmação'
			text={text}
			onRequestClose={() => true}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={confirmText}
			cancelText={cancelText}
		/>
	);
};


export class InfoModal extends Component {
	render() {
		const { text, onRequestClose } = this.props;
		return (
			<Modal
				onRequestClose={onRequestClose}
				style={{ content: { maxHeight: '300px', minWidth: '300px', maxWidth: '500px' } }}
			>
				<div>
					<div className="text-center text-white border-yellow-500 bg-amarelo-eq">
						Atenção
					</div>
					<div className="flex">
						<div className="block grow-0">
							<HiExclamation className="text-yellow-400 text-8xl" />
						</div>
						<div className="pt-6 text-lg text-center grow">
							{text}
						</div>
					</div>
					<div className="py-2 text-center">
						<button
							onClick={() => { ModalManager.close(); onRequestClose(); }}
							className="px-4 py-2 mt-4 mb-4 bg-gray-400 border border-gray-500 rounded-md shadow-md hover:bg-gray-300 hover:border"
						>
							Fechar
						</button>
					</div>
				</div>
			</Modal>
		);
	}
}

export class ErrorModal extends Component {
	render() {
		const { text, onRequestClose } = this.props;
		return (
			<Modal
				onRequestClose={onRequestClose}
				style={{ content: { maxHeight: '300px', minWidth: '300px', maxWidth: '500px' } }}
			>
				<div>
					<div className="text-center text-white bg-red-500 border-red-500">
						Erro!
					</div>
					<div className="flex">
						<div className="block grow-0">
							<HiExclamationCircle className="text-red-500 text-8xl" />
						</div>
						<div className="pt-6 text-lg text-center grow">
							{text}
						</div>
					</div>
					<div className="py-2 text-center">
						<button
							onClick={() => { ModalManager.close(); onRequestClose(); }}
							className="px-4 py-2 mt-4 mb-4 bg-gray-400 border border-gray-500 rounded-md shadow-md hover:bg-gray-300 hover:border"
						>
							Fechar
						</button>
					</div>
				</div>
			</Modal>
		);
	}
}


class ModalConfirm extends Component {
	render() {
		const { title, text, onRequestClose } = this.props;
		return (
			<Modal
				onRequestClose={onRequestClose}
				style={{ content: { maxHeight: '600px', minWidth: '300px', maxWidth: '500px' } }}
			>
				<div className='border shadow-sm' size='md' style={{ border: 0 }}>
					<div className="text-center text-white bg-red-500 border-red-500">
						{title}
					</div>
					<div className="flex">
						<div className="block grow-0">
							<HiQuestionMarkCircle className="text-red-500 text-8xl" />
						</div>
						<div className="pt-6 text-lg text-center grow">
							{text}
						</div>
					</div>

					<div className='flex justify-end gap-2 border-t'>
						<button
							type='button'
							onClick={() => {
								if (this.props.onCancel) {
									this.props.onCancel();
								}
								ModalManager.close();
							}}
							className='px-4 py-2 mt-4 mb-4 bg-gray-400 border border-gray-300 rounded-md shadow-md hover:bg-gray-500 hover:border'
						>
							{this.props.cancelText ?? 'Cancelar'}
						</button>
						<button
							type='button'
							onClick={() => {
								if (this.props.onConfirm !== undefined) {
									this.props.onConfirm();
								}
								ModalManager.close();
							}}
							className='px-4 py-2 mt-4 mb-4 mr-4 border border-gray-300 rounded-md shadow-md bg-amarelo-eq hover:bg-yellow-200 hover:border'
						>
							{this.props.confirmText ?? 'Confirmar'}
						</button>
					</div>
				</div>
			</Modal>
		);
	}
}
