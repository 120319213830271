import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSystemContext } from '../Context/SystemContext';
import { GroupInput, Input } from '../components/Input';
import '../styles.css';

import logoEQBPF from '../assets/logo_eqbpf.png';
import logoEQLGPD from '../assets/logo_eqlgpd.png';
import logoLogin from '../assets/logo_sgt.png';

const yup = require('../interface/yup-br');

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

export default function Login() {
    const { login } = useSystemContext();
    const [message, setMessage] = useState('');

    async function handleLogin(values) {
        let response = await login(values);
        if (response.status === 200) {
            window.location.href = '/';
        } else {
            console.log('@@@@', response);
            setMessage('Usuário ou senha inválidos');
            localStorage.removeItem('token');
        }
    }
    let actualLogo = logoLogin;
    if (window.location.href.indexOf('eqbpf') > -1) {
        actualLogo = logoEQBPF;
    }
    if (window.location.href.indexOf('eqlgpd') > -1) {
        actualLogo = logoEQLGPD;
    }
    return (
        <section className="h-screen">
            <div className="container h-full px-6 py-12 ">
                <div className="flex flex-wrap items-center justify-center h-full">
                    <div className="p-10 ml-20 text-gray-800 bg-yellow-100 border border-gray-500 rounded shadow-md w-72">
                        <div className="py-2 text-center">
                            <img src={actualLogo} alt="logo" />
                        </div>
                        <div className="py-2 text-2xl text-center">Login</div>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={schema}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    handleLogin(values);
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            <Form>
                                <GroupInput>
                                    <Input name="email" type="email" label="Email" />
                                </GroupInput>
                                <GroupInput>
                                    <Input name="password" type="password" label="Senha" />
                                </GroupInput>
                                <div className="w-full py-2 text-center text-gray-800">
                                    <button
                                        type="submit"
                                        className="w-3/4 py-2 border rounded shadow-md bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte-hover"
                                    >
                                        Enviar
                                    </button>
                                </div>
                                <div className="w-full text-center text-red-500">{message}</div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
}
