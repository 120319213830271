import { useContext, useState } from 'react';
import { VscFilter as FilterOFF, VscFilterFilled as FilterON, VscClose, VscTrash } from 'react-icons/vsc';
import { v4 as uuid } from 'uuid';
import { dateToString } from '../helper/dates';
import { useGridContext } from './GridContext';

export const GridHeadFilterMenu = ({ col }) => {
    const { methods, property } = useContext(useGridContext);

    const [isFloatDivOpen, setIsFloatDivOpen] = useState(false);
    const [currentOperator, setCurrentOperator] = useState(col.type === 'date' ? 'eq' : 'in');
    const [inputTag, setInputTag] = useState('');

    let esquerda = 0;
    let topo = 0;

    const toggleFloatDiv = () => setIsFloatDivOpen(!isFloatDivOpen);
    if (col.colRef && col.colRef.current) {
        const rect = col.colRef.current.getBoundingClientRect();
        topo = rect.top + rect.height + 3;
        esquerda = rect.left + rect.width - 353;
        if (esquerda < 10) esquerda = 10;
    }
    const AddTag = (col) => {
        methods.AddFilterTag(col.field, inputTag, currentOperator, true, col.type);
        setInputTag('');
        //toggleFloatDiv();
    };

    const FormatColTag = (filter) => {
        if (col.filter?.type === 'select') {
            return col.filter.definition.find((item) => item.value === filter.tag).label;
        } else {
            switch (filter.type) {
                case 'date':
                    let dt = new Date(filter.tag + ' 00:00:00');
                    return dateToString(dt);
                default:
                    return filter.tag;
            }
        }
    };

    const OPList = ({ type }) => {
        return methods.OPList(type).map((item) => {
            return (
                <div
                    key={uuid()}
                    className={`h-7 rounded border border-gray-600 pt-1 pl-1 text-center  ${item.op === currentOperator ? 'bg-amarelo-eq' : ''} `}
                    onClick={() => {
                        setCurrentOperator(item.op);
                    }}
                >
                    {item.title}
                </div>
            );
        });
    };

    return (
        <div>
            {property.filters.some((item) => item.field === col.field) ? (
                <FilterON className="mt-1 ml-auto text-orange-600 cursor-pointer" onClick={toggleFloatDiv} />
            ) : (
                <FilterOFF className="mt-1 ml-auto cursor-pointer" onClick={toggleFloatDiv} />
            )}

            {isFloatDivOpen && (
                <div className="fixed inset-0 items-start w-full h-full overflow-y-auto bg-gray-600 bg-opacity-20" onClick={toggleFloatDiv}>
                    <div
                        className="fixed bg-gray-100 border border-gray-800 rounded shadow"
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            top: `${topo}px`,
                            left: `${esquerda}px`,
                            width: '350px',
                        }}
                    >
                        <div className="flex flex-col w-full font-normal">
                            <div className="flex justify-between w-full h-6 pl-1 bg-gray-400">
                                <span className="inline-flex w-full text-left">
                                    <FilterON className="mt-1 mr-1" />
                                    Filtros
                                </span>
                                <VscClose className="cursor-pointer hover:text-blue-600" style={{ fontSize: '1.5rem' }} title="Fechar" onClick={toggleFloatDiv} />
                            </div>
                            <div className="flex justify-between w-full pl-1 text-blue-500">{col.title}</div>
                            {property.filters.some((item) => item.field === col.field) && (
                                <div className="flex flex-wrap w-full gap-1 p-1">
                                    {property.filters
                                        .filter((item) => item.field === col.field)
                                        .map((item) => (
                                            <div key={item.tag} className="flex items-center max-w-full bg-gray-200 border border-gray-500 rounded ">
                                                <span className="p-1 overflow-hidden">
                                                    {methods.OPDecode(item.op)}: "{FormatColTag(item)}"
                                                </span>
                                                <VscTrash
                                                    className="p-1 cursor-pointer hover:text-red-600"
                                                    style={{ fontSize: '2rem' }}
                                                    title="Excluir filtro"
                                                    onClick={() => {
                                                        methods.DelFilterTag(item.field, item.tag);
                                                        //toggleFloatDiv();
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </div>
                            )}

                            <div className="w-full p-1 mt-2">
                                {col.filter?.type === 'select' && (
                                    <div>
                                        <div className="w-full ml-1 text-left">Opções do filtro</div>
                                        <select
                                            onSelect={(e) => {
                                                setInputTag(e.target.value);
                                            }}
                                            className="w-full p-1 border-2 rounded"
                                            value={inputTag}
                                            onChange={(e) => {
                                                setInputTag(e.target.value);
                                                setCurrentOperator('eq');
                                            }}
                                        >
                                            <option value="">Selecione...</option>
                                            {col.filter.definition.map((item) => (
                                                <option key={item.value} value={item.value}>
                                                    {item.label}
                                                </option>
                                            ))}
                                            }
                                        </select>
                                    </div>
                                )}
                                {col.filter?.type === undefined && (
                                    <input
                                        type={col.type}
                                        className="w-full p-1 border-2 rounded"
                                        placeholder="Conteúdo do filtro..."
                                        value={inputTag}
                                        onChange={(e) => setInputTag(e.target.value)}
                                    />
                                )}
                            </div>
                            {col.filter?.type === undefined && (
                                <div>
                                    <div className="w-full h-4 p-1 mt-1">
                                        <span>Operação:</span>
                                    </div>
                                    <div className="grid grid-flow-row grid-cols-3 grid-rows-1 gap-1 p-1 mt-2 text-xs font-normal">
                                        <OPList type={col.type} />
                                    </div>
                                </div>
                            )}
                            <div className="w-full p-1 pb-2 mt-2 ml-auto text-right">
                                <button onClick={() => AddTag(col)} className="px-2 text-white bg-blue-500 rounded h-7 hover:bg-blue-400">
                                    Aplicar filtro
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
