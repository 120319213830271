import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormInput, Input } from '../components/Input';
import { useSystemContext } from '../Context/SystemContext';
import Layout from '../Layout/Layout';

const yup = require('../interface/yup-br');

const ProfileConsultor = () => {
    const System = useSystemContext();
    const navigate = useNavigate();
    const UserDocument = '/user/' + System.property.user.id;
    return (
        <Layout>
            <div className="grow"></div>
            <div className="mt-4 w-1/5 grow-0 bg-white shadow">
                <FormInput
                    document={UserDocument}
                    title="Perfil do Usuário"
                    validationSchema={yup.object().shape({
                        name: yup.string().required(),
                        email: yup.string().email().required(),
                        password: yup.string().required(),
                        passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Senhas não conferem'),
                    })}
                    onAfterSave={() => {
                        navigate('/');
                    }}
                    onRenderFields={({ setFieldValue }) => {
                        return (
                            <div>
                                <Input name="name" label="Nome" className="block w-full" />
                                <Input name="email" label="E-mail" className="w-full" />
                                <div className="inline-flex">
                                    <Input name="password" label="Senha" type="password" />
                                    <Input name="passwordConfirm" label="Confirme" type="password" />
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
            <div className="grow"></div>
        </Layout>
    );
};

export default ProfileConsultor;
