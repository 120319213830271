import Grid from "../components/Grid";
import { BasicInput, Input } from "../components/Input";
import Layout from "../Layout/Layout";
import { PictureSchema } from "../validation/PictureSchema";

const RegistroFotograficoPage = () => {
    /*
    const [Picture, setPicture] = useState(
        {
        titulo: "",
        arquivo: ""
        }
    );
    */
    return (
        <Layout>
            <div className="grow"></div>
            <div className="w-1/2 glorw-0">
                <div className="mt-4 ml-2 bg-white grow">
                    <div className="border shadow">
                        <Grid
                            url={"/picture"}
                            modalHeight={'30%'}
                            GridHeaders={[
                                { title: "Título", field: "titulo", sort: true, className: 'w-1/2 text-left pl-1' },
                                { title: "Arquivo", field: "arquivo", sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' }
                            ]}
                            GridTitle={"Registro Fotográfico"}
                            LineDataPrint={(item) => {
                                return [
                                    item.titulo, `#view=${item.arquivo}|link=${item.arquivo}`
                                ]
                            }}
                            onRenderForm={TelaPicture}
                            initialValues={{ titulo: "", arquivo: "" }}
                            validationSchema={PictureSchema}
                            canEdit={false}
                            canDelete={true}
                            onPrepareSaveRecord={(values) => {
                                let newForm = new FormData();
                                for (let key in values) { newForm.append(key, values[key]); }
                                return newForm;
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="grow"></div>
        </Layout>
    );
    function TelaPicture(GridModes, setFieldValue) {
        return (
            <>
                <Input name="titulo" label="Título" className="w-92" />
                <BasicInput
                    name="arquivo"
                    label="Arquivo"
                    type="file"
                    onChange={(e) => {
                        setFieldValue('arquivo', e.target.files[0]);
                    }}
                />
            </>
        );
    }
}
export default RegistroFotograficoPage;