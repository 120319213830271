import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useSystemContext } from '../../Context/SystemContext';
import RACI from '../../assets/RACI.jpeg';
import Grid from '../../components/Grid';
import { Grid5W2H } from '../../components/Grid5W2H';
import { GridAnexos } from '../../components/GridAnexos';
import { CheckBoxInput, DBSelectInput, FormInput, GroupInput, Input, TextInput } from '../../components/Input';

const yup = require('../../interface/yup-br');

export const Form51 = () => {
    const System = useSystemContext();
    return (
        <FormInput
            document="/document/form51"
            perms={'51'}
            title={System.getMenuTitle(51)}
            initialValues={{
                conteudo: '',
            }}
            validationSchema={yup.object().shape({
                conteudo: yup.string(),
            })}
            onRenderFields={() => {
                return (
                    <div className="w-full">
                        <TextInput name="conteudo" label="Conteúdo" className="w-full" />
                    </div>
                );
            }}
        />
    );
};
export const Form52 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form52"
                        perms={'52'}
                        title={System.getMenuTitle(52)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form52'} perms="52" />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const Form53 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center" color="#FFDDFF">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form53"
                        perms={'53'}
                        title={System.getMenuTitle(53)}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                            estrutura: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" />
                                    <TextInput name="estrutura" label="Estrutura Organizacional" className="w-full" />
                                </div>
                            );
                        }}
                    />
                    <img src={RACI} alt="RACI" className="w-full" />
                </TabPanel>
                <TabPanel name="Gestao">
                    <Grid
                        url={'/griddocument/form53'}
                        perms={'53'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Requisito', field: 'requisito', sort: true, className: 'w-2/12 text-left pl-1' },
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-10/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.requisito, data.descricao];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="justify-left inline-flex h-8 w-full bg-amarelo-eq py-1 pl-2">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>

                                    <GroupInput>
                                        <Input name="requisito" label="Requisito" />
                                        <Input name="descricao" label="Descrição" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Funções</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <Grid
                                                    url={'/griddocument/grid53-' + item._id}
                                                    perms={'53'}
                                                    disableModal={true}
                                                    GridHeaders={[
                                                        { title: 'Função', field: 'role', sort: true, className: 'w-full text-left pl-1' },
                                                        { title: 'Responsável', field: 'respons', sort: false, className: 'w-24 pl-1 text-left border-l border-gray-300' },
                                                        { title: 'Autoridade', field: 'autoridade', sort: false, className: 'w-24 pl-1 text-left border-l border-gray-300' },
                                                        { title: 'Consultado', field: 'consultado', sort: false, className: 'w-24 pl-1 text-left border-l border-gray-300' },
                                                        { title: 'Informado', field: 'informado', sort: false, className: 'w-24 pl-1 text-left border-l border-gray-300' },
                                                    ]}
                                                    GridTitle="Funções"
                                                    LineDataPrint={(data) => {
                                                        return [
                                                            data.funcao?.nome,
                                                            data.respons ? 'Sim' : 'Não',
                                                            data.autoridade ? 'Sim' : 'Não',
                                                            data.consultado ? 'Sim' : 'Não',
                                                            data.informado ? 'Sim' : 'Não',
                                                        ];
                                                    }}
                                                    onRenderForm={() => {
                                                        return (
                                                            <div className="w-full">
                                                                <DBSelectInput name="role" label="Função" url="/role" displaySelectField="nome" />
                                                                <GroupInput>
                                                                    <CheckBoxInput name="respons" label="Responsável" />
                                                                    <CheckBoxInput name="autoridade" label="Autoridade" />
                                                                    <CheckBoxInput name="consultado" label="Consultado" />
                                                                    <CheckBoxInput name="informado" label="Informado" />
                                                                </GroupInput>
                                                            </div>
                                                        );
                                                    }}
                                                    validationSchema={() => {
                                                        return yup.object().shape({
                                                            role: yup.string(),
                                                            respons: yup.boolean(),
                                                            autoridade: yup.boolean(),
                                                            consultado: yup.boolean(),
                                                            informado: yup.boolean(),
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid53-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            requisito: yup.string(),
                            descricao: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form53'} perms="53" />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const Form54 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center" color="#FFDDFF">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center" color="#FFDDFF">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form54"
                        url="/method5w2h/form54"
                        title={System.getMenuTitle(54)}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestao">
                    <Grid5W2H url={`/method5w2h/form54`} perms="54" useAttachments="form54" />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form54'} perms="54" />
                </TabPanel>
            </Tabs>
        </div>
    );
};
