import React, { useState } from 'react';
import { BiCaretDown, BiCaretUp, BiMenu } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';
import Layout from '../Layout/Layout';
import { Form11, Form12 } from './forms/Form_1';
import { Form21 } from './forms/Form_2';
import { Form31 } from './forms/Form_3';
import { Form41, Form42, Form43, Form44 } from './forms/Form_4';
import { Form51, Form52, Form53, Form54 } from './forms/Form_5';
import { Form61, Form612, Form613, Form614, Form62, Form63 } from './forms/Form_6';
import { Form711, Form712SGI, Form713, Form714, Form715, Form716, Form72, Form73, Form74, Form75, Form753, Form78 } from './forms/Form_7';
import {
    Form81,
    Form812,
    Form813,
    Form814,
    Form82,
    Form821,
    Form822,
    Form823,
    Form824,
    Form82SGI,
    Form83,
    Form84,
    Form841,
    Form842,
    Form843,
    Form851,
    Form852,
    Form853,
    Form854,
    Form855,
    Form856,
    Form86,
    Form87,
} from './forms/Form_8';
import { Form91, Form911, Form912, Form912SGI, Form913, Form92, Form93 } from './forms/Form_9';
import { FormA1, FormA2SGI, FormA3 } from './forms/Form_A';

import {
    RDC11,
    RDC12,
    RDC13,
    RDC14,
    RDC15,
    RDC16,
    RDC17,
    RDC21,
    RDC210,
    RDC22,
    RDC23,
    RDC24,
    RDC25,
    RDC26,
    RDC27,
    RDC28,
    RDC29,
    RDC31,
    RDC32,
    RDC33,
    RDC34,
    RDC41,
    RDC42,
    RDC51,
    RDC52,
    RDC53,
    RDC54,
    RDC55,
    RDC56,
    RDC57,
    RDC61,
    RDC62,
    RDC63,
    RDC64,
    RDC71,
    RDC72,
    RDC81,
    RDC91,
} from './forms/Form_RDC';

//import { useEffect } from 'react';
import { useSystemContext } from '../Context/SystemContext';

const DatabasePage = (props) => {
    //const { getUser, notificationError, getUserRule } = useSystemContext();
    const System = useSystemContext();
    //const user = getUser();
    const [Menu, setMainMenu] = useState(parseInt(props.defaultMenu));
    const [menuOpen, setMenuOpen] = useState(0);

    //useEffect(() => {
    //let user = getUser();
    //apiGet(`/crewbyuserid/${user.id}`).then((response) => {
    //setUserHole(response.data?.funcao);
    //});
    // eslint-disable-next-line
    //}, []);

    return (
        <Layout>
            <div className="h-full ml-2 text-sm">{MenuDatabase()}</div>
            <div className="w-2 bg-yellow-50"></div>
            <div className="w-full h-full pt-2 bg-yellow-50">
                {Menu > 0 && (
                    <div className="mt-2 bg-white border border-gray-300 rounded-t shadow">
                        {Menu === 11 && <Form11 />}
                        {Menu === 12 && <Form12 />}
                        {Menu === 21 && <Form21 />}
                        {Menu === 31 && <Form31 />}
                        {Menu === 41 && <Form41 />}
                        {Menu === 42 && <Form42 />}
                        {Menu === 43 && <Form43 />}
                        {Menu === 44 && <Form44 />}
                        {Menu === 51 && <Form51 />}
                        {Menu === 52 && <Form52 />}
                        {Menu === 53 && <Form53 />}
                        {Menu === 54 && <Form54 />}
                        {Menu === 61 && <Form61 />}
                        {Menu === 612 && <Form612 />}
                        {Menu === 613 && <Form613 />}
                        {Menu === 614 && <Form614 />}
                        {Menu === 62 && <Form62 />}
                        {Menu === 63 && <Form63 />}
                        {Menu === 711 && <Form711 />}
                        {Menu === 712 && <Form712SGI />}
                        {Menu === 713 && <Form713 />}
                        {Menu === 714 && <Form714 />}
                        {Menu === 715 && <Form715 />}
                        {Menu === 716 && <Form716 />}
                        {Menu === 72 && <Form72 />}
                        {Menu === 73 && <Form73 />}
                        {Menu === 74 && <Form74 />}
                        {Menu === 75 && <Form75 />}
                        {Menu === 753 && <Form753 />}
                        {Menu === 78 && <Form78 />}
                        {Menu === 81 && <Form81 />}
                        {Menu === 812 && <Form812 />}
                        {Menu === 813 && <Form813 />}
                        {Menu === 814 && <Form814 />}
                        {Menu === 82 && <Form82 />}
                        {Menu === 82001 && <Form82SGI />}
                        {Menu === 821 && <Form821 />}
                        {Menu === 822 && <Form822 />}
                        {Menu === 823 && <Form823 />}
                        {Menu === 824 && <Form824 />}
                        {Menu === 83 && <Form83 />}
                        {Menu === 84 && <Form84 />}
                        {Menu === 841 && <Form841 />}
                        {Menu === 842 && <Form842 />}
                        {Menu === 843 && <Form843 />}
                        {Menu === 851 && <Form851 />}
                        {Menu === 852 && <Form852 />}
                        {Menu === 853 && <Form853 />}
                        {Menu === 854 && <Form854 />}
                        {Menu === 855 && <Form855 />}
                        {Menu === 856 && <Form856 />}
                        {Menu === 86 && <Form86 />}
                        {Menu === 87 && <Form87 />}
                        {Menu === 91 && <Form91 />}
                        {Menu === 911 && <Form911 />}
                        {Menu === 912 && <Form912 />}
                        {Menu === 912001 && <Form912SGI />}
                        {Menu === 913 && <Form913 />}
                        {Menu === 92 && <Form92 />}
                        {Menu === 93 && <Form93 />}
                        {Menu === 101 && <FormA1 />}
                        {Menu === 102001 && <FormA2SGI />}
                        {Menu === 103 && <FormA3 />}

                        {Menu === 2001001 && <RDC11 />}
                        {Menu === 2001002 && <RDC12 />}
                        {Menu === 2001003 && <RDC13 />}
                        {Menu === 2001004 && <RDC14 />}
                        {Menu === 2001005 && <RDC15 />}
                        {Menu === 2001006 && <RDC16 />}
                        {Menu === 2001007 && <RDC17 />}

                        {Menu === 2002001 && <RDC21 />}
                        {Menu === 2002002 && <RDC22 />}
                        {Menu === 2002003 && <RDC23 />}
                        {Menu === 2002004 && <RDC24 />}
                        {Menu === 2002005 && <RDC25 />}
                        {Menu === 2002006 && <RDC26 />}
                        {Menu === 2002007 && <RDC27 />}
                        {Menu === 2002008 && <RDC28 />}
                        {Menu === 2002009 && <RDC29 />}
                        {Menu === 2002010 && <RDC210 />}

                        {Menu === 2003001 && <RDC31 />}
                        {Menu === 2003002 && <RDC32 />}
                        {Menu === 2003003 && <RDC33 />}
                        {Menu === 2003004 && <RDC34 />}

                        {Menu === 2004001 && <RDC41 />}
                        {Menu === 2004002 && <RDC42 />}

                        {Menu === 2005001 && <RDC51 />}
                        {Menu === 2005002 && <RDC52 />}
                        {Menu === 2005003 && <RDC53 />}
                        {Menu === 2005004 && <RDC54 />}
                        {Menu === 2005005 && <RDC55 />}
                        {Menu === 2005006 && <RDC56 />}
                        {Menu === 2005007 && <RDC57 />}

                        {Menu === 2006001 && <RDC61 />}
                        {Menu === 2006002 && <RDC62 />}
                        {Menu === 2006003 && <RDC63 />}
                        {Menu === 2006004 && <RDC64 />}

                        {Menu === 2007001 && <RDC71 />}
                        {Menu === 2007002 && <RDC72 />}

                        {Menu === 2008001 && <RDC81 />}

                        {Menu === 2009001 && <RDC91 />}
                    </div>
                )}
            </div>
        </Layout>
    );
    function MenuDatabase() {
        function setMenu(item) {
            if (System.property.user.level <= 1) {
                if (!System.methods.getUserRule('menu' + item)) {
                    System.methods.notificationError('Seu nível não tem acesso a esta área!');
                    return;
                }
            }
            setMainMenu(item);
        }
        function renderMainMenu(data) {
            function renderSubMenu(item) {
                if (item.length === 0) {
                    return null;
                }
                return (
                    <div>
                        {item.map((entry) => {
                            const key = Object.keys(entry)[0];
                            const value = entry[key];
                            let subitem = false;
                            if (entry.hasOwnProperty('sub')) {
                                subitem = entry.sub;
                            }
                            return (
                                <div
                                    key={uuidv4()}
                                    onClick={() => setMenu(parseInt(key))}
                                    className={`pl-4 hover:bg-gray-300 ${subitem ? 'ml-2' : null} ${Menu === parseInt(key) ? 'bg-gray-200' : 'bg-white'} `}
                                >
                                    {value}
                                </div>
                            );
                        })}
                    </div>
                );
            }
            function renderMenu(key, value) {
                let clickSelf = null;
                if (value.hasOwnProperty('self')) {
                    clickSelf = value.self;
                }
                return (
                    <div key={uuidv4()} className="pl-2 text-left bg-white border-b border-l border-r">
                        <div className={`w-full cursor-pointer`}>
                            <div className="inline-flex hover:bg-gray-300" onClick={!clickSelf ? () => setMenuOpen(key) : () => setMenu(clickSelf)}>
                                {value.menu} {menuOpen === key ? <BiCaretUp className="mt-1" /> : <BiCaretDown className={`mt-1 ${clickSelf ? 'invisible' : 'visible'}`} />}
                            </div>
                            {menuOpen === key && <>{renderSubMenu(value.submenu)}</>}
                        </div>
                    </div>
                );
            }
            return (
                <div className="pl-2 text-left bg-white border-b border-l border-r">
                    <div className={`w-full cursor-pointer`}>{Object.entries(data).map(([key, value]) => renderMenu(key, value))}</div>
                </div>
            );
        }

        return (
            <div className="pt-4 overflow-y-auto rounded h-4/6 w-72">
                <div className="inline-flex w-full pl-2 text-xl rounded-t h-7 bg-amarelo-eq">
                    <BiMenu className="mt-1 mr-1" /> Banco de Dados
                </div>
                {renderMainMenu(companyMenu(System.property.user.companyNorma))}
            </div>
        );
    }
};

export function companyMenu(norma) {
    switch (norma) {
        case 'RDC-665':
            return MenuRDC_JSON();
        case 'SGI':
            return MenuSGI_JSON();
        case 'ISO-9001':
            return MenuISO_JSON();
        case 'LGPD':
            return MenuLGPD_JSON();
        default:
            return MenuSGI_JSON();
    }
}

export function MenuRDC_JSON() {
    let data = {
        1: {
            menu: '1 - Disposições iniciais',
            submenu: [
                { 11: 'Dados cadastrais da organização' },
                { 12: 'Compromisso com a conduta ética' },
                { 21: 'Objetivo do manual de garantia da qualidade' },
                { 31: 'Referência normativa e regulamentar' },
                { 43: 'Escopo do sistema de gestão' },
                { 41: 'Entendendo a organização e seu contexto' },
                { 42: 'Entendendo as necessidades e expectativas de partes interessadas' },
            ],
        },
        2: {
            menu: '2 - Requisitos Gerais do Sistema da Qualidade',
            submenu: [
                { 44: 'Processos' },
                { 613: 'Requisitos legais' },
                { 52: 'Política da qualidade' },
                { 62: 'Objetivos da qualidade' },
                { 53: 'Organização e responsabilidades' },
                { 74: 'Comunicação' },
                { 93: 'Revisão gerencial' },
                { 712: 'Pessoal' },
                { 61: 'Gerenciamento de risco' },
                { 84: 'Controles de compra' },
            ],
        },
        3: {
            menu: '3 - Documentos e Registros da Qualidade',
            submenu: [
                { 75: 'Documentos e registros' },
                { 753: 'Controle de documentos externos' },
                { 2003003: 'Registro histórico do produto' },
                { 2003004: 'Registros de inspeções e testes' },
            ],
        },
        4: {
            menu: '4 - Controle de Projeto e Registro Mestre de Produto',
            submenu: [{ 83: 'Controle de projeto' }, { 2004002: 'Registro mestre do produto (RMP)' }],
        },
        5: {
            menu: '5 - Controles de Processo e Produção',
            submenu: [
                { 2005001: 'Controles de processo - requisitos gerais' },
                { 713: 'Infraestrutura / manutenção' },
                { 2005003: 'Controle de embalagem, rotulagem e instruções de uso' },
                { 2005004: 'Inspeção e testes' },
                { 715: 'Equipamentos de medição e testes' },
                { 2005006: 'Validação' },
                { 63: 'Planejamento de mudanças' },
            ],
        },
        6: {
            menu: '6 - Manuseio, Armazenamento, Distribuição e Rastreabilidade',
            submenu: [
                { 2006001: 'Manuseio' },
                { 2006002: 'Armazenamento e distribuição' },
                { 853: 'Propriedade pertencente a clientes ou fornecedores' },
                { 2006004: 'Identificação, rastreabilidade e não conformidades' },
            ],
        },
        7: {
            menu: '7 - Ações Corretivas e Preventivas',
            submenu: [{ 102001: 'Requisitos gerais / gerenciamento das reclamações' }, { 92: 'Auditoria da qualidade' }],
        },
        8: {
            menu: '8 - Instalação e Assistência Técnica',
            self: 2008001,
            submenu: [],
        },
        9: {
            menu: '9 - Técnicas Estatísticas',
            self: 911,
            submenu: [],
        },
    };
    return data;
}

export function MenuSGI_JSON() {
    let data = {
        1: {
            menu: '1 - Apresentação',
            submenu: [{ 11: '1.1 Dados cadastrais da organização' }, { 12: '1.2 Compromisso com a conduta e ética' }],
        },
        2: {
            menu: '2 - Objetivo do manual do sistema de gestão integrado',
            self: 21,
            submenu: [],
        },
        3: {
            menu: '3 - Referência normativa e regulamentar',
            self: 31,
            submenu: [],
        },
        4: {
            menu: '4 - Contexto da organização',
            submenu: [
                { 41: '4.1 Entendendo a organização e seu contexto' },
                { 42: '4.2 Entendendo as necessidades e expectativas de partes interessadas' },
                { 43: '4.3 Determinando o escopo do sistema de gestão da qualidade' },
                { 44: '4.4 Sistema de gestão da qualidade e seus processos' },
            ],
        },
        5: {
            menu: '5 - Liderança',
            submenu: [
                { 51: '5.1 Liderança e comprometimento' },
                { 52: '5.2 Política' },
                { 53: '5.3 Papéis, responsabilidades e autoridades organizacionais' },
                { 54: '5.4 Consulta e participação de trabalhadores' },
            ],
        },
        6: {
            menu: '6 - Planejamento',
            submenu: [
                { 61: '6.1 Ações para abordar riscos e oportunidades' },
                { 612: '6.1.2 Aspectos Ambientais / Identificação de perigo e avaliação de riscos e oportunidades' },
                { 613: '6.1.3 Requisitos legais e outros requisitos' },
                { 614: '6.1.4 Planejamento de ações ' },
                { 62: '6.2 Objetivos do SGI e planejamento para alcançá-los' },
                { 63: '6.3 Planejamento de mudanças' },
            ],
        },
        7: {
            menu: '7 - Suporte',
            submenu: [
                { 711: '7.1 Recursos' },
                { 711: '7.1.1 Generalidades', sub: true },
                { 712: '7.1.2 Pessoas', sub: true },
                { 713: '7.1.3 Infraestrutura', sub: true },
                { 714: '7.1.4 Ambiente para a operação dos processos', sub: true },
                { 715: '7.1.5 Recursos de monitoramento e medição', sub: true },
                { 716: '7.1.6 Conhecimento organizacional', sub: true },
                { 72: '7.2 Competência' },
                { 73: '7.3 Conscientização' },
                { 74: '7.4 Comunicação' },
                { 75: '7.5 Informação documentada' },
                { 753: '7.5.3 Controle de documentos externos' },
            ],
        },
        8: {
            menu: '8 - Operação',
            submenu: [
                { 81: '8.1 Planejamento e controle operacionais' },
                { 812: '8.1.2 Eliminar perigos e reduzir riscos de SSO' },
                { 813: '8.1.3 Gestão da mudança' },
                { 814: '8.1.4 Aquisição' },
                { 82001: '8.2 Preparação e Resposta de Emergência' },
                { 821: '8.2.1 Comunicação com o cliente', sub: true },
                { 822: '8.2.2 Determinação de requisitos relativos a produtos e serviços', sub: true },
                { 823: '8.2.3 Análise crítica de requisitos relativos a produtos e serviços', sub: true },
                { 824: '8.2.4 Mudanças nos requisitos para produtos e serviços', sub: true },
                { 83: '8.3 Projeto e desenvolvimento de produtos e serviços' },
                { 84: '8.4 Controle de Processos, Produtos e Serviços Providos Externamente' },
                { 841: '8.4.1 Generalidades', sub: true },
                { 842: '8.4.2 Tipo e Extensão do Controle', sub: true },
                { 843: '8.4.3 Informação para Provedores Externos', sub: true },
                { 851: '8.5 Produção e provisão de serviço' },
                { 851: '8.5.1 Controle de produção e de provisão de serviço', sub: true },
                { 852: '8.5.2 Identificação e Rastreabilidade', sub: true },
                { 853: '8.5.3 Propriedade pertencente a clientes ou provedores externos', sub: true },
                { 854: '8.5.4 Preservação', sub: true },
                { 855: '8.5.5 Atividades pós entrega', sub: true },
                { 856: '8.5.6 Controle de mudanças', sub: true },
                { 86: '8.6 Liberação de produtos e serviços' },
                { 87: '8.7 Controle de saídas não conformes' },
            ],
        },
        9: {
            menu: '9 - Avaliação de desempenho',
            submenu: [
                { 91: '9.1. Monitoramento, medição, análise e avaliação' },
                { 911: '9.1.1 Generalidades', sub: true },
                { 912: '9.1.2 Satisfação do cliente', sub: true },
                { 912001: '9.1.2 Avaliação do atendimento aos requisitos legais e outros requisitos', sub: true },
                { 913: '9.1.3 Análise e avaliação', sub: true },
                { 92: '9.2 Auditoria interna' },
                { 93: '9.3 Análise crítica pela direção' },
            ],
        },
        10: {
            menu: '10 - Melhoria',
            submenu: [{ 101: '10.1 Generalidades' }, { 102001: '10.2 Incidente, Não conformidade e ação corretiva' }, { 103: '10.3 Melhoria contínua' }],
        },
    };
    return data;
}

export function MenuISO_JSON() {
    let data = {
        1: {
            menu: '1 - Apresentação',
            submenu: [{ 11: '1.1 Dados cadastrais da organização' }, { 12: '1.2 Compromisso com a conduta e ética' }],
        },
        2: {
            menu: '2 - Objetivo do manual do sistema de gestão integrado',
            self: 21,
            submenu: [],
        },
        3: {
            menu: '3 - Referência normativa e regulamentar',
            self: 31,
            submenu: [],
        },
        4: {
            menu: '4 - Contexto da organização',
            submenu: [
                { 41: '4.1 Entendendo a organização e seu contexto' },
                { 42: '4.2 Entendendo as necessidades e expectativas de partes interessadas' },
                { 43: '4.3 Determinando o escopo do sistema de gestão da qualidade' },
                { 44: '4.4 Sistema de gestão da qualidade e seus processos' },
            ],
        },
        5: {
            menu: '5 - Liderança',
            submenu: [{ 51: '5.1 Liderança e comprometimento' }, { 52: '5.2 Política' }, { 53: '5.3 Papéis, responsabilidades e autoridades organizacionais' }],
        },
        6: {
            menu: '6 - Planejamento',
            submenu: [
                { 61: '6.1 Ações para abordar riscos e oportunidades' },
                { 62: '6.2 Objetivos da qualidade e planejamento para alcançá-los' },
                { 63: '6.3 Planejamento de mudanças' },
            ],
        },
        7: {
            menu: '7 - Suporte',
            submenu: [
                { 711: '7.1 Recursos' },
                { 711: '7.1.1 Generalidades', sub: true },
                { 712: '7.1.2 Pessoas', sub: true },
                { 713: '7.1.3 Infraestrutura', sub: true },
                { 714: '7.1.4 Ambiente para a operação dos processos', sub: true },
                { 715: '7.1.5 Recursos de monitoramento e medição', sub: true },
                { 716: '7.1.6 Conhecimento organizacional', sub: true },
                { 72: '7.2 Competência' },
                { 73: '7.3 Conscientização' },
                { 74: '7.4 Comunicação' },
                { 75: '7.5 Informação documentada' },
                { 753: '7.5.3 Controle de documentos externos' },
            ],
        },
        8: {
            menu: '8 - Operação',
            submenu: [
                { 81: '8.1 Planejamento e controle operacionais' },
                { 82: '8.2 Requisitos para produtos e serviços' },
                { 821: '8.2.1 Comunicação com o cliente', sub: true },
                { 822: '8.2.2 Determinação de requisitos relativos a produtos e serviços', sub: true },
                { 823: '8.2.3 Análise crítica de requisitos relativos a produtos e serviços', sub: true },
                { 824: '8.2.4 Mudanças nos requisitos para produtos e serviços', sub: true },
                { 83: '8.3 Projeto e desenvolvimento de produtos e serviços' },
                { 84: '8.4 Controle de Processos, Produtos e Serviços Providos Externamente' },
                { 841: '8.4.1 Generalidades', sub: true },
                { 842: '8.4.2 Tipo e Extensão do Controle', sub: true },
                { 843: '8.4.3 Informação sobre o Controle', sub: true },
                { 851: '8.5 Controle de produção' },
                { 851: '8.5.1 Controle de produção e de provisão de serviço', sub: true },
                { 852: '8.5.2 Identificação e Rastreabilidade', sub: true },
                { 853: '8.5.3 Propriedade pertencente a clientes ou provedores externos', sub: true },
                { 854: '8.5.4 Preservação', sub: true },
                { 855: '8.5.5 Atividades pós entrega', sub: true },
                { 856: '8.5.6 Controle de mudanças', sub: true },
                { 86: '8.6 Liberação de produtos e serviços' },
                { 87: '8.7 Controle de saídas não conformes' },
            ],
        },
        9: {
            menu: '9 - Avaliação de desempenho',
            submenu: [
                { 91: '9.1. Monitoramento, medição, análise e avaliação' },
                { 911: '9.1.1. Generalidades', sub: true },
                { 912: '9.1.2. Satisfação do cliente', sub: true },
                { 913: '9.1.3. Análise e avaliação', sub: true },
                { 92: '9.2. Auditoria interna' },
                { 93: '9.3. Análise crítica pela direção' },
            ],
        },
        10: {
            menu: '10 - Melhoria',
            submenu: [{ 101: '10.1. Generalidades' }, { 102001: '10.2. Não conformidade e ação corretiva' }, { 103: '10.3. Melhoria contínua' }],
        },
    };
    return data;
}

export function MenuLGPD_JSON() {
    let data = {
        1: {
            menu: '1 - Apresentação',
            submenu: [{ 11: '1.1 Dados cadastrais da organização' }, { 12: '1.2 Compromisso com a conduta e ética' }],
        },
        2: {
            menu: '2 - Objetivo',
            self: 21,
            submenu: [],
        },
        3: {
            menu: '3 - Referência normativa e regulamentar',
            self: 31,
            submenu: [],
        },
        4: {
            menu: '4 - Contexto da organização',
            submenu: [
                { 41: '4.1 Entendendo a organização e seu contexto' },
                { 42: '4.2 Entendendo as necessidades e expectativas de partes interessadas' },
                { 43: '4.3 Determinando o escopo da organização' },
            ],
        },
        5: {
            menu: '5 - Liderança',
            submenu: [{ 51: '5.1 Liderança e comprometimento' }, { 52: '5.2 Política(s)' }, { 53: '5.3 Papéis, responsabilidades e autoridades organizacionais' }],
        },
        6: {
            menu: '6 - Planejamento',
            submenu: [
                { 61: '6.1 Ações para abordar riscos e oportunidades' },
                { 62: '6.2 Objetivos da LGPD e planejamento para alcançá-los' },
                { 63: '6.3 Planejamento de mudanças' },
            ],
        },
        7: {
            menu: '7 - Apoio',
            submenu: [
                { 711: '7.1 Recursos' },
                { 712: '7.2 Pessoas' },
                { 713: '7.3 Infraestrutura' },
                { 72: '7.4 Competência' },
                { 73: '7.5 Conscientização' },
                { 74: '7.6 Comunicação' },
                { 75: '7.7 Informação documentada' },
                { 78: '7.8 Controle de documentos externos' },
            ],
        },
        8: {
            menu: '8 - Avaliação de desempenho',
            submenu: [{ 92: '8.1. Auditoria interna' }, { 93: '8.2. Análise crítica pela direção' }],
        },
        9: {
            menu: '9 - Melhoria',
            submenu: [
                {
                    102001: '9.1 Incidente, Não conformidade e ação corretiva',
                },
                { 103: '9.2 Melhoria contínua' },
            ],
        },
    };
    return data;
}

export function _MenuRDC_JSON() {
    let data = {
        1: {
            menu: '1 - Disposições iniciais',
            submenu: [
                { 2001001: 'Dados cadastrais da organização' },
                { 2001002: 'Compromisso com a conduta ética' },
                { 2001003: 'Objetivo do manual de garantia da qualidade' },
                { 2001004: 'Referência normativa e regulamentar' },
                { 2001005: 'Escopo do sistema de gestão' },
                { 2001006: 'Entendendo a organização e seu contexto' },
                { 2001007: 'Entendendo as necessidades e expectativas de partes interessadas' },
            ],
        },
        2: {
            menu: '2 - Requisitos Gerais do Sistema da Qualidade',
            submenu: [
                { 2002001: 'Processos' },
                { 2002002: 'Requisitos legais' },
                { 2002003: 'Política da qualidade' },
                { 2002004: 'Objetivos da qualidade' },
                { 2002005: 'Organização e responsabilidades' },
                { 2002006: 'Comunicação' },
                { 2002007: 'Revisão gerencial' },
                { 2002008: 'Pessoal' },
                { 2002009: 'Gerenciamento de risco' },
                { 2002010: 'Controles de compra' },
            ],
        },
        3: {
            menu: '3 - Documentos e Registros da Qualidade',
            submenu: [
                { 2003001: 'Documentos e registros' },
                { 2003002: 'Controle de documentos externos' },
                { 2003003: 'Registro histórico do produto' },
                { 2003004: 'Registros de inspeções e testes' },
            ],
        },
        4: {
            menu: '4 - Controle de Projeto e Registro Mestre de Produto',
            submenu: [{ 2004001: 'Controle de projeto' }, { 2004002: 'Registro mestre do produto (RMP)' }],
        },
        5: {
            menu: '5 - Controles de Processo e Produção',
            submenu: [
                { 2005001: 'Controles de processo - requisitos gerais' },
                { 2005002: 'Infraestrutura / manutenção' },
                { 2005003: 'Controle de embalagem, rotulagem e instruções de uso' },
                { 2005004: 'Inspeção e testes' },
                { 2005005: 'Equipamentos de medição e testes' },
                { 2005006: 'Validação' },
                { 2005007: 'Planejamento de mudanças' },
            ],
        },
        6: {
            menu: '6 - Manuseio, Armazenamento, Distribuição e Rastreabilidade',
            submenu: [
                { 2006001: 'Manuseio' },
                { 2006002: 'Armazenamento e distribuição' },
                { 2006003: 'Propriedade pertencente a clientes ou fornecedores' },
                { 2006004: 'Identificação, rastreabilidade e não conformidades' },
            ],
        },
        7: {
            menu: '7 - Ações Corretivas e Preventivas',
            submenu: [{ 2007001: 'Requisitos gerais / gerenciamento das reclamações' }, { 2007002: 'Auditoria da qualidade' }],
        },
        8: {
            menu: '8 - Instalação e Assistência Técnica',
            submenu: [{ 2008001: 'Requisitos gerais' }],
        },
        9: {
            menu: '9 - Técnicas Estatísticas',
            submenu: [{ 2009001: 'Requisitos gerais' }],
        },
    };
    return data;
}

export default DatabasePage;
