import jsPDF from 'jspdf';
import { createRef, useEffect, useRef, useState } from 'react';
import { FaPrint } from 'react-icons/fa';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { v4 as uuidv4 } from 'uuid';
import { useSystemContext } from '../../Context/SystemContext';
import kirkpatrick from '../../assets/kirkpatrick.png';
import Grid from '../../components/Grid';
import { Grid5W2H } from '../../components/Grid5W2H';
import { GridAnexos } from '../../components/GridAnexos';
import { GridDiario } from '../../components/GridDiario';
import {
    AttachmentInput,
    DBMultiSelectInput,
    DBSelectInput,
    DateInput,
    DropboxDBInput,
    FormInput,
    GroupInput,
    Input,
    SelectInput,
    TabsInput,
    TextInput,
} from '../../components/Input';
import { showConfirm } from '../../components/Messages';
import { AdicionarDias, dateToString, dateToStringFull } from '../../helper/dates';

const yup = require('../../interface/yup-br');

export const Form711 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form711"
                perms={'711'}
                title={System.getMenuTitle(711)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form712SGI = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <div>
                        <FormInput
                            document="/document/form712SGI"
                            perms={'712'}
                            title={System.getMenuTitle(712)}
                            initialValues={{
                                conteudo: '',
                            }}
                            validationSchema={yup.object().shape({
                                conteudo: yup.string(),
                            })}
                            onRenderFields={() => {
                                return (
                                    <div className="w-full">
                                        <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                    </div>
                                );
                            }}
                        />
                        <img src={kirkpatrick} alt="kirkpatrick" />
                    </div>
                </TabPanel>
                <TabPanel name="Gestão de Pessoas">
                    <Grid
                        url={'/griddocument/grid712SGI'}
                        perms={'712'}
                        disableModal={true}
                        clearAutoEdit={true}
                        GridTitle={'7.1.2 - Gestão de Pessoas'}
                        GridHeaders={[
                            { title: 'Id', field: '#crew.identificacao', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Nome', field: 'crew.nome', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Admissão', field: '@crew.admissao', sort: true, type: 'date', className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Função', field: 'crew.funcao.nome', sort: true, className: 'w-3/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.crew?.identificacao, data.crew?.nome, dateToString(data.crew?.admissao), data.crew?.funcao?.nome];
                        }}
                        onPrepareSaveRecord={(values) => {
                            let newForm = new FormData();
                            for (let key in values) {
                                newForm.append(key, values[key]);
                            }
                            return newForm;
                        }}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <DBSelectInput name="responsavel" label="Nome" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            responsavel: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Avaliação das competências</div>;
                            },
                            () => {
                                return <div>Plano de Desenvolvimento</div>;
                            },
                            () => {
                                return <div>EPI´s</div>;
                            },
                            () => {
                                return <div>ASO</div>;
                            },
                            () => {
                                return <div>Treinamento</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Avaliaçao
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid712SGI-avaliacao-${item._id}`}
                                            perms={'712'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Avaliação', field: 'avaliacao', sort: true, className: 'w-1/6 text-left pl-1' },
                                                { title: 'Superior imediato', field: 'resposavel', sort: false, className: 'w-4/6 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Próx.Avaliação', field: 'proxavaliacao', sort: false, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.avaliacao), data.crew?.nome, dateToString(data.proxavaliacao)];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="educacao"
                                                                label="Educação"
                                                                listOptions={[{ value: 'Atende' }, { value: 'Não atende' }, { value: 'Não aplicável' }]}
                                                            />
                                                            <SelectInput
                                                                name="treinamentos"
                                                                label="Treinamentos"
                                                                listOptions={[{ value: 'Atende' }, { value: 'Não atende' }, { value: 'Não aplicável' }]}
                                                            />
                                                            <SelectInput
                                                                name="habilidades"
                                                                label="Habilidades"
                                                                listOptions={[{ value: 'Atende' }, { value: 'Não atende' }, { value: 'Não aplicável' }]}
                                                            />
                                                            <SelectInput
                                                                name="experiencia"
                                                                label="Experiência"
                                                                listOptions={[{ value: 'Atende' }, { value: 'Não atende' }, { value: 'Não aplicável' }]}
                                                            />
                                                        </GroupInput>
                                                        <TextInput name="acoes" label="Ações necessárias para desenvolvimento" rows={3} />
                                                        <GroupInput>
                                                            <DateInput name="avaliacao" label="Data da avaliação" />
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Superior Imediato"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="proxavaliacao" label="Próxima avaliação" />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    descricao: yup.string(),
                                                    inicio: yup.date(),
                                                    termino: yup.date(),
                                                    tipo: yup.string(),
                                                    status: yup.string(),
                                                    responsavel: yup.string(),
                                                    anexo: yup.string(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Plano de Desenvolvimento
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid712SGI-plano-${item._id}`}
                                            perms={'712'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Data', field: 'dataplano', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Ação', field: 'acao', sort: true, className: 'w-8/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Prazo', field: 'prazo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.dataplano), data.acao, data.tipo, dateToString(data.prazo), data.status];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <DateInput name="dataplano" label="Data do Plano" className={'inline-block'} />
                                                        <Input name="acao" label="Ação" />
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="tipo"
                                                                label="Tipo"
                                                                listOptions={[{ value: 'Educação' }, { value: 'Treinamento' }, { value: 'Habilidade' }, { value: 'Experiência' }]}
                                                            />
                                                            <DateInput name="prazo" label="Prazo" />
                                                            <SelectInput name="status" label="Status" listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]} />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DateInput name="realizado" label="Realizado em" />
                                                            <SelectInput
                                                                name="reacao"
                                                                label="Avaliação de Reação"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                            <SelectInput
                                                                name="aprendizagem"
                                                                label="Avaliação de Aprendizagem"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                        </GroupInput>
                                                        <div className="mt-10"></div>
                                                        <GroupInput>
                                                            <DateInput name="prazoeficacaia" label="Prazo para eficácia" />
                                                            <SelectInput name="statuseficacia" label="Status" listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]} />
                                                            <SelectInput
                                                                name="comportamento"
                                                                label="Avaliação de Comportamento"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                            <SelectInput
                                                                name="resultado"
                                                                label="Avaliação de Resultado/Eficácia"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DateInput name="realizadaem" label="Realizada em" className={'inline-block'} />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    dataplano: yup.date().required(),
                                                    acao: yup.string().required(),
                                                    tipo: yup.string().required(),
                                                    prazo: yup.date().required(),
                                                    status: yup.string().required(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // EPI´s
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid712SGI-epi-${item._id}`}
                                            perms={'712'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Nome do EPI', field: 'nome', sort: true, className: 'w-5/12 text-left pl-1' },
                                                { title: 'Número do CA', field: 'numero', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Última Entrega', field: 'entrega', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Próxima substituição', field: 'substituicao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.nome, data.numero, dateToString(data.entrega), dateToString(data.substituicao), data.crew?.nome];
                                            }}
                                            onRenderForm={(GridModes, setFieldValue, values, GridRefresh) => {
                                                const centralizaText = (doc, text, y) => {
                                                    let textWidth = doc.getTextWidth(text);
                                                    let pageWidth = doc.internal.pageSize.width;
                                                    let x = (pageWidth - textWidth) / 2;

                                                    doc.text(text, x, y);
                                                };
                                                const emitirTermoResponsabilidade = async () => {
                                                    const doc = new jsPDF('p', 'pt');
                                                    doc.setFontSize(9);
                                                    const response = await System.apiGet('/company/' + System.property.user.companyId);
                                                    const company = response.data;
                                                    const logo = await System.methods.apiGetImg(company.logotipo);
                                                    const date = new Date().toLocaleDateString();
                                                    const url = window.location.href;

                                                    doc.setFontSize(12);
                                                    doc.setFont('helvetica', 'bold');
                                                    doc.text(`Termo de Responsabilidade`, 40, 60);
                                                    doc.setFontSize(9);
                                                    doc.setFont('helvetica', 'normal');
                                                    doc.addImage(logo, 'JPEG', doc.internal.pageSize.width - 140, 10, 100, 50);
                                                    // header line
                                                    doc.line(40, 70, doc.internal.pageSize.width - 40, 70);

                                                    // coordenada das linhas
                                                    let y = 100;
                                                    let passo = 14;

                                                    // Nome  e função, ate same line
                                                    doc.text(`Nome: ${item.crew.nome}`, 40, y);
                                                    doc.text(`Função: ${item.crew.funcao.nome}`, doc.internal.pageSize.width / 2, y);

                                                    // text
                                                    y += 60;
                                                    doc.text(
                                                        'NR 06 item 6.3.1 - Para os fins de aplicação desta NR considera-se EPI o dispositivo ou produto de uso individual utilizado',
                                                        40,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text(
                                                        'pelo trabalhador, concebido e fabricado para oferecer proteção contra os riscos ocupacionais existentes no ambiente de',
                                                        40,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text('trabalho, conforme previsto no Anexo I.', 40, y);

                                                    // Draw a rectangle for the border
                                                    doc.rect(40, 197, doc.internal.pageSize.width - 90, 85);

                                                    // Add the text
                                                    y += 20;
                                                    doc.text('Item 6.6.1 Cabe ao trabalhador, quanto ao EPI:', 50, y);
                                                    y += passo;
                                                    doc.text('a) usar o fornecido pela organização, observado o disposto no item 6.5.2;', 50, y);
                                                    y += passo;
                                                    doc.text('b) utilizar apenas para a finalidade a que se destina;', 50, y);
                                                    y += passo;
                                                    doc.text('c) responsabilizar-se pela limpeza, guarda e conservação;', 50, y);
                                                    y += passo;
                                                    doc.text(
                                                        'd) comunicar à organização quando extraviado, danificado ou qualquer alteração que o torne impróprio para uso; e',
                                                        50,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text('e) cumprir as determinações da organização sobre o uso adequado.', 50, y);

                                                    // Inicio do termo
                                                    y += 30;
                                                    doc.setFontSize(12);
                                                    doc.setFont('helvetica', 'bold');
                                                    centralizaText(doc, 'TERMO DE RESPONSABILIDADE', y);
                                                    // normal
                                                    doc.setFont('helvetica', 'normal');
                                                    doc.setFontSize(9);

                                                    // text
                                                    y += 30;
                                                    doc.text(`Recebi de ${company.razaoSocial} os Equipamento de Proteção Individual adequados, descritos abaixo, `, 40, y);
                                                    y += passo;
                                                    doc.text(
                                                        'os quais desde já me comprometo utilizar na execução de minhas atividades , zelando pela sua perfeita guarda e conservação, ',
                                                        40,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text('uso e funcionamento de acordo com as orientações e treinamentos recebidos, assumindo também o compromisso de', 40, y);
                                                    y += passo;
                                                    doc.text('devolvê-lo (s) quando solicitado ou em ocasião de rescisão de meu contrato de trabalho.', 40, y);

                                                    //text
                                                    y += 40;
                                                    doc.text(
                                                        'Estou ciente e de pleno acordo que o não cumprimento das condições estabelecidas, acarretará, além de aplicações de',
                                                        40,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text(
                                                        'penas disciplinares, inclusive a possibilidade de rescisão de meu contrato laboral, outras sanções previstas em lei, em',
                                                        40,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text(
                                                        'especial nas constantes na NR - 6, portaria 3.214 de 08 /06 / 78, do Ministério do Trabalho.No caso de perda, dano, extravio',
                                                        40,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text(
                                                        'ou avaria, por negligência minha dos equipamentos de proteção, o respectivo valor será debitado de minha remuneração,',
                                                        40,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text('o que deste já autorizo.', 40, y);

                                                    // text
                                                    y += 40;
                                                    doc.setFont('helvetica', 'bold');
                                                    doc.text('ATO FALTOSO:', 40, y);

                                                    doc.setFont('helvetica', 'normal');
                                                    doc.text('“Constitui-se ato faltoso do colaborador a recusa injustificada do uso e/ou conservação dos EPI’S -', 110, y);
                                                    y += passo;
                                                    doc.text(
                                                        'Equipamento de Proteção Individual - fornecidos pela empresa”. (Conforme previsto na Legislação vigente em seu artigo',
                                                        40,
                                                        y
                                                    );
                                                    y += passo;
                                                    doc.text('482 da CLT).', 40, y);

                                                    // text
                                                    y += 20;
                                                    centralizaText(doc, 'Declaro estar ciente de todas as informações descritas acima.', y);

                                                    // linha e assinatura
                                                    y += 70;
                                                    centralizaText(doc, 'Assinatura do colaborador: ________________________________________', y);

                                                    // relação
                                                    y += 70;
                                                    doc.setFillColor(200, 200, 200);
                                                    doc.rect(40, y - 13, doc.internal.pageSize.width - 90, 17, 'F');
                                                    doc.rect(40, y - 13, doc.internal.pageSize.width - 90, 17);
                                                    doc.setFontSize(12);
                                                    doc.setFont('helvetica', 'bold');
                                                    centralizaText(doc, 'CONTROLE DE EQUIPAMENTOS DE PROTEÇÃO INDIVIDUAL – EPI', y);
                                                    // normal
                                                    doc.setFont('helvetica', 'normal');
                                                    doc.setFontSize(9);

                                                    // items
                                                    y += 25;
                                                    doc.text('Motivo', 40, y);
                                                    doc.text('Quant.', 160, y);
                                                    doc.text('Data Entrega', 210, y);
                                                    doc.text('Nome do EPI', 290, y);
                                                    doc.text('Nº CA', 500, y);
                                                    y += passo;

                                                    doc.text(values.motivo || '', 40, y);
                                                    doc.text((values.quantidade || 1).toString(), 160, y);
                                                    let dt = new Date(values.entrega);
                                                    doc.text(dateToString(dt), 210, y);
                                                    doc.text(values.nome || '', 290, y);
                                                    doc.text(values.numero || '', 500, y);

                                                    centralizaText(doc, ` Página 1/1 - URL: ${url} - Emitido em ${date} por ${System.property.user?.name}`, 820);

                                                    doc.save('termo_responsabilidade.pdf');
                                                };
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <Input name="nome" label="Nome do EPI" className={'w-9/12'} />
                                                            <Input name="numero" label="Número do CA" className={'w-3/12'} />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DateInput name="entrega" label="Última entrega" className={'inline-block'} />
                                                            <DateInput name="substituicao" label="Próxima substituição" className={'inline-block'} />
                                                            <Input name="quantidade" label="Quantidade" type="number" className={'w-1/12'} />
                                                            <Input name="motivo" label="Motivo" />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <div
                                                                className="inline-flex items-center justify-center w-1/6 m-2 space-x-2 rounded-md shadow-md cursor-pointer border-1 h-9 bg-botao-primario text-botao-primario-fonte hover:bg-botao-primario-hover hover:text-botao-primario-fonte"
                                                                onClick={() => {
                                                                    emitirTermoResponsabilidade();
                                                                }}
                                                            >
                                                                <FaPrint className="h-8" />
                                                                <span className="">Emitir termo de responsabilidade</span>
                                                            </div>
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    entrega: yup.date().required(),
                                                    nome: yup.string().required(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // ASO
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid712SGI-aso-${item._id}`}
                                            perms={'712'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Tipo', field: 'tipo', sort: true, className: 'w-2/12 text-left pl-1' },
                                                { title: 'Data', field: 'data', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Revisão', field: 'datarevisao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                {
                                                    title: 'Anexo',
                                                    field: 'fileview',
                                                    filter: false,
                                                    sort: false,
                                                    type: 'fileview',
                                                    className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                                                },
                                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-6/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Conclusão', field: 'conclusão', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                let cc = '';
                                                if (data.conclusao === 'apto') cc = 'Apto';
                                                if (data.conclusao === 'restricao') cc = 'Apto com restrição';
                                                if (data.conclusao === 'inapto') cc = 'Inapto';
                                                if (data.conclusao === 'pendente') cc = 'Pendente';
                                                let ciclo = '';
                                                if (data.tipo === 'periodico') ciclo = 'Periódico';
                                                if (data.tipo === 'mudancarisco') ciclo = 'Mudança de Risco';
                                                if (data.tipo === 'admissional') {
                                                    ciclo = 'Admissional';
                                                    data.datarevisao = '';
                                                }
                                                if (data.tipo === 'demissional') {
                                                    ciclo = 'Demissional';
                                                    data.datarevisao = '';
                                                }
                                                if (data.tipo === 'retornotrabalho') ciclo = 'Retorno ao Trabalho';

                                                return [ciclo, dateToString(data.data), dateToString(data.datarevisao), { fileid: data.attachment }, data.crew?.nome, cc];
                                            }}
                                            validationSchema={yup.object().shape({
                                                data: yup.string(),
                                            })}
                                            onPrepareSaveRecord={(values) => {
                                                if (values.tipo === 'periodico' || values.tipo === 'mudancarisco' || values.tipo === 'retornotrabalho') {
                                                    values.datarevisao = AdicionarDias(values.data, values.diasrevisao);
                                                    values.dataalerta = AdicionarDias(values.data, values.diasrevisao - values.alerta);
                                                }
                                                let newForm = new FormData();
                                                for (let key in values) {
                                                    newForm.append(key, values[key]);
                                                }
                                                return newForm;
                                            }}
                                            onAfterPost={(values, localGridRefresh) => {
                                                newRecord(values, { conclusao: '' }, localGridRefresh);
                                                return true;
                                            }}
                                            onAfterEdit={(values, oldValues, localGridRefresh) => {
                                                newRecord(values, oldValues, localGridRefresh);
                                                return true;
                                            }}
                                            onRenderForm={(GridModes, setFieldValue, values) => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="data" label="Data" className={'w-1/6'} />
                                                            <SelectInput
                                                                name="tipo"
                                                                label="Tipo"
                                                                className={'w-2/6'}
                                                                listOptions={[
                                                                    { value: 'admissional', option: 'Admissional' },
                                                                    { value: 'demissional', option: 'Demissional' },
                                                                    { value: 'periodico', option: 'Periódico' },
                                                                    { value: 'mudancarisco', option: 'Mudança de Risco' },
                                                                    { value: 'retornotrabalho', option: 'Retorno ao Trabalho' },
                                                                ]}
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="diasrevisao"
                                                                label="Revisão"
                                                                className={'w-2/6'}
                                                                disabled={values.tipo === 'admissional' || values.tipo === 'demissional'}
                                                                listOptions={[
                                                                    { value: 30, option: 'Mensal' },
                                                                    { value: 60, option: 'Bimestral' },
                                                                    { value: 90, option: 'Trimestral' },
                                                                    { value: 180, option: 'Semestral' },
                                                                    { value: 365, option: 'Anual' },
                                                                    { value: 730, option: 'Bienal' },
                                                                ]}
                                                            />
                                                            <SelectInput
                                                                name="alerta"
                                                                label="Antecedência do alerta"
                                                                className={'w-2/6'}
                                                                disabled={values.tipo === 'admissional' || values.tipo === 'demissional'}
                                                                listOptions={[
                                                                    { value: 30, option: '30 dias' },
                                                                    { value: 60, option: '60 dias' },
                                                                    { value: 90, option: '90 dias' },
                                                                    { value: 180, option: '180 dias' },
                                                                    { value: 365, option: '1 ano' },
                                                                ]}
                                                            />
                                                        </GroupInput>

                                                        {(values.tipo === 'periodico' || values.tipo === 'mudancarisco' || values.tipo === 'retornotrabalho') && (
                                                            <div className="block p-2 m-2 border border-gray-400 rounded">
                                                                <div className="w-full flex-block">
                                                                    <p>Revisão em {dateToString(AdicionarDias(values.data, values.diasrevisao))}</p>
                                                                    <p>Alerta a partir de {dateToString(AdicionarDias(values.data, values.diasrevisao - values.alerta))}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Responsável"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <AttachmentInput name="attachment" perms="712" label="Anexo" />
                                                        <SelectInput
                                                            name="conclusao"
                                                            label="Conclusão"
                                                            className={'w-2/6'}
                                                            listOptions={[
                                                                { value: 'pendente', option: 'Pendente' },
                                                                { value: 'apto', option: 'Apto' },
                                                                { value: 'restricao', option: 'Apto com restrição' },
                                                                { value: 'inapto', option: 'Inapto' },
                                                            ]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                );
                                function newRecord(values, oldValues, localGridRefresh) {
                                    if (values.conclusao !== oldValues.conclusao) {
                                        if (
                                            values.conclusao !== 'pendente' &&
                                            (values.tipo === 'periodico' || values.tipo === 'mudancarisco' || values.tipo === 'retornotrabalho')
                                        ) {
                                            showConfirm('Deseja gerar o registro do próximo ciclo?', async () => {
                                                setTimeout(async () => {
                                                    let _hoje = new Date();
                                                    let _datarevisao = new Date(AdicionarDias(values.datarevisao, values.diasrevisao));
                                                    let _dataalerta = new Date(AdicionarDias(values.datarevisao, values.diasrevisao - values.alerta));
                                                    let newRecord = {
                                                        data: _hoje,
                                                        tipo: 'periodico',
                                                        diasrevisao: values.diasrevisao,
                                                        alerta: values.alerta,
                                                        datarevisao: _datarevisao,
                                                        dataalerta: _dataalerta,
                                                        responsavel: values.responsavel,
                                                        attachment: '',
                                                        conclusao: 'pendente',
                                                    };
                                                    let response = await System.apiFormPost(`/griddocument/grid712SGI-aso-${item._id}`, newRecord);
                                                    if (response.error) {
                                                        System.methods.notificationError(response.error);
                                                    } else {
                                                        localGridRefresh();
                                                        System.methods.notificationInformation('Registro criado com sucesso.');
                                                    }
                                                }, 1);
                                            });
                                        }
                                    }
                                    return true;
                                }
                            },
                            (GridModes, item) => {
                                // Treinamento
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid712SGI-treinamento-${item._id}`}
                                            perms={'712'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Treinamento', field: 'treinamento', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Realização', field: 'realizacao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Vencimento', field: 'vencimento', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-2/12 pl-1 text-center border-l border-gray-300' },
                                                {
                                                    title: 'Anexo',
                                                    field: 'attachment',
                                                    filter: false,
                                                    sort: false,
                                                    type: 'fileview',
                                                    className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                                                },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.treinamento_str ?? '', dateToString(data.realizacao), dateToString(data.vencimento), data.status, data.attachment];
                                            }}
                                            validationSchema={yup.object().shape({
                                                treinamento: yup.string(),
                                            })}
                                            onRenderForm={(GridModes, setFieldValue, values) => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="realizacao" label="Data de realização" className={'w-1/6'} />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="treinamento_id"
                                                                label="Treinamento"
                                                                url={'/treinamentofuncao/' + item.crew.funcao._id}
                                                                displaySelectField={'treinamento.treinamento'}
                                                                returnField={'_id'}
                                                                onChange={(option, value, fullDBItem) => {
                                                                    setFieldValue('treinamento_str', option);
                                                                    if (fullDBItem.treinamento.periodicidade > 0) {
                                                                        setFieldValue('vencimento', AdicionarDias(values.realizacao, fullDBItem.treinamento.periodicidade));
                                                                        setFieldValue(
                                                                            'alerta',
                                                                            AdicionarDias(values.realizacao, fullDBItem.treinamento.periodicidade - fullDBItem.treinamento.alerta)
                                                                        );
                                                                    } else {
                                                                        setFieldValue('vencimento', '');
                                                                    }
                                                                }}
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <Input name="instituicao" label="Instituição" />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DateInput name="vencimento" label="Vencimento/renovação" className={'w-1/6'} disabled={true} />
                                                            <DateInput
                                                                name="alerta"
                                                                label="Data do alerta"
                                                                disabled={true}
                                                                className={'w-1/6'}
                                                                title="Data gerada automaticamente"
                                                            />
                                                            <SelectInput
                                                                name="status"
                                                                label="Status"
                                                                listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]}
                                                                className={'w-1/6'}
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DateInput name="eficacia" label="Prazo para eficácia" className={'w-1/6'} />
                                                            <SelectInput
                                                                name="statuseficacia"
                                                                label="Status da Eficácia"
                                                                listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]}
                                                            />
                                                            <SelectInput
                                                                name="comportamento"
                                                                label="Avaliação de Comportamento"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                            <SelectInput
                                                                name="resultado"
                                                                label="Avaliação de Resultado/Eficácia"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DateInput name="realizadaem" label="Realizada em" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="gestor"
                                                                label="Gestor responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <AttachmentInput name="attachment" perms="712" label="Anexo" />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // Diario de bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={`/griddocument/grid712SGI-${item._id}`} perms={'712'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // Anexos
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/form712SGI-${item._id}`} disableModal={true} perms="712" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            try {
                                await System.apiDelete(`/griddocument/grid712SGI-${item._id}`);
                                await System.apiDelete(`/griddocument/grid712SGI-avaliacao-${item._id}`);
                                await System.apiDelete(`/griddocument/grid712SGI-plano-${item._id}`);
                                await System.apiDelete(`/griddocument/grid712SGI-epi-${item._id}`);
                                await System.apiDelete(`/griddocument/grid712SGI-aso-${item._id}`);
                            } catch (error) {
                                console.log('Deletando:', error);
                            }
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form713 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/form713"
                        perms={'713'}
                        title={System.getMenuTitle(713)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Infraestrtutura">
                    <Grid
                        url={'/griddocument/grid713'}
                        perms={'713'}
                        clearAutoEdit={true}
                        disableModal={true}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/4 text-left pl-1' },
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Frequência', field: 'frequencia', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao, data.tipo, data.frequencia, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" />
                                    <GroupInput>
                                        <SelectInput name="tipo" label="Tipo" listOptions={[{ value: 'Interno' }, { value: 'Externo' }]} />
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            listOptions={[
                                                { value: 'Diário' },
                                                { value: 'Semanal' },
                                                { value: 'Quinzenal' },
                                                { value: 'Mensal' },
                                                { value: 'Bimestral' },
                                                { value: 'Trimestral' },
                                                { value: 'Semestral' },
                                                { value: 'Anual' },
                                                { value: 'Bienal' },
                                                { value: 'Trienal' },
                                                { value: '4 anos' },
                                                { value: '5 anos' },
                                                { value: '10 anos' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="proxatualizacao" label="Próxima Atualização" className={'inline-block'} />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid713-' + item._id} perms={'713'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={'/attachment/form713-' + item._id} disableModal={true} perms="713" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/attachment/form713-' + item._id);
                            await System.apiDelete('/griddocument/grid713-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            tipo: yup.string(),
                            frequencia: yup.string(),
                            responsavel: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form714 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/form714"
                        perms={'714'}
                        title={System.getMenuTitle(714)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Infraestrtutura">
                    <Grid
                        url={'/griddocument/grid714'}
                        perms={'714'}
                        clearAutoEdit={true}
                        disableModal={true}
                        GridTitle="7.1.4 - Ambiente para a operação dos processos"
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/4 text-left pl-1' },
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Frequência', field: 'frequencia', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao, data.tipo, data.frequencia, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" />
                                    <GroupInput>
                                        <SelectInput name="tipo" label="Tipo" listOptions={[{ value: 'Interno' }, { value: 'Externo' }]} />
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            listOptions={[
                                                { value: 'Diário' },
                                                { value: 'Semanal' },
                                                { value: 'Quinzenal' },
                                                { value: 'Mensal' },
                                                { value: 'Bimestral' },
                                                { value: 'Trimestral' },
                                                { value: 'Semestral' },
                                                { value: 'Anual' },
                                                { value: 'Bienal' },
                                                { value: 'Trienal' },
                                                { value: 'Quadrienal' },
                                                { value: 'Quinquenal' },
                                                { value: 'Decenal' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="proxatualizacao" label="Próxima Atualização" className={'inline-block'} />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid714-' + item._id} perms={'714'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={'/attachment/form714-' + item._id} disableModal={true} perms="714" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/attachment/form714-' + item._id);
                            await System.apiDelete('/griddocument/grid714-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            tipo: yup.string(),
                            frequencia: yup.string(),
                            responsavel: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form715 = () => {
    const System = useSystemContext();
    const refGrid = createRef();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form715"
                        perms={'715'}
                        title={System.getMenuTitle(715)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Metrologia">
                    <Grid
                        url={'/griddocument/grid715'}
                        perms={'715'}
                        clearAutoEdit={true}
                        ref={refGrid}
                        showPDFExport={true}
                        showCSVExport={true}
                        GridHeaders={[
                            { title: 'Código', field: 'codigo', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Local', field: 'local', sort: true, className: 'w-3/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Próx. Calibração', field: 'proximacalibracao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        onAfterLoadData={(data) => {
                            return data.map((item) => {
                                if (!('proximacalibracao_dt_alerta' in item)) {
                                    item.proximacalibracao_dt_alerta = item.proximacalibracao;
                                }
                                return item;
                            });
                        }}
                        LineDataPrint={(data) => {
                            return [data.codigo, data.descricao, data.local, dateToString(data.proximacalibracao)];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values, Refresh) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left w-1/ bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="codigo" label="Código" />
                                    <Input name="descricao" label="Descrição" />
                                    <Input name="local" label="Local da instalação" />
                                    <Input name="posicao" label="Posição da instalação" />
                                    <GroupInput>
                                        <Input name="modelo" label="Modelo/Fabricante" />
                                        <Input name="capacidade" label="Capacidade de medição" />
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            listOptions={[{ value: 'Semestral' }, { value: 'Anual' }, { value: 'Bienal' }, { value: '3 anos' }, { value: '5 anos' }]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="tolerancia" label="Tolerância Procedimento +/-" />
                                        <Input name="aprovacao" label="Tolerância de Aprovação" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="resolucao" label="Resolução" className={'w-1/6'} />
                                        <Input name="uso" label="Faixa de Uso" className={'w-1/6'} />
                                        <Input name="calibracao" label="Procedimento de Calibração" className={'w-4/6'} />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="ultimacalibracao" label="Última calibração" className={'inline-block'} />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="proximacalibracao" label="Próxima calibração" className={'inline-block'} />
                                        <DateInput name="proximacalibracao_dt_alerta" label="Data de Alerta" className={'inline-block'} />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid715-' + item._id} perms={'715'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return <GridAnexos url={`/attachment/form715-${item._id}`} disableModal={true} perms="715" />;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid715-' + item._id);
                            await System.apiDelete('/attachment/form715-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            codigo: yup.string(),
                            descricao: yup.string(),
                            local: yup.string(),
                            posicao: yup.string(),
                            modelo: yup.string(),
                            capacidade: yup.string(),
                            frequencia: yup.string().required(),
                            tolerancia: yup.string(),
                            aprovacao: yup.string(),
                            uso: yup.string(),
                            calibracao: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form715'} perms="715" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form716 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form716"
                        perms={'716'}
                        title={System.getMenuTitle(716)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Conhecimento Organizacional">
                    <Grid
                        url={'/griddocument/grid716'}
                        perms={'716'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Aquisição', field: 'aquisicao', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Armazenamento', field: 'armazenamento', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(item) => {
                            return [item.aquisicao, item.armazenamento];
                        }}
                        GridTitle={'Conhecimento Organizacional'}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <TextInput name="aquisicao" label="Aquisição" className="w-full" rows="8" />
                                    <TextInput name="armazenamento" label="Armazenamento" className="w-full" rows="8" />
                                    <TextInput name="distribuicao" label="Distribuição" className="w-full" rows="8" />
                                    <TextInput name="utilidade" label="Utilidade do Conhecimento" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            aquisicao: yup.string(),
                            armazenamento: yup.string(),
                            distribuicao: yup.string(),
                            utilidade: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid716-' + item._id} perms={'716'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid716-' + item._id);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form72 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form72"
                        perms={'72'}
                        title={System.getMenuTitle(72)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestão">
                    <Grid
                        url={'/griddocument/grid72'}
                        perms={'72'}
                        clearAutoEdit={true}
                        modalHeight={'85%'}
                        modalWidth={'60%'}
                        modalMargin={'2% auto'}
                        GridHeaders={[
                            { title: 'Função', field: 'role', sort: true, className: 'w-11/12 text-left pl-1' },
                            { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.funcao?.nome, `#link=${data.attachment}`];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <DBSelectInput name="role" label="Função" url="/role" displaySelectField="nome" />
                                    <TextInput name="sumario" label="Sumário da Função" />
                                    <GroupInput>
                                        <SelectInput
                                            name="educacao"
                                            label="Educação"
                                            listOptions={[
                                                { value: '0', option: 'Não aplicável' },
                                                { value: '1', option: 'Ensino Fundamental Incompleto' },
                                                { value: '2', option: 'Ensino Fundamental Completo' },
                                                { value: '3', option: 'Ensino Médio Incompleto' },
                                                { value: '4', option: 'Ensino Médio Completo' },
                                                { value: '5', option: 'Ensino Superior Incompleto' },
                                                { value: '6', option: 'Ensino Superior Completo' },
                                                { value: '7', option: 'Pós-Graduação' },
                                                { value: '8', option: 'Mestrado' },
                                                { value: '9', option: 'Doutorado' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <TextInput name="especificas" label="Funções Específicas" />
                                    <GroupInput>
                                        <DBMultiSelectInput
                                            name="treinamento_id"
                                            label="Treinamentos"
                                            url={'/treinamentofuncao/' + values.role}
                                            displaySelectField={'treinamento.treinamento'}
                                        />
                                    </GroupInput>
                                    <TextInput name="habilidades" label="Habilidades" />
                                    <GroupInput>
                                        <SelectInput
                                            name="experiencia"
                                            label="Experiência"
                                            listOptions={[
                                                { value: '0', option: 'Não aplicável' },
                                                { value: '1', option: 'Desejável: > 6 meses na função ou atividades correlatas' },
                                                { value: '2', option: 'Desejável: > 1 ano na função ou atividades correlatas' },
                                                { value: '3', option: 'Desejável: > 2 anos na função ou atividades correlatas' },
                                                { value: '4', option: 'Desejável: > 3 anos na função ou atividades correlatas' },
                                                { value: '5', option: 'Desejável: > 4 anos na função ou atividades correlatas' },
                                                { value: '6', option: 'Desejável: > 5 anos na função ou atividades correlatas' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <AttachmentInput name="attachment" perms="72" label="Anexo" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <DateInput name="data" label="Data" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            role: yup.string(),
                            parteinteressada: yup.string(),
                            incluenciainteresse: yup.string(),
                            necessidades: yup.string(),
                            expectativas: yup.string(),
                            what: yup.string(),
                            why: yup.string(),
                            who: yup.string(),
                            when: yup.string(),
                            where: yup.string(),
                            how: yup.string(),
                            howmuch: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form73 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form73"
                        perms={'73'}
                        title={System.getMenuTitle(73)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form73'} perms="73" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form74 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form74"
                        perms={'74'}
                        title={System.getMenuTitle(74)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Comunicação">
                    <Grid
                        url={'/griddocument/grid74'}
                        perms={'74'}
                        clearAutoEdit={true}
                        GridHeaders={[{ title: 'O que', field: 'oque', sort: true, className: 'w-full text-left pl-1' }]}
                        LineDataPrint={(data) => {
                            return [data.oque];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="oque" label="O que comunica?" />
                                    <Input name="quando" label="Quando comunica" />
                                    <Input name="comquem" label="Com quem se comunica" />
                                    <Input name="como" label="Como comunica" />
                                    <Input name="quem" label="Quem comunica" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            oque: yup.string(),
                            quando: yup.string(),
                            comquem: yup.string(),
                            como: yup.string(),
                            quem: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form74'} perms="74" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form75 = () => {
    const System = useSystemContext();
    const subRef = useRef();
    const revisaoRef = useRef(null);
    const [empresa, setEmpresa] = useState({});

    const [GridHeaders, setGridHeaders] = useState([
        { title: 'Número', field: 'numero', sort: true, className: 'w-min text-left pl-1' },
        { title: 'Título', field: 'titulo', sort: true, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
        { title: 'Processo', field: 'processo', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
        { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
        { title: 'Rev', field: 'revisao', sort: false, className: 'w-min pl-1 text-left border-l border-gray-300' },
        { title: 'Data', field: 'datarevisao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
        { title: 'Situação', field: 'situacao', sort: false, className: 'w-min pl-1 text-left border-l border-gray-300' },
        { title: 'Anexo', field: 'anexo', filter: false, sort: false, type: 'fileview', className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
    ]);

    useEffect(() => {
        const fetch = async () => {
            let empresa = await System.methods.getCompany();
            setEmpresa(empresa);
            if (empresa.formatoprocessos === 'processoesub' && GridHeaders[3].title !== 'Subprocesso') {
                GridHeaders.splice(3, 0, { title: 'Subprocesso', field: 'subprocesso', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' });
                setGridHeaders(GridHeaders);
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const registraHistorico = async (id, descricao, crewID = System.property.user.crew_id) => {
        let url = `/griddocument/grid75-logs-${id}`;
        let body = {
            data: new Date(),
            responsavel: crewID,
            descricao: descricao,
        };
        await System.apiPost(url, body);
    };

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab key={uuidv4()}>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab key={uuidv4()}>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form75"
                        perms={'75'}
                        title={System.getMenuTitle(75)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Informação Documentada" key={uuidv4()}>
                    <Grid
                        url={'/griddocument/grid75'}
                        perms={'75'}
                        clearAutoEdit={true}
                        showPDFExport={true}
                        showCSVExport={true}
                        disableModal={true}
                        forceReloadAfterSave={true}
                        GridTitle={'7.5 - Informação Documentada'}
                        GridHeaders={GridHeaders}
                        key={uuidv4()}
                        onAfterLoadData={(data) => {
                            data = data.map((elemento) => {
                                var rev = new Date('2000-01-01');
                                let nrDoc = '';
                                if (elemento.revisao.length > 0) {
                                    elemento.revisao.map((item) => {
                                        let dt = new Date(item.datarevisao);
                                        if (dt > rev && item.statusaprovacao === 'Concluído') {
                                            rev = dt;
                                            nrDoc = item;
                                            return true;
                                        }
                                        return true;
                                    });
                                    elemento.datarevisao = nrDoc?.datarevisao;
                                    elemento.numerorevisao = nrDoc?.numero;
                                    elemento.attachment = nrDoc?.attachment;
                                    elemento.fileView = {
                                        fileid: nrDoc?.attachment,
                                        onView: () => {
                                            registraHistorico(nrDoc._id, `Documento visualizado`);
                                        },
                                    };
                                }
                                return elemento;
                            });
                            return data;
                        }}
                        LineDataPrint={(data) => {
                            if (empresa.formatoprocessos === 'processoesub') {
                                return [
                                    data.numero,
                                    data.titulo,
                                    data?.processo,
                                    data?.subprocesso,
                                    data.tipo,
                                    data.numerorevisao,
                                    dateToString(data.datarevisao),
                                    data.situacao,
                                    data.fileView,
                                ];
                            } else {
                                return [data.numero, data.titulo, data.processo, data.tipo, data.numerorevisao, dateToString(data.datarevisao), data.situacao, data.fileView];
                            }
                        }}
                        validationSchema={yup.object().shape({
                            processo: yup.string(),
                            numero: yup.string(),
                            data: yup.string(),
                            origem: yup.string(),
                            tipo: yup.string(),
                            titulo: yup.string(),
                            formato: yup.string(),
                            meio: yup.string(),
                            armazenamento: yup.string(),
                            preservacao: yup.string(),
                            recuperacao: yup.string(),
                            retencao: yup.string(),
                            disposicao: yup.string(),
                            situacao: yup.string(),
                            proximaatualizacao: yup.string(),
                        })}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        {empresa.formatoprocessos === 'txt' && <Input name="processo" label="Processo" />}
                                        {empresa.formatoprocessos !== 'txt' && (
                                            <DropboxDBInput
                                                name="processo"
                                                label="Processo"
                                                url={'/process'}
                                                displaySelectField="processo"
                                                onSelectedOption={(option) => {
                                                    subRef.current.changeUrl('/subprocess/' + option?.id || null);
                                                    SetFieldValue('processoid', option?.id || null);
                                                    SetFieldValue('subprocesso', null);
                                                    SetFieldValue('subprocessoid', null);
                                                }}
                                            />
                                        )}
                                        {empresa.formatoprocessos === 'processoesub' && (
                                            <DropboxDBInput
                                                name="subprocesso"
                                                label="Subprocesso"
                                                url={`/subprocess/${values?.processoid || '0'}`}
                                                displaySelectField="subprocesso"
                                                externalRef={subRef}
                                                onSelectedOption={(option) => {
                                                    SetFieldValue('subprocessoid', option?.id || null);
                                                }}
                                            />
                                        )}
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="numero" label="Número" />
                                        <DateInput name="data" label="Data" className={'inline-block'} />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="origem" label="Origem" />
                                        <Input name="tipo" label="Tipo" />
                                    </GroupInput>
                                    <Input name="titulo" label="Título" />
                                    <GroupInput>
                                        <Input name="formato" label="Formato" />
                                        <Input name="meio" label="Meio" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="armazenamento" label="Armazenamento" />
                                        <Input name="preservacao" label="Preservação" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="recuperacao" label="Recuperação" />
                                        <Input name="retencao" label="Retenção" />
                                    </GroupInput>
                                    <Input name="disposicao" label="Disposição" />
                                    <GroupInput>
                                        <SelectInput
                                            name="situacao"
                                            label="Situação"
                                            className={'w-3/6'}
                                            listOptions={[
                                                { value: 'Ativo', option: 'Ativo' },
                                                { value: 'Inativo', option: 'Inativo' },
                                            ]}
                                        />
                                        <DateInput name="proximaatualizacao" label="Próxima Atualização" className={'inline-block'} />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Disponibilidade</div>;
                            },
                            () => {
                                return <div>Revisão</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return <div>Solicitar Revisão</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Disponibilidade
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid75-disponibilidade-${item._id}`}
                                            perms={'75'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Local', field: 'local', sort: true, className: 'w-1/2 text-left pl-1' },
                                                { title: 'Acesso', field: 'acesso', sort: false, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.local, data.acesso];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <Input name="local" label="Local" />
                                                        <SelectInput
                                                            name="acesso"
                                                            label="Acesso"
                                                            className={'w-3/6'}
                                                            listOptions={[{ value: 'Físico' }, { value: 'Eletrônico' }]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    local: yup.string(),
                                                    acesso: yup.string(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Revisão
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid75-revisao-${item._id}`}
                                            perms={'75'}
                                            disableModal={true}
                                            forceReloadAfterSave={true}
                                            externalRef={revisaoRef}
                                            hideIndexColumn={true}
                                            key={uuidv4()}
                                            GridHeaders={[
                                                { title: 'Número', field: 'numero', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Execução', field: 'crew.nome', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Data Revisão', field: 'analise', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status Aprovação', field: 'status', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                                {
                                                    title: 'Anexo',
                                                    field: 'anexo',
                                                    filter: false,
                                                    sort: false,
                                                    type: 'fileview',
                                                    className: 'w-1/12 pl-1 text-center border-l border-gray-300',
                                                },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [
                                                    data.numero,
                                                    data.crew?.nome,
                                                    dateToString(data.datarevisao),
                                                    data.statusaprovacao,
                                                    {
                                                        fileid: data.attachment,
                                                        onView: () => {
                                                            registraHistorico(data._id, `Documento visualizado.`);
                                                        },
                                                    },
                                                ];
                                            }}
                                            onClickEditButton={(item) => {
                                                //console.log('onClickEditButton', item);
                                                //registraHistorico(item._id, 'Visualizou');
                                            }}
                                            onAfterDelete={() => {
                                                System.apiPost('/notify/newdeleterevisao75', {
                                                    item: item,
                                                });
                                            }}
                                            onAfterEdit={(values, oldValues) => {
                                                //console.log('onAfterEdit', values, oldValues);
                                                if (values.statusaprovacao !== oldValues.statusaprovacao) {
                                                    registraHistorico(values._id, `Alterado status da aprovação para ${values.statusaprovacao}`);
                                                    if (values.statusaprovacao === 'Concluído') {
                                                        System.apiPost('/notify/newrevisao75', {
                                                            data: values,
                                                            item: item,
                                                        });
                                                    }
                                                }
                                                if (values.statusanalise !== oldValues.statusanalise) { // so disparar se tiver processo e subprocesso
                                                    registraHistorico(values._id, `Alterado status da análise crítica para ${values.statusanalise}`);
                                                    if (values.statusanalise === 'Concluído') {
                                                        System.apiPost('/notify/newanalizecritica75', {
                                                            data: values,
                                                            item: item,
                                                        });
                                                    }
                                                }
                                                if (values.datarevisao !== oldValues.datarevisao) {
                                                    registraHistorico(values._id, `Alterado Data Revisão`);
                                                }
                                                if (values.prazoanalise !== oldValues.prazoanalise) {
                                                    registraHistorico(values._id, `Alterado Prazo Análise`);
                                                }
                                                if (values.dataanalise !== oldValues.dataanalise) {
                                                    registraHistorico(values._id, `Alterado Data Análise`);
                                                }
                                                if (values.prazoaprovacao !== oldValues.prazoaprovacao) {
                                                    registraHistorico(values._id, `Alterado Prazo Aprovação`);
                                                }
                                                if (values.dataaprovacao !== oldValues.dataaprovacao) {
                                                    registraHistorico(values._id, `Alterado Data Aprovação`);
                                                }
                                            }}
                                            onPrepareSaveRecord={(values) => {
                                                values.gridparent = item._id;
                                                let newForm = new FormData();
                                                for (let key in values) {
                                                    newForm.append(key, values[key]);
                                                }
                                                return newForm;
                                            }}
                                            onBeforeInsertAction={(item) => {
                                                const GridData = revisaoRef.current.methods.GridData();
                                                let ultimoItem = GridData.reduce(
                                                    (acumulado, itemAtual) => {
                                                        const valorAtual = parseInt(itemAtual.numero);
                                                        if (valorAtual > acumulado.valor && Number.isInteger(valorAtual)) {
                                                            return {
                                                                valor: valorAtual,
                                                                responsavel: itemAtual.responsavel,
                                                                analisecritica: itemAtual.analisecritica,
                                                                aprovacao: itemAtual.aprovacao,
                                                            };
                                                        } else {
                                                            return acumulado;
                                                        }
                                                    },
                                                    { valor: 0, responsavel: null, analisecritica: '', aprovacao: '' }
                                                );

                                                item.numero = (ultimoItem.valor + 1).toString();
                                                item.responsavel = ultimoItem.responsavel;
                                                item.analisecritica = ultimoItem.analisecritica;
                                                item.aprovacao = ultimoItem.aprovacao;

                                                return item;
                                            }}
                                            onRenderForm={(GridModes, setFieldValue, values, GridRefresh) => {
                                                return (
                                                    <div className="w-full">
                                                        <div className="w-full pl-2 bg-gray-300">Revisão</div>
                                                        <GroupInput>
                                                            <Input name="numero" label="Número" className={'w-1/4'} />
                                                            <DateInput name="datarevisao" label="Data Revisão" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Execução"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>

                                                        <div className="w-full pl-2 mt-2 bg-gray-300">Análise Crítica</div>
                                                        <DBSelectInput
                                                            name="analisecritica"
                                                            label="Análise Crítica"
                                                            disabled={
                                                                GridModes.insert_mode || System.property.user.level > 1 || System.property.user.crew_id === values.responsavel
                                                                    ? false
                                                                    : true
                                                            }
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <GroupInput>
                                                            <DateInput name="prazoanalise" label="Prazo Análise" className={'inline-block'} />
                                                            <SelectInput
                                                                name="statusanalise"
                                                                label="Status Análise"
                                                                className={'w-3/6'}
                                                                disabled={System.property.user.crew_id === values.analisecritica || System.property.user.level > 1 ? false : true}
                                                                defaultValue={'Pendente'}
                                                                listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]}
                                                            />
                                                            <DateInput
                                                                name="dataanalise"
                                                                label="Data Análise"
                                                                className={'inline-block'}
                                                                disabled={System.property.user.crew_id === values.analisecritica || System.property.user.level > 1 ? false : true}
                                                            />
                                                        </GroupInput>

                                                        <div className="w-full pl-2 mt-2 bg-gray-300">Aprovação</div>
                                                        <DBSelectInput
                                                            name="aprovacao"
                                                            label="Aprovação"
                                                            disabled={
                                                                GridModes.insert_mode ||
                                                                    values.statusaprovacao === 'Pendente' ||
                                                                    System.property.user.level > 1 ||
                                                                    System.property.user.crew_id === values.responsavel
                                                                    ? false
                                                                    : true
                                                            }
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <GroupInput>
                                                            <DateInput name="prazoaprovacao" label="Prazo Aprovação" className={'inline-block'} />
                                                            <SelectInput
                                                                name="statusaprovacao"
                                                                label="Status Aprovação"
                                                                disabled={
                                                                    values.statusanalise === 'Concluído' &&
                                                                        (System.property.user.level > 1 || System.property.user.crew_id === values.aprovacao)
                                                                        ? false
                                                                        : true
                                                                }
                                                                className={'w-3/6'}
                                                                defaultValue={'Pendente'}
                                                                listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]}
                                                            />
                                                            <DateInput
                                                                name="dataaprovacao"
                                                                disabled={System.property.user.crew_id === values.aprovacao || System.property.user.level > 1 ? false : true}
                                                                label="Data Aprovação"
                                                                className={'inline-block'}
                                                            />
                                                        </GroupInput>

                                                        <div className="w-full pl-2 mt-2 bg-gray-300">Adicional</div>

                                                        <AttachmentInput
                                                            name="attachment"
                                                            perms="75"
                                                            canDownload={values.responsavel === System.property.user.crew_id || System.property.user.level > 1}
                                                            disabled={System.property.user.crew_id !== values.responsavel && System.property.user.level <= 1}
                                                            label="Anexo"
                                                        />
                                                    </div>
                                                );
                                            }}
                                            FormTabs={[
                                                () => {
                                                    return <div>Revisão</div>;
                                                },
                                                () => {
                                                    return System.property.user.level > 1 ? <div>Histórico</div> : null;
                                                },
                                            ]}
                                            onRenderFormExtra={[
                                                (GridModes, item, GridRefresh) => {
                                                    if (System.property.user.level <= 1) return null;
                                                    return (
                                                        <div className={'m-2'}>
                                                            <Grid
                                                                url={'/griddocument/grid75-logs-' + item._id}
                                                                canEdit={false}
                                                                canDelete={false}
                                                                canInsert={false}
                                                                canShow={true}
                                                                disableModal={true}
                                                                hideIndexColumn={true}
                                                                defaultSortField="data"
                                                                defaultSortOrder="desc"
                                                                hideActionsButtons={true}
                                                                key={uuidv4()}
                                                                GridHeaders={[
                                                                    { title: 'Data', field: 'data', sort: true, className: 'w-2/12 text-left pl-1' },
                                                                    {
                                                                        title: 'Colaborador',
                                                                        field: 'responsavel',
                                                                        sort: true,
                                                                        className: 'w-5/12 pl-1 text-left border-l border-gray-300',
                                                                    },
                                                                    {
                                                                        title: 'Descrição',
                                                                        field: 'descricao',
                                                                        sort: false,
                                                                        className: 'w-5/12 pl-1 text-left border-l border-gray-300',
                                                                    },
                                                                ]}
                                                                LineDataPrint={(data) => {
                                                                    return [dateToStringFull(data.data), data.crew?.nome ?? '(Administrador)', data.descricao];
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                },
                                            ]}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    numero: yup.string(),
                                                    responsavel: yup.string(),
                                                    analisecritica: yup.string(),
                                                    datarevisao: yup.date(),
                                                    statusrevisao: yup.string(),
                                                    aprovacao: yup.string(),
                                                    dataaprovacao: yup.date(),
                                                    statusaprovacao: yup.string(),
                                                    anexo: yup.string(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de bordo
                                return (
                                    <div className={'m-2'}>
                                        <GridDiario url={'/griddocument/grid75-diariodebordo-' + item._id} perms={'75'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Solicitar revisão
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={'/griddocument/grid75-revisao-' + item._id}
                                            _perms={'75'}
                                            clearAutoEdit={true}
                                            showPDFExport={true}
                                            showCSVExport={true}
                                            disableModal={true}
                                            forceReloadAfterSave={true}
                                            GridHeaders={[
                                                { title: 'Data', field: 'data', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Solicitante', field: 'solicitante', sort: true, className: 'w-5/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Descrição', field: 'descricao', sort: false, className: 'w-5/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            onBeforeInsertAction={(item) => {
                                                item.data = new Date();
                                                return item;
                                            }}
                                            onAfterPost={(values) => {
                                                System.apiPost('/notify/master75', {
                                                    data: values.data,
                                                    descricao: values.descricao,
                                                    solicitante: values.responsavel,
                                                    gridparent: item.id,
                                                    item: item._id,
                                                });
                                            }}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.data), data.crew?.nome, data.descricao];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                                        </div>
                                                        <DateInput name="data" label="Data" className={'w-1/6'} />
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Solicitante"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <TextInput name="descricao" label="Descreva o que precisa ser revisado:" className="w-full" rows="4" />
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid75-disponibilidade-' + item._id);
                            await System.apiDelete('/griddocument/grid75-revisao-' + item._id);
                            await System.apiDelete('/griddocument/grid75-diariodebordo-' + item._id);
                            await System.apiDelete('/griddocument/grid75-logs-' + item._id);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};

export const Form7Pessoas = (formitm = 753) => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document={`/document/form${formitm}`}
                        perms={formitm}
                        title={System.getMenuTitle(formitm)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestão de Pessoas">
                    <Grid
                        url={`/griddocument/grid${formitm}`}
                        perms={formitm}
                        clearAutoEdit={true}
                        defaultSortField="createdAt"
                        GridHeaders={[
                            { title: 'Categoria', field: 'categoria', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Empresa', field: 'empresa', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.categoria, data.empresa];
                        }}
                        validationSchema={yup.object().shape({
                            categoria: yup.string(),
                            empresa: yup.string(),
                        })}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="categoria" label="Categoria" />
                                    <Input name="empresa" label="Empresa" />
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Documentos</div>;
                            },
                            () => {
                                return <div>Condicionantes</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Documentos
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid${formitm}-documentos-${item._id}`}
                                            perms={formitm}
                                            GridTitle={'Documentos'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Identificação', field: 'identificacao', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Títulos', field: 'titulo', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Vencimento', field: 'vencimento', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.identificacao, data.titulo, dateToString(data.vencimento), data.crew?.nome, data.status];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <Input name="identificacao" label="Número/Identificação" />
                                                        <Input name="titulo" label="Título" />
                                                        <Input name="emissor" label="Orgão emissor" />
                                                        <GroupInput>
                                                            <DateInput name="emissao" label="Data de emissão" />
                                                            <DateInput name="vencimento" label="Data de Vencimento" />
                                                            <DateInput name="alerta" label="Data de alerta" />
                                                        </GroupInput>
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Responsável pela atualização"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="status"
                                                                label="Status"
                                                                className={'inline-block'}
                                                                listOptions={[{ value: 'Válido' }, { value: 'A renovar' }, { value: 'Protocolado' }, { value: 'Vencido' }]}
                                                            />
                                                            <SelectInput
                                                                name="situacao"
                                                                label="Situação"
                                                                className={'inline-block'}
                                                                listOptions={[{ value: 'Ativo' }, { value: 'Inativo' }]}
                                                            />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                identificacao: yup.string(),
                                                titulo: yup.string(),
                                                emissor: yup.string(),
                                                emissao: yup.date(),
                                                vencimento: yup.date(),
                                                alerta: yup.date(),
                                                responsavel: yup.string(),
                                                status: yup.string(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Condicionantes
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid${formitm}-condicionantes-${item._id}`}
                                            perms={formitm}
                                            GridTitle={'Documentos'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Identificação', field: 'identificacao', sort: true, className: 'w-8/12 text-left pl-1' },
                                                { title: 'Vencimento', field: 'vencimento', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.identificacao, dateToString(data.vencimento), data.crew?.nome, data.status];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <Input name="identificacao" label="Número/Identificação" />
                                                        <TextInput name="condicionante" label="Descrição da condicionante" rows={5} />
                                                        <GroupInput>
                                                            <DateInput name="emissao" label="Data de emissão" />
                                                            <DateInput name="vencimento" label="Data de Vencimento" />
                                                            <DateInput name="alerta" label="Data de alerta" />
                                                        </GroupInput>
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Responsável pela atualização/atendimento"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="status"
                                                                label="Status"
                                                                className={'inline-block'}
                                                                listOptions={[{ value: 'Conforme' }, { value: 'A vencer' }, { value: 'Não conforme' }]}
                                                            />
                                                            <SelectInput
                                                                name="situacao"
                                                                label="Situação"
                                                                className={'inline-block'}
                                                                listOptions={[{ value: 'Ativo' }, { value: 'Inativo' }]}
                                                            />
                                                        </GroupInput>
                                                        <TextInput name="evidencia" label="Evidência do atendimento" rows={5} />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                identificacao: yup.string(),
                                                condicionante: yup.string(),
                                                emissao: yup.date(),
                                                vencimento: yup.date(),
                                                alerta: yup.date(),
                                                responsavel: yup.string(),
                                                status: yup.string(),
                                                evidencia: yup.string(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/form${formitm}-${item._id}`} perms={formitm} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexos
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/form${formitm}-${item._id}`} disableModal={true} perms={formitm} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/attachment/form${formitm}-${item._id}`);
                            await System.apiDelete(`/method5w2h/form${formitm}-${item._id}`);
                            await System.apiDelete(`/griddocument/grid${formitm}-documentos-${item._id}`);
                            await System.apiDelete(`/griddocument/grid${formitm}-condicionantes-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};

export const Form753 = () => {
    return Form7Pessoas(753);
};

export const Form78 = () => {
    return Form7Pessoas(78);
};
