import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { validateCNPJ } from 'validations-br';
import { useSystemContext } from '../../Context/SystemContext';
import { GridAnexos } from '../../components/GridAnexos';
import { CepInput, FormInput, GroupInput, Input, MaskInput, TextInput } from '../../components/Input';

const yup = require('../../interface/yup-br');

export const Form11 = () => {
    const System = useSystemContext();
    return (
        <FormInput document="/document/form11" perms={'11'}
            title={System.getMenuTitle(11)}
            validationSchema={yup.object().shape({
                nomeFantasia: yup.string().required(),
                razaoSocial: yup.string(),
                cnpj: yup.string().test('is-cnpj', 'inválido', (value) => validateCNPJ(value)),
                credencial: yup.string(),
                endereco: yup.string(),
                cep: yup.string(),
                cidade: yup.string(),
                estado: yup.string().uppercase(),
                representante: yup.string(),
                email: yup.string(),
                fone: yup.string(),
                celular: yup.string(),
                whatsapp: yup.string(),
                skype: yup.string(),
            })}
            onRenderFields={({ setFieldValue }) => {
                return (
                    <div>
                        <GroupInput>
                            <Input name="nomeFantasia" label="Nome Fantasia" />
                            <Input name="razaoSocial" label="Razão Social" />
                        </GroupInput>
                        <GroupInput>
                            <MaskInput name="cnpj" label="CNPJ" mask="99.999.999/9999-99" className={'w-64'} />
                            <Input name="credencial" label="Credencial" className={'w-40'} />
                        </GroupInput>
                        <CepInput
                            name="cep"
                            label="CEP"
                            className="w-32"
                            onReceivedCep={(received) => {
                                setFieldValue('cidade', received.localidade);
                                setFieldValue('estado', received.uf);
                            }}
                        />
                        <Input name="endereco" label="Endereço Completo" />
                        <GroupInput>
                            <Input name="cidade" label="Cidade" />
                            <MaskInput
                                name="estado"
                                label="Estado"
                                className="w-42"
                                mask="aa"
                                beforeMaskedValueChange={(newState) => {
                                    newState.value = newState.value.toUpperCase();
                                    return newState;
                                }}
                            />
                        </GroupInput>
                        <GroupInput>
                            <Input name="representante" label="Representante legal" />
                            <Input name="email" label="Email" />
                        </GroupInput>
                        <GroupInput>
                            <MaskInput name="fone" label="Fone" mask="(99) 9999-9999" />
                            <Input name="celular" label="Celular" />
                        </GroupInput>
                        <GroupInput>
                            <Input name="whatsapp" label="Whatsapp" />
                            <Input name="skype" label="Skype" />
                        </GroupInput>
                    </div>
                );
            }}
        />
    );
};

export const Form12 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput document="/document/form12" perms={'12'}

                        title={System.getMenuTitle(12)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="compromisso" label="Termos" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/form12'} perms={'12'} />
                </TabPanel>
            </Tabs>
        </div >
    );
};
