import React, { createRef, useEffect, useState } from 'react';
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Bar, BarChart, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { validateCNPJ } from 'validations-br';
import GUT from '../../assets/GUT.jpeg';
import INFLUENCIA_INTERESSE from '../../assets/INFLUENCIA_INTERESSE.jpeg';
import kirkpatrick from '../../assets/kirkpatrick.png';
import RACI from '../../assets/RACI.jpeg';
import SIPOC from '../../assets/SIPOC.jpeg';
import SWOT from '../../assets/SWOT.jpeg';
import tabelaImg from '../../assets/tabela.jpeg';
import ChartRdc210 from '../../components/ChartRdc210';
import Grid from '../../components/Grid';
import { Grid5W2H } from '../../components/Grid5W2H';
import { GridAnexos } from '../../components/GridAnexos';

import {
    AttachmentInput,
    CepInput,
    CheckBoxInput,
    DateInput,
    DBSelectInput,
    FormInput,
    GroupInput,
    Input,
    MaskInput,
    SelectInput,
    TabsInput,
    TextInput,
} from '../../components/Input';
import { useSystemContext } from '../../Context/SystemContext';
import { dateToString } from '../../helper/dates';
import { GridDiario } from '../../components/GridDiario';

const yup = require('../../interface/yup-br');

export const RDC11 = () => {
    return (
        <FormInput
            document="/document/rdc1"
            perms={'2001001'}
            title="Dados cadastrais da organização"
            validationSchema={yup.object().shape({
                nomeFantasia: yup.string().required(),
                razaoSocial: yup.string(),
                cnpj: yup.string().test('is-cnpj', 'inválido', (value) => validateCNPJ(value)),
                credencial: yup.string(),
                endereco: yup.string(),
                cep: yup.string(),
                cidade: yup.string(),
                estado: yup.string().uppercase(),
                representante: yup.string(),
                email: yup.string(),
                fone: yup.string(),
                celular: yup.string(),
                whatsapp: yup.string(),
                skype: yup.string(),
            })}
            onRenderFields={({ setFieldValue }) => {
                return (
                    <div>
                        <GroupInput>
                            <Input name="nomeFantasia" label="Nome Fantasia" />
                            <Input name="razaoSocial" label="Razão Social" />
                        </GroupInput>
                        <GroupInput>
                            <MaskInput name="cnpj" label="CNPJ" mask="99.999.999/9999-99" className={'w-64'} />
                            <Input name="credencial" label="Credencial" className={'w-40'} />
                        </GroupInput>
                        <CepInput
                            name="cep"
                            label="CEP"
                            className="w-32"
                            onReceivedCep={(received) => {
                                setFieldValue('cidade', received.localidade);
                                setFieldValue('estado', received.uf);
                            }}
                        />
                        <Input name="endereco" label="Endereço Completo" />
                        <GroupInput>
                            <Input name="cidade" label="Cidade" />
                            <MaskInput
                                name="estado"
                                label="Estado"
                                className="w-42"
                                mask="aa"
                                beforeMaskedValueChange={(newState) => {
                                    newState.value = newState.value.toUpperCase();
                                    return newState;
                                }}
                            />
                        </GroupInput>
                        <GroupInput>
                            <Input name="representante" label="Representante legal" />
                            <Input name="email" label="Email" />
                        </GroupInput>
                        <GroupInput>
                            <MaskInput name="fone" label="Fone" mask="(99) 9999-9999" />
                            <Input name="celular" label="Celular" />
                        </GroupInput>
                        <GroupInput>
                            <Input name="whatsapp" label="Whatsapp" />
                            <Input name="skype" label="Skype" />
                        </GroupInput>
                    </div>
                );
            }}
        />
    );
};
export const RDC12 = () => {
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc2"
                        perms={'2001002'}
                        title="Compromisso com a Conduta e Ética"
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="compromisso" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc2'} perms={'2001002'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC13 = () => {
    return (
        <FormInput
            document="/document/rdc13"
            perms={'2001003'}
            title={'Objetivo do manual de garantia da qualidade'}
            initialValues={{
                objetivo: '',
            }}
            validationSchema={yup.object().shape({
                objetivo: yup.string(),
            })}
            onRenderFields={() => {
                return (
                    <div className="w-full">
                        <TextInput name="objetivo" label="Conteúdo" className="w-full" />
                    </div>
                );
            }}
        />
    );
};
export const RDC14 = () => {
    return (
        <FormInput
            document="/document/rdc14"
            perms={'2001004'}
            title="Referência normativa e regulamentar"
            initialValues={{
                normas: '',
            }}
            validationSchema={yup.object().shape({
                normas: yup.string(),
            })}
            onRenderFields={() => {
                return (
                    <div className="w-full">
                        <TextInput name="normas" label="Normas" className="w-full" />
                    </div>
                );
            }}
        />
    );
};
export const RDC15 = () => {
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Gestão</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Anexos</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc15"
                        perms={'2001005'}
                        title={'Escopo do sistema de gestão'}
                        initialValues={{
                            necessidades: '',
                        }}
                        validationSchema={yup.object().shape({
                            normas: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="necessidades" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <FormInput
                        document="/document/rdc15"
                        perms={'2001005'}
                        validationSchema={yup.object().shape({
                            escopo: yup.string(),
                            requisito: yup.string(),
                            justificativa: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div>
                                    <TextInput name="escopo" label="Escopo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <Grid
                        url={'/griddocument/rdc15'}
                        perms={'2001005'}
                        clearAutoEdit={true}
                        GridTitle="Exclusões"
                        GridHeaders={[
                            { title: 'Requisito', field: 'requisito', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Justificativa', field: 'justificativa', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.requisito, data.justificativa];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <Input name="requisito" label="Requisito" />
                                    <Input name="justificativa" label="Justificativa" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            requisito: yup.string(),
                            justificativa: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc15'} perms={'2001005'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC16 = () => {
    const System = useSystemContext();
    function converteParaInteiro(str) {
        const numero = parseInt(str);
        return isNaN(numero) ? 0 : numero;
    }
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Gestão</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Anexos</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc16"
                        perms={'2001006'}
                        title="Entendendo a organização e o seu contexto"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <img src={SWOT} alt="swot" className="w-full h-auto" />
                    <img src={GUT} alt="GUT" className="w-full h-auto" />
                </TabPanel>
                <TabPanel>
                    <Grid
                        url={'/griddocument/rdc16'}
                        perms={'2001006'}
                        clearAutoEdit={true}
                        defaultSortField={'!calculado'}
                        defaultSortOrder={'desc'}
                        modalHeight={'55%'}
                        modalWidth={'60%'}
                        modalMargin={'5% auto'}
                        onAfterLoadData={(data) => {
                            return data.map((item) => {
                                item.calculado = converteParaInteiro(item.g) * converteParaInteiro(item.u) * converteParaInteiro(item.t);
                                return item;
                            });
                        }}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'SWOT', field: 'swot', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'G', field: 'g', sort: false, className: 'w-32 pl-1 text-center border-l border-gray-300' },
                            { title: 'U', field: 'u', sort: false, className: 'w-32 pl-1 text-center border-l border-gray-300' },
                            { title: 'T', field: 't', sort: false, className: 'w-32 pl-1 text-center border-l border-gray-300' },
                            { title: 'R', field: '!calculado', sort: true, className: 'w-32 pl-1 text-center border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [
                                data.descricao.substr(0, 50), //
                                data.swot, //
                                data.g, //
                                data.u, //
                                data.t, //
                                `${converteParaInteiro(data.g) * converteParaInteiro(data.u) * converteParaInteiro(data.t)}`, //
                            ];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <TextInput name="descricao" label="Descrição" className="w-full" rows="8" />
                                    <GroupInput>
                                        <SelectInput
                                            name="swot"
                                            label="SWOT"
                                            className={'w-1/4'}
                                            listOptions={[
                                                { value: 'Forças | Interno e Positivo' },
                                                { value: 'Oportunidades | Externo e Positivo' },
                                                { value: 'Ameaças | Externo E Negativo' },
                                                { value: 'Fraquezas | interno e Negativo' },
                                            ]}
                                        />
                                        <SelectInput
                                            name="g"
                                            label="G"
                                            className="w-1/4"
                                            listOptions={[
                                                { value: '0', option: '0' },
                                                { value: '1', option: '1 - Sem gravidade' },
                                                { value: '2', option: '2 - Pouco grave' },
                                                { value: '3', option: '3 - Grave' },
                                                { value: '4', option: '4 - Muito grave' },
                                                { value: '5', option: '5 - Extremamente grave' },
                                            ]}
                                        />
                                        <SelectInput
                                            name="u"
                                            label="U"
                                            className="w-1/4"
                                            listOptions={[
                                                { value: '0' },
                                                { value: '1', option: '1 - Pode esperar' },
                                                { value: '2', option: '2 - Pouco urgente' },
                                                { value: '3', option: '3 - O mais rápido possivel' },
                                                { value: '4', option: '4 - É urgente' },
                                                { value: '5', option: '5 - Precisa ser resolvido já' },
                                            ]}
                                        />
                                        <SelectInput
                                            name="t"
                                            label="T"
                                            className="w-1/4"
                                            listOptions={[
                                                { value: '0', option: '0' },
                                                { value: '1', option: '1 - Não mudar nada' },
                                                { value: '2', option: '2 - Piorar em longo prazo' },
                                                { value: '3', option: '3 - Piorar em médio prazo' },
                                                { value: '4', option: '4 - Piorar em curto prazo' },
                                                { value: '5', option: '5 - Piorar rapidamente' },
                                            ]}
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/rdc16-${item._id}`} perms={'2001006'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/rdc16-${item._id}`);
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            swot: yup.string(),
                            g: yup.string().min(0).max(5),
                            u: yup.string().min(0).max(5),
                            t: yup.string().min(0).max(5),
                            r: yup.string().min(0).max(5),
                            ordem: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc16'} perms={'2001006'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC17 = () => {
    const System = useSystemContext();
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Gestão</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Anexos</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc17"
                        perms={'2001007'}
                        title="Entendendo as necessidades e expectativas de partes interessadas"
                        initialValues={{
                            necessidades: '',
                        }}
                        validationSchema={yup.object().shape({
                            normas: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="necessidades" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <img src={INFLUENCIA_INTERESSE} alt="INFLUENCIA_INTERESSE" className="w-full h-auto" />
                </TabPanel>
                <TabPanel>
                    <Grid
                        url={'/griddocument/rdc17'}
                        perms={'2001007'}
                        clearAutoEdit={true}
                        modalHeight={'85%'}
                        modalWidth={'60%'}
                        modalMargin={'2% auto'}
                        modalHeightAdditional={'95%'}
                        modalWidthAdditional={'70%'}
                        modalMarginAdditional={'1% auto'}
                        GridHeaders={[
                            { title: 'Parte interessada', field: 'parteinteressada', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Influência X Interesse', field: 'influenciainteresse', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.parteinteressada.substr(0, 50), data.influenciainteresse];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="parteinteressada" label="Parte Interessada" />
                                    <SelectInput
                                        name="influenciainteresse"
                                        label="Influência x Interesse"
                                        className={'w-3/6'}
                                        listOptions={[
                                            { value: 'Manter Satisfeito', option: 'Manter Satisfeito' },
                                            { value: 'Monitorar', option: 'Monitorar' },
                                            { value: 'Manter Informado', option: 'Manter Informado' },
                                            { value: 'Gerenciar de perto', option: 'Gerenciar de perto' },
                                        ]}
                                    />
                                    <TextInput name="necessidades" label="Necessidades" mask="4" />
                                    <TextInput name="expectativas" label="Expectativas" mask="4" />
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/rdc17-${item._id}`} perms={'2001007'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/rdc17-' + item._id} perms={'2001007'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/rdc17-' + item._id);
                            await System.apiDelete(`/method5w2h/rdc17-${item._id}`);
                        }}
                        validationSchema={yup.object().shape({
                            parteinteressada: yup.string(),
                            incluenciainteresse: yup.string(),
                            necessidades: yup.string(),
                            expectativas: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc17'} perms={'2001007'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC21 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Anexos</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc21"
                        perms={'2002001'}
                        title={'Processos'}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            normas: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <img src={SIPOC} alt="SIPOC" className="w-full h-auto" />
                </TabPanel>
                <TabPanel name="Mapeamento de Processos">
                    <div className="w-full h-80">
                        <Grid
                            url={'/griddocument/rdc21'}
                            perms={'2002001'}
                            clearAutoEdit={true}
                            GridHeaders={[
                                { title: 'Descrição', field: 'crescricao', sort: true, className: 'w-1/12 text-left pl-1' },
                                { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Processo', field: 'processo', sort: true, className: 'w-6/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Data', field: 'data', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            ]}
                            LineDataPrint={(data) => {
                                return [data.descricao, data.tipo, data.processo?.substring(0, 90), dateToString(data.data), data.crew?.nome, `#link=${data.attachment}`];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                        </div>
                                        <GroupInput>
                                            <Input name="descricao" label="Descrição" />
                                            <SelectInput
                                                name="tipo"
                                                label="Tipo"
                                                className={'w-1/3'}
                                                listOptions={[{ value: 'Realização' }, { value: 'Gestão' }, { value: 'Apoio' }, { value: 'Terceiro' }]}
                                            />
                                        </GroupInput>
                                        <GroupInput>
                                            <Input name="subprocesso" label="Sub-Processo" />
                                        </GroupInput>
                                        <TextInput name="dadosentrada" label="Dados de Entrada" />
                                        <TextInput name="processo" label="Processo" />
                                        <TextInput name="dadossaida" label="Dados de Saida" />
                                        <TextInput name="riscosoportunidades" label="Riscos e Oportunidades" rows={3} />
                                        <TextInput name="metrologia" label="Metrologia" rows={3} />
                                        <TextInput name="recursos" label="Recursos Humanos" rows={3} />
                                        <TextInput name="ambiente" label="Ambiente de Trabalho" rows={3} />
                                        <TextInput name="infraestrutura" label="Infraestrutura" rows={3} />
                                        <TextInput name="indicadores" label="Indicadores" rows={3} />
                                        <TextInput name="pontoscriticos" label="Pontos Críticos" rows={3} />
                                        <TextInput name="informacao" label="Informação Documentada" rows={3} />
                                        <TextInput name="residuos" label="Gestão de Resíduos" rows={3} />

                                        <GroupInput>
                                            <DateInput name="data" label="Data" className={'w-1/3'} />
                                            <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            FormTabs={[
                                () => {
                                    return <div>Home</div>;
                                },
                                () => {
                                    return (
                                        <div className="inline-flex justify-center">
                                            <span>Anexos</span>
                                        </div>
                                    );
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <FormInput
                                            document={`/griddocument/rdc21/${item._id}`}
                                            perms={'2002001'}
                                            validationSchema={yup.object().shape({
                                                arquivo: yup.string(),
                                            })}
                                            onAfterSave={() => {
                                                GridRefresh();
                                            }}
                                            onRenderFields={(setFieldValue, values, callCommit) => {
                                                return <AttachmentInput name="attachment" label="Anexo" callParentCommit={callCommit} />;
                                            }}
                                        />
                                    );
                                },
                            ]}
                            onBeforeDelete={async (item) => {
                                await System.apiDelete('/griddocument/rdc21-' + item._id);
                            }}
                            validationSchema={yup.object().shape({
                                descricao: yup.string(),
                                tipo: yup.string(),
                                subprocesso: yup.string(),
                                dadosentrada: yup.string(),
                                processo: yup.string(),
                                dadossaida: yup.string(),
                                metrologia: yup.string(),
                                recursos: yup.string(),
                                ambiente: yup.string(),
                                infraestrutura: yup.string(),
                                indicadores: yup.string(),
                                pontoscriticos: yup.string(),
                                informacao: yup.string(),
                                residuos: yup.string(),
                                status: yup.string(),
                                data: yup.string(),
                                responsavel: yup.string(),
                            })}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc21'} perms={'2002001'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC22 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc22"
                        perms={'2002002'}
                        title="Requisitos legais"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Ambiental">
                    <Grid
                        url={'/griddocument/rdc22'}
                        perms={'2002002'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-2/12 text-left pl-1' },
                            { title: 'Legislação', field: 'legislacao', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Cadastro', field: 'cadastro', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Situação', field: 'situacao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.tipo, data.legislacao, dateToString(data.cadastro), data.crew?.nome, data.situacao];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div className={'m-2'}>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <SelectInput
                                        name="tipo"
                                        label="Tipo"
                                        className={'w-1/3'}
                                        listOptions={[
                                            { value: 'Meio Ambiente' },
                                            { value: 'Saúde e Segurança' },
                                            { value: 'Qualidade' },
                                            { value: 'Responsabilidade Social' },
                                            { value: 'Outros' },
                                        ]}
                                    />

                                    <TextInput name="legislacao" label="Legislação" rows={5} />
                                    <GroupInput>
                                        <SelectInput name="ambito" label="Âmbito" listOptions={[{ value: 'Federal' }, { value: 'Estadual' }, { value: 'Municipal' }]} />
                                        <Input name="orgao" label="Orgão" />
                                    </GroupInput>
                                    <TextInput name="ementa" label="Ementa" rows={5} />
                                    <Input name="tema" label="Tema" />
                                    <GroupInput>
                                        <DateInput name="cadastro" label="Cadastro/Ult.Atualização" />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                        <DateInput name="atualizacao" label="Próxima Atualização" />
                                    </GroupInput>
                                    <GroupInput>
                                        <SelectInput name="situacao" label="Situação" listOptions={[{ value: 'Ativo' }, { value: 'Inativo' }]} />
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência de Avaliação"
                                            listOptions={[{ value: 'Semestral' }, { value: 'Anual' }, { value: 'Bienal' }]}
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Avaliações</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Avaliações
                                var url = `/griddocument/rdc22-avaliacoes-${item._id}`;
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={url}
                                            perms={'2002002'}
                                            clearAutoEdit={true}
                                            GridTitle={'Avaliações'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Data Avaliação', field: 'data', sort: true, className: 'w-1/6 text-left pl-1' },
                                                { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-3/6 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Próx. Avaliação', field: 'proxavaliacao', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.data), data.crew?.nome, data.status, dateToString(data.proxavaliacao)];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <DateInput name="data" label="Data da Avaliação" className={'inline-block'} />
                                                        <TextInput name="evidencia" label="Evidência de atendimento" rows={5} />
                                                        <DateInput name="proxavaliacao" label="Próxima Avaliação" className={'inline-block'} />
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Avaliador"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <SelectInput
                                                            name="status"
                                                            label="Status"
                                                            className={'inline-block'}
                                                            listOptions={[{ value: 'Conforme' }, { value: 'Não conforme' }]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                data: yup.string(),
                                                evidencia: yup.string(),
                                                proxavaliacao: yup.string(),
                                                responsavel: yup.string(),
                                                status: yup.string(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div className={'m-2'}>
                                        <Grid5W2H url={`/method5w2h/rdc22-${item._id}`} perms={'2002002'} />
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // Diario de bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/rdc22-' + item._id} perms={'2002002'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // Anexos
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={'/attachment/rdc22-' + item._id} disableModal={true} perms={'2002002'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/attachment/rdc22-' + item._id);
                            await System.apiDelete('/griddocument/rdc22-' + item._id);
                            await System.apiDelete(`/method5w2h/rdc22-${item._id}`);
                            await System.apiDelete(`/griddocument/rdc22-avaliacoes-${item._id}`);
                        }}
                        validationSchema={yup.object().shape({
                            tipo: yup.string().required(),
                            legislacao: yup.string(),
                            ambito: yup.string(),
                            orgao: yup.string(),
                            ementa: yup.string(),
                            tema: yup.string(),
                            cadastro: yup.string(),
                            atualizacao: yup.string(),
                            responsavel: yup.string(),
                            situacao: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC23 = () => {
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc23"
                        perms={'2002003'}
                        title="Política da qualidade"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/rdc23'} perms={'2002003'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC24 = () => {
    const System = useSystemContext();
    function mountBars(values) {
        let ret = [];
        if (values.frequencia === 'Mensal') {
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: 'Jan' },
                { Anterior: values.avalor2, Atual: values.valor2, label: 'Fev' },
                { Anterior: values.avalor3, Atual: values.valor3, label: 'Mar' },
                { Anterior: values.avalor4, Atual: values.valor4, label: 'Abr' },
                { Anterior: values.avalor5, Atual: values.valor5, label: 'Mai' },
                { Anterior: values.avalor6, Atual: values.valor6, label: 'Jun' },
                { Anterior: values.avalor7, Atual: values.valor7, label: 'Jul' },
                { Anterior: values.avalor8, Atual: values.valor8, label: 'Ago' },
                { Anterior: values.avalor9, Atual: values.valor9, label: 'Set' },
                { Anterior: values.avalor10, Atual: values.valor10, label: 'Out' },
                { Anterior: values.avalor11, Atual: values.valor11, label: 'Nov' },
                { Anterior: values.avalor12, Atual: values.valor12, label: 'Dez' },
            ];
        }
        if (values.frequencia === 'Bimestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Bim' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Bim' },
                { Anterior: values.avalor3, Atual: values.valor3, label: '3º Bim' },
                { Anterior: values.avalor4, Atual: values.valor4, label: '4º Bim' },
                { Anterior: values.avalor5, Atual: values.valor5, label: '5º Bim' },
                { Anterior: values.avalor6, Atual: values.valor6, label: '6º Bim' },
            ];
        if (values.frequencia === 'Trimestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Tri' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Tri' },
                { Anterior: values.avalor3, Atual: values.valor3, label: '3º Tri' },
                { Anterior: values.avalor4, Atual: values.valor4, label: '4º Tri' },
            ];
        if (values.frequencia === 'Semestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Sem' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Sem' },
            ];
        if (values.frequencia === 'Anual') ret = [{ Anterior: values.avalor1, Atual: values.valor1, label: 'Ano' }];
        return ret;
    }

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc24"
                        perms={'2002004'}
                        title={'Objetivos da qualidade'}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Objectivos">
                    <Grid
                        url={'/griddocument/rdc24'}
                        perms={'2002004'}
                        clearAutoEdit={true}
                        modalHeight={'55%'}
                        modalWidth={'60%'}
                        modalMargin={'5% auto'}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-2/4 text-left pl-1' },
                            { title: 'Etapa', field: 'etapa', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Frequência', field: 'frequencia', sort: true, className: 'w-1/6 w-32 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao.substr(0, 50), data.etapa, data.frequencia, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" className="w-full" rows="8" />
                                    <Input name="etapa" label="Etapa" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <GroupInput>
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            className={'w-1/4'}
                                            listOptions={[{ value: 'Mensal' }, { value: 'Bimestral' }, { value: 'Trimestral' }, { value: 'Semestral' }, { value: 'Anual' }]}
                                        />
                                        <SelectInput
                                            name="operacao"
                                            label="Operação"
                                            className={'w-1/3'}
                                            listOptions={[
                                                { value: 'lt', option: '< (Menor quê)' },
                                                { value: 'le', option: '<= (Menor ou igual)' },
                                                { value: 'eq', option: '= (Igual)' },
                                                { value: 'ge', option: '>= (Maior ou igual)' },
                                                { value: 'gt', option: '> (Maior quê)' },
                                            ]}
                                        />
                                        <Input name="meta" label="Meta" className={'w-44'} />
                                    </GroupInput>
                                    <Input name="unidade" label="Unidade" />
                                    <TextInput name="memoria" label="Memória de Cálculo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            etapa: yup.string(),
                            responsavel: yup.string(),
                            frequencia: yup.string(),
                            operacao: yup.string(),
                            meta: yup.string(),
                            unidade: yup.string(),
                            memoria: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Gráfico</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de bordo</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Grafico
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/rdc24/${item._id}`}
                                            perms={'2002004'}
                                            title="Gráfico"
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <GroupInput>
                                                            <div className="mt-8 ml-2 w-36">Anterior</div>
                                                            {values.frequencia === 'Mensal' && (
                                                                <div className="inline-flex ml-4">
                                                                    <Input name="avalor1" label="Jan" />
                                                                    <Input name="avalor2" label="Fev" />
                                                                    <Input name="avalor3" label="Mar" />
                                                                    <Input name="avalor4" label="Abr" />
                                                                    <Input name="avalor5" label="Mai" />
                                                                    <Input name="avalor6" label="Jun" />
                                                                    <Input name="avalor7" label="Jul" />
                                                                    <Input name="avalor8" label="Ago" />
                                                                    <Input name="avalor9" label="Set" />
                                                                    <Input name="avalor10" label="Out" />
                                                                    <Input name="avalor11" label="Nov" />
                                                                    <Input name="avalor12" label="Dez" />
                                                                </div>
                                                            )}
                                                            {values.frequencia === 'Bimestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Bimestre" />
                                                                    <Input name="avalor2" label="2º Bimestre" />
                                                                    <Input name="avalor3" label="3º Bimestre" />
                                                                    <Input name="avalor4" label="4º Bimestre" />
                                                                    <Input name="avalor5" label="5º Bimestre" />
                                                                    <Input name="avalor6" label="6º Bimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Trimestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Trimestre" />
                                                                    <Input name="avalor2" label="2º Trimestre" />
                                                                    <Input name="avalor3" label="3º Trimestre" />
                                                                    <Input name="avalor4" label="4º Trimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Semestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Semestre" />
                                                                    <Input name="avalor2" label="2º Semestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Anual' && (
                                                                <>
                                                                    <Input name="avalor1" label="Anual" />
                                                                </>
                                                            )}
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <div className="pl-5 mt-8 w-36">Atual</div>
                                                            {values.frequencia === 'Mensal' && (
                                                                <div className="inline-flex ml-4">
                                                                    <Input name="valor1" label="Jan" />
                                                                    <Input name="valor2" label="Fev" />
                                                                    <Input name="valor3" label="Mar" />
                                                                    <Input name="valor4" label="Abr" />
                                                                    <Input name="valor5" label="Mai" />
                                                                    <Input name="valor6" label="Jun" />
                                                                    <Input name="valor7" label="Jul" />
                                                                    <Input name="valor8" label="Ago" />
                                                                    <Input name="valor9" label="Set" />
                                                                    <Input name="valor10" label="Out" />
                                                                    <Input name="valor11" label="Nov" />
                                                                    <Input name="valor12" label="Dez" />
                                                                </div>
                                                            )}
                                                            {values.frequencia === 'Bimestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Bimestre" />
                                                                    <Input name="valor2" label="2º Bimestre" />
                                                                    <Input name="valor3" label="3º Bimestre" />
                                                                    <Input name="valor4" label="4º Bimestre" />
                                                                    <Input name="valor5" label="5º Bimestre" />
                                                                    <Input name="valor6" label="6º Bimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Trimestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Trimestre" />
                                                                    <Input name="valor2" label="2º Trimestre" />
                                                                    <Input name="valor3" label="3º Trimestre" />
                                                                    <Input name="valor4" label="4º Trimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Semestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Semestre" />
                                                                    <Input name="valor2" label="2º Semestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Anual' && (
                                                                <>
                                                                    <Input name="valor1" label="Anual" />
                                                                </>
                                                            )}
                                                        </GroupInput>
                                                        <div className="w-full h-96">
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <BarChart
                                                                    width={500}
                                                                    height={300}
                                                                    data={mountBars(values)}
                                                                    margin={{
                                                                        top: 5,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                >
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="label" />
                                                                    <YAxis />
                                                                    <Tooltip />
                                                                    <Legend />

                                                                    <Bar dataKey="Anterior" legendType="none" fill="#83af83" />
                                                                    <Bar dataKey="Atual" legendType="none" fill="#93c6f9" />
                                                                    <ReferenceLine
                                                                        y={values.meta}
                                                                        stroke="black"
                                                                        strokeDasharray="5 5"
                                                                        label={{ value: 'Meta', position: 'top' }}
                                                                        ifOverflow="extendDomain"
                                                                    />
                                                                </BarChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({})}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/rdc24-${item._id}`} perms={'2002004'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div>
                                                <GridDiario url={'/griddocument/rdc24-' + item._id} perms={'2002004'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexos
                                return (
                                    <div>
                                        <GridAnexos url={`/attachment/rdc24-${item._id}`} disableModal={true} perms={'2002004'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/rdc24-${item._id}`);
                            await System.apiDelete(`/attachment/rdc24-${item._id}`);
                            await System.apiDelete(`/griddocument/rdc24-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC25 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center" color="#FFDDFF">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc25"
                        perms={'2002005'}
                        title="Organização e responsabilidades"
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                            estrutura: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" />
                                    <TextInput name="estrutura" label="Estrutura Organizacional" className="w-full" />
                                </div>
                            );
                        }}
                    />
                    <img src={RACI} alt="RACI" className="w-full" />
                </TabPanel>
                <TabPanel name="Gestao">
                    <Grid
                        url={'/griddocument/rdc25'}
                        perms={'2002005'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Requisito', field: 'requisito', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.requisito, data.descricao];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>

                                    <GroupInput>
                                        <Input name="requisito" label="Requisito" />
                                        <Input name="descricao" label="Descrição" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Funções</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <Grid
                                                    url={'/griddocument/rdc25-' + item._id}
                                                    perms={'2002005'}
                                                    disableModal={true}
                                                    GridHeaders={[
                                                        { title: 'Função', field: 'role', sort: true, className: 'w-full text-left pl-1' },
                                                        { title: 'Responsável', field: 'respons', sort: false, className: 'w-24 pl-1 text-left border-l border-gray-300' },
                                                        { title: 'Autoridade', field: 'autoridade', sort: false, className: 'w-24 pl-1 text-left border-l border-gray-300' },
                                                        { title: 'Consultado', field: 'consultado', sort: false, className: 'w-24 pl-1 text-left border-l border-gray-300' },
                                                        { title: 'Informado', field: 'informado', sort: false, className: 'w-24 pl-1 text-left border-l border-gray-300' },
                                                    ]}
                                                    GridTitle="Funções"
                                                    LineDataPrint={(data) => {
                                                        return [
                                                            data.funcao?.nome,
                                                            data.respons ? 'Sim' : 'Não',
                                                            data.autoridade ? 'Sim' : 'Não',
                                                            data.consultado ? 'Sim' : 'Não',
                                                            data.informado ? 'Sim' : 'Não',
                                                        ];
                                                    }}
                                                    onRenderForm={() => {
                                                        return (
                                                            <div className="w-full">
                                                                <DBSelectInput name="role" label="Função" url="/role" displaySelectField="nome" />
                                                                <GroupInput>
                                                                    <CheckBoxInput name="respons" label="Responsável" />
                                                                    <CheckBoxInput name="autoridade" label="Autoridade" />
                                                                    <CheckBoxInput name="consultado" label="Consultado" />
                                                                    <CheckBoxInput name="informado" label="Informado" />
                                                                </GroupInput>
                                                            </div>
                                                        );
                                                    }}
                                                    validationSchema={() => {
                                                        return yup.object().shape({
                                                            role: yup.string(),
                                                            respons: yup.boolean(),
                                                            autoridade: yup.boolean(),
                                                            consultado: yup.boolean(),
                                                            informado: yup.boolean(),
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/rdc25-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            requisito: yup.string(),
                            descricao: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/rdc25'} perms={'2002005'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC26 = () => {
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc26"
                        perms={'2002006'}
                        title="Comunicação"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Comunicação">
                    <Grid
                        url={'/griddocument/rdc26'}
                        perms={'2002006'}
                        clearAutoEdit={true}
                        GridHeaders={[{ title: 'O que', field: 'oque', sort: true, className: 'w-full text-left pl-1' }]}
                        LineDataPrint={(data) => {
                            return [data.oque];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="oque" label="O que comunica?" />
                                    <Input name="quando" label="Quando comunica" />
                                    <Input name="comquem" label="Com quem se comunica" />
                                    <Input name="como" label="Como comunica" />
                                    <Input name="quem" label="Quem comunica" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            oque: yup.string(),
                            quando: yup.string(),
                            comquem: yup.string(),
                            como: yup.string(),
                            quem: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/rdc26'} perms={'2002006'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC27 = () => {
    const System = useSystemContext();
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Gestão</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc27"
                        perms={'2002007'}
                        title="Revisão gerencial"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestao">
                    <Grid
                        url={'/griddocument/rdc27'}
                        perms={'2002007'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Número', field: 'numero', sort: true, className: 'w-1/5 text-left pl-1' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.numero, dateToString(data.data), data.descricao?.substr(0, 50), data.crew?.nome, `#link=${data.attachment}`];
                        }}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="numero" label="Número" />
                                        <DateInput name="data" label="Data" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <AttachmentInput name="attachment" label="Anexo" />
                                </div>
                            );
                        }}
                        onPrepareSaveRecord={(values) => {
                            let newForm = new FormData();
                            for (let key in values) {
                                newForm.append(key, values[key]);
                            }
                            return newForm;
                        }}
                        validationSchema={yup.object().shape({
                            numero: yup.string(),
                            data: yup.string(),
                            descricao: yup.string(),
                            responsavel: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/rdc27-${item._id}`} perms={'2002007'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/rdc27-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC28 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <div>
                        <FormInput
                            document="/document/rdc28"
                            perms={'2002008'}
                            title="Pessoas"
                            initialValues={{
                                conteudo: '',
                            }}
                            validationSchema={yup.object().shape({
                                conteudo: yup.string(),
                            })}
                            onRenderFields={() => {
                                return (
                                    <div className="w-full">
                                        <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                    </div>
                                );
                            }}
                        />
                        <img src={kirkpatrick} alt="kirkpatrick" />
                    </div>
                </TabPanel>
                <TabPanel name="Gestão de Pessoas">
                    <Grid
                        url={'/griddocument/rdc28'}
                        perms={'2002008'}
                        clearAutoEdit={true}
                        GridTitle={'Gestão de Pessoas'}
                        GridHeaders={[
                            { title: 'Id', field: '#crew.identificacao', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Nome', field: 'crew.nome', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Admissão', field: '@crew.admissao', sort: true, type: 'date', className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Função', field: 'crew.funcao.nome', sort: true, className: 'w-3/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.crew?.identificacao, data.crew?.nome, dateToString(data.crew?.admissao), data.crew?.funcao?.nome];
                        }}
                        onPrepareSaveRecord={(values) => {
                            let newForm = new FormData();
                            for (let key in values) {
                                newForm.append(key, values[key]);
                            }
                            return newForm;
                        }}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <DBSelectInput name="responsavel" label="Nome" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            responsavel: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Avaliação das competências</div>;
                            },
                            () => {
                                return <div>Plano de Desenvolvimento</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Avaliaçao
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/rdc28-avaliacao-${item._id}`}
                                            perms={'2002008'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Avaliação', field: 'avaliacao', sort: true, className: 'w-1/6 text-left pl-1' },
                                                { title: 'Superior imediato', field: 'resposavel', sort: false, className: 'w-4/6 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Próx.Avaliação', field: 'proxavaliacao', sort: false, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.avaliacao), data.crew?.nome, dateToString(data.proxavaliacao)];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="educacao"
                                                                label="Educação"
                                                                listOptions={[{ value: 'Atende' }, { value: 'Não atende' }, { value: 'Não aplicável' }]}
                                                            />
                                                            <SelectInput
                                                                name="treinamentos"
                                                                label="Treinamentos"
                                                                listOptions={[{ value: 'Atende' }, { value: 'Não atende' }, { value: 'Não aplicável' }]}
                                                            />
                                                            <SelectInput
                                                                name="habilidades"
                                                                label="Habilidades"
                                                                listOptions={[{ value: 'Atende' }, { value: 'Não atende' }, { value: 'Não aplicável' }]}
                                                            />
                                                            <SelectInput
                                                                name="experiencia"
                                                                label="Experiência"
                                                                listOptions={[{ value: 'Atende' }, { value: 'Não atende' }, { value: 'Não aplicável' }]}
                                                            />
                                                        </GroupInput>
                                                        <TextInput name="acoes" label="Ações necessárias para desenvolvimento" rows={3} />
                                                        <GroupInput>
                                                            <DateInput name="avaliacao" label="Data da avaliação" />
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Superior Imediato"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="proxavaliacao" label="Próxima avaliação" />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    descricao: yup.string(),
                                                    inicio: yup.date(),
                                                    termino: yup.date(),
                                                    tipo: yup.string(),
                                                    status: yup.string(),
                                                    responsavel: yup.string(),
                                                    anexo: yup.string(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Plano de Desenvolvimento
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/rdc28-plano-${item._id}`}
                                            perms={'2002008'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Data', field: 'dataplano', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Ação', field: 'acao', sort: true, className: 'w-8/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Prazo', field: 'prazo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.dataplano), data.acao, data.tipo, dateToString(data.prazo), data.status];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <DateInput name="dataplano" label="Data do Plano" className={'inline-block'} />
                                                        <Input name="acao" label="Ação" />
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="tipo"
                                                                label="Tipo"
                                                                listOptions={[{ value: 'Educação' }, { value: 'Treinamento' }, { value: 'Habilidade' }, { value: 'Experiência' }]}
                                                            />
                                                            <DateInput name="prazo" label="Prazo" />
                                                            <SelectInput name="status" label="Status" listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]} />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DateInput name="realizado" label="Realizado em" />
                                                            <SelectInput
                                                                name="reacao"
                                                                label="Avaliação de Reação"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                            <SelectInput
                                                                name="aprendizagem"
                                                                label="Avaliação de Aprendizagem"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                        </GroupInput>
                                                        <div className="mt-10"></div>
                                                        <GroupInput>
                                                            <DateInput name="prazoeficacaia" label="Prazo para eficácia" />
                                                            <SelectInput name="statuseficacia" label="Status" listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]} />
                                                            <SelectInput
                                                                name="comportamento"
                                                                label="Avaliação de Comportamento"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                            <SelectInput
                                                                name="resultado"
                                                                label="Avaliação de Resultado/Eficácia"
                                                                listOptions={[{ value: 'Necessita melhorar' }, { value: 'Neutro' }, { value: 'Adequado' }]}
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <DateInput name="realizadaem" label="Realizada em" className={'inline-block'} />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    dataplano: yup.date().required(),
                                                    acao: yup.string().required(),
                                                    tipo: yup.string().required(),
                                                    prazo: yup.date().required(),
                                                    status: yup.string().required(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // Diario de bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={`/griddocument/rdc28-${item._id}`} perms={'2002008'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                // Anexos
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/rdc28-${item._id}`} disableModal={true} perms={'2002008'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            try {
                                await System.apiDelete(`/griddocument/rdc28-${item._id}`);
                                await System.apiDelete(`/griddocument/rdc28-avaliacao-${item._id}`);
                                await System.apiDelete(`/griddocument/rdc28-plano-${item._id}`);
                            } catch (error) {
                                console.log('Deletando:', error);
                            }
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC29 = () => {
    const System = useSystemContext();
    const riscos = [
        { value: 0, option: 'Desprezível' },
        { value: 1, option: 'Menor' },
        { value: 2, option: 'Moderado' },
        { value: 4, option: 'Sério' },
        { value: 8, option: 'Crítico' },
    ];

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc29"
                        perms={'2002009'}
                        title="Gerenciamento de risco"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <div className="ml-4">
                        <img src={tabelaImg} alt="Tabela" />
                    </div>
                </TabPanel>
                <TabPanel name="Risco">
                    <div className="w-full">
                        <Grid
                            url={'/griddocument/rdc29'}
                            perms={'2002009'}
                            clearAutoEdit={true}
                            GridHeaders={[
                                { title: 'Requisito', field: 'requisito', sort: true, className: 'w-2/12 text-left pl-1' },
                                { title: 'Descrição do Risco', field: 'risco', sort: true, className: 'w-6/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Cadastro', field: 'cadastro', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Atualização', field: 'atualizacao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            ]}
                            LineDataPrint={(data) => {
                                return [
                                    data.requisito,
                                    data.descricaorisco,
                                    dateToString(data.cadastro),
                                    dateToString(data.atualizacao),
                                    data.crew?.nome,
                                    `#link=${data.attachment}`,
                                ];
                            }}
                            validationSchema={yup.object().shape({
                                requisito: yup.string(),
                                risco: yup.number(),
                                cadastro: yup.date(),
                                atualizacao: yup.date(),
                                responsavel: yup.string(),
                                attachment: yup.string(),
                                frequencia: yup.number(),
                                causas: yup.string(),
                                impactos: yup.string(),
                                acoes: yup.string(),
                            })}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                function calcRisco(a, b) {
                                    let risco = Math.round((a * b) / 8);
                                    risco = (a * b) / 8 < 1 ? 0 : risco;
                                    risco = risco > 8 ? 8 : risco;
                                    SetFieldValue('risco', risco);
                                }
                                return (
                                    <div>
                                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                        </div>
                                        <Input name="requisito" label="Requisito" />
                                        <TextInput name="descricaorisco" label="Descrição do risco" rows={3} />
                                        <GroupInput>
                                            <SelectInput
                                                name="severidade"
                                                label="Severidade"
                                                className={'w-1/3'}
                                                onChange={(e) => {
                                                    SetFieldValue('severidade', e.target.value);
                                                    calcRisco(e.target.value, values.frequencia);
                                                }}
                                                listOptions={[
                                                    { value: 1, option: 'I' },
                                                    { value: 2, option: 'II' },
                                                    { value: 4, option: 'III' },
                                                    { value: 8, option: 'IV' },
                                                ]}
                                            />
                                            <SelectInput
                                                name="frequencia"
                                                label="Frequência"
                                                className={'w-1/3'}
                                                onChange={(e) => {
                                                    SetFieldValue('frequencia', e.target.value);
                                                    calcRisco(values.severidade, e.target.value);
                                                }}
                                                listOptions={[
                                                    { value: 1, option: 'A' },
                                                    { value: 2, option: 'B' },
                                                    { value: 4, option: 'C' },
                                                    { value: 8, option: 'D' },
                                                    { value: 16, option: 'E' },
                                                ]}
                                            />
                                            <SelectInput name="risco" label="Risco" className={'w-1/3'} disabled listOptions={riscos} />
                                        </GroupInput>
                                        <Input name="causas" label="Possível(is) Causa(s) ou Potencial(is)" />
                                        <Input name="controle" label="Controle(s) ou oportunidade(s) sobre a(s) possível(is) causa(s) e/ou no(s) possível(is) impacto(s)" />
                                        <GroupInput>
                                            <SelectInput
                                                name="significancia"
                                                label="Significância/Aceitabilidade"
                                                listOptions={[{ value: 'Sim para riscos moderados, sérios e críticos' }, { value: 'Não para riscos menores e desprezíveis' }]}
                                            />
                                        </GroupInput>
                                        <GroupInput>
                                            <DateInput name="cadastro" label="Cadastro/Atualização" />
                                            <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                            <DateInput name="atualizacao" label="Próxima Atualização" />
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            FormTabs={[
                                () => {
                                    return <div>Home</div>;
                                },
                                () => {
                                    return <div>5W2H</div>;
                                },
                                () => {
                                    return <div>Diário de Bordo</div>;
                                },
                                () => {
                                    return (
                                        <div className="inline-flex justify-center">
                                            <span>Anexos</span>
                                        </div>
                                    );
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    return <Grid5W2H url={`/method5w2h/rdc29-${item._id}`} perms={'2002009'} />;
                                },
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <div>
                                            {!GridModes.insert_mode && (
                                                <div className={'m-2'}>
                                                    <GridDiario url={'/griddocument/rdc29-' + item._id} perms={'2002009'} />
                                                </div>
                                            )}
                                        </div>
                                    );
                                },
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <FormInput
                                            document={`/griddocument/rdc29/${item._id}`}
                                            perms={'2002009'}
                                            validationSchema={yup.object().shape({
                                                arquivo: yup.mixed(),
                                            })}
                                            onAfterSave={() => {
                                                GridRefresh();
                                            }}
                                            onRenderFields={(setFieldValue, values, callCommit) => {
                                                return <AttachmentInput name="attachment" label="Anexo" callParentCommit={callCommit} />;
                                            }}
                                        />
                                    );
                                },
                            ]}
                            onBeforeDelete={async (item) => {
                                await System.apiDelete('/griddocument/rdc29-' + item._id);
                                await System.apiDelete(`/method5w2h/rdc29-${item._id}`);
                            }}
                        />
                    </div>
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC210 = () => {
    const System = useSystemContext();
    const [tipos, setTipos] = useState([]);
    const [categorias, setCategorias] = useState([]);

    var criterios = [];
    function getData() {
        System.apiGet('/griddocument/rdc210-servicos').then((response) => {
            setTipos(response.data);
        });
        System.apiGet('/griddocument/rdc210-categorias').then((response) => {
            setCategorias(response.data);
        });
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc210"
                        perms={'2002010'}
                        title="Controle de compra"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestão">
                    <Grid
                        url={'/griddocument/rdc210'}
                        perms={'2002010'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Provedor Externo', field: 'provedor', sort: true, className: 'w-6/12 text-left pl-1' },
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Categoria', field: 'categoria', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Nota', field: 'nota', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Reavaliação', field: 'reavaliacao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            var rev = new Date('2000-01-01');
                            let lastNota = '';
                            data.revisao.map((item) => {
                                let dt = new Date(item.data);
                                if (dt > rev) {
                                    rev = dt;
                                    lastNota = item;
                                    return true;
                                }
                                return true;
                            });
                            return [
                                data.provedor,
                                typeof tipos === 'object' ? tipos.find((item) => item.id === data.tipo)?.tipo : '',
                                typeof categorias === 'object' ? categorias.find((item) => item.id === data.categoria)?.titulo : '',
                                lastNota.hasOwnProperty('notaFinal') ? lastNota?.notaFinal?.toFixed(1) : '',
                                data.crew?.nome,
                                dateToString(data.reavaliacao),
                            ];
                        }}
                        validationSchema={yup.object().shape({
                            provedor: yup.string(),
                            tipo: yup.string(),
                            reavaliacao: yup.date(),
                        })}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="codigo" label="Código" className={'w-1/4'} />
                                        <Input name="provedor" label="Provedor Externo" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="data" label="Data Cadastro / Atualização" />
                                        <DateInput name="reavaliacao" label="Data Reavaliação" />
                                    </GroupInput>
                                    <CepInput
                                        name="cep"
                                        label="CEP"
                                        className="w-44"
                                        onReceivedCep={(received) => {
                                            setFieldValue('endereco', received.logradouro);
                                            setFieldValue('bairro', received.bairro);
                                            setFieldValue('cidade', received.localidade);
                                            setFieldValue('estado', received.uf);
                                            setFieldValue('pais', 'Brasil');
                                        }}
                                    />
                                    <GroupInput>
                                        <Input name="endereco" label="Endereço" />
                                        <Input name="numero" label="Número" className="w-32" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="bairro" label="Bairro" />
                                        <Input name="cidade" label="Cidade" />
                                        <MaskInput
                                            name="estado"
                                            label="Estado"
                                            className="w-48"
                                            mask="aa"
                                            beforeMaskedValueChange={function (newState) {
                                                newState.value = newState.value.toUpperCase();
                                                return newState;
                                            }}
                                        />
                                        <Input name="pais" label="País" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DBSelectInput name="tipo" label="Tipo" displaySelectField="tipo" url="/griddocument/rdc210-servicos" />
                                        <SelectInput
                                            name="periodicidade"
                                            label="Periodicidade de Controle"
                                            listOptions={[
                                                { value: 'Mensal' },
                                                { value: 'Bimestral' },
                                                { value: 'Trimestral' },
                                                { value: 'Quatrimestral' },
                                                { value: 'Semestral' },
                                                { value: 'Anual' },
                                                { value: 'Bienal' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <DBSelectInput name="categoria" label="Categoria" displaySelectField="titulo" url="/griddocument/rdc210-categorias" />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Inspeção</div>;
                            },
                            () => {
                                return <div>Gráfico</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                        ]}
                        onSelectItem={async (item) => {
                            await System.apiGet(`/griddocument/rdc210-${item.tipo}-criterios`).then((response) => {
                                criterios = response.data;
                            });
                        }}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Inspeção
                                var url = `/griddocument/rdc210-ipde-${item._id}`;
                                var notaAnterior;
                                var foraPeriodo = 0;
                                const dtIni = new Date(item.data);
                                function saveNotaFinal(item) {
                                    System.apiPut(`${url}/${item._id}`, item);
                                    return true;
                                }
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={url}
                                            perms={'2002010'}
                                            defaultSortField={'data'}
                                            disableModal={true}
                                            onLoadData={(data) => {
                                                notaAnterior = 10;
                                                foraPeriodo = 0;
                                            }}
                                            GridHeaders={[
                                                { title: 'Data', field: 'data', sort: false, className: 'w-4/12 text-left pl-1' },
                                                { title: 'Nota Fiscal', field: 'notafiscal', sort: false, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Nota Final', field: 'nota', sort: false, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                let dt = new Date(data.data);
                                                if (foraPeriodo === 0) {
                                                    if (dt >= dtIni) {
                                                        foraPeriodo = 1;
                                                        notaAnterior = 10;
                                                    }
                                                }
                                                let nota = notaAnterior;
                                                criterios.forEach((item) => {
                                                    if (data[item.criterio] !== 'Atende') {
                                                        nota -= item.demerito;
                                                    }
                                                });
                                                notaAnterior = nota;
                                                data.notaFinal = nota;
                                                saveNotaFinal(data);
                                                return [dateToString(data.data), data.notafiscal, nota.toFixed(1)];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="data" label="Data" />
                                                            <Input name="notafiscal" label="Nota Fiscal" />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            {criterios.map((item, index) => {
                                                                return (
                                                                    <SelectInput
                                                                        key={index}
                                                                        name={item.criterio}
                                                                        label={item.criterio}
                                                                        listOptions={[{ value: 'Atende' }, { value: 'Não Atende' }]}
                                                                    />
                                                                );
                                                            })}
                                                        </GroupInput>
                                                        <TextInput name="observacoes" label="Observações" rows={2} />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    data: yup.date().required('Data requerida'),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Grafico
                                return <ChartRdc210 item={item} />;
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexos
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/rdc210-${item._id}`} perms={'2002010'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de Bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/rdc210-diario-' + item._id} perms={'2002010'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/rdc210-ipde-' + item._id);
                            await System.apiDelete('/griddocument/rdc210-categorias-' + item._id);
                            await System.apiDelete('/griddocument/rdc210-diario-' + item._id);
                            await System.apiDelete('/attachment/rdc210-' + item._id);
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc210'} perms={'2002010'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC31 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc31"
                        perms={'2003001'}
                        title="Requisitos gerais"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Informação Documentada">
                    <Grid
                        url={'/griddocument/rdc31'}
                        perms={'2003001'}
                        clearAutoEdit={true}
                        GridTitle={'Informação Documentada'}
                        GridHeaders={[
                            { title: 'Número', field: 'numero', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Título', field: 'titulo', sort: true, className: 'w-6/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Revisão', field: 'revisao', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Situação', field: 'situacao', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Anexo', field: 'anexo', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            var rev = new Date('2000-01-01');
                            let nrDoc = '';
                            data.revisao.map((item) => {
                                let dt = new Date(item.datarevisao);
                                if (dt > rev && item.statusaprovacao === 'Concluído') {
                                    rev = dt;
                                    nrDoc = item;
                                    return true;
                                }
                                return true;
                            });
                            return [data.numero, data.titulo, nrDoc.numero, dateToString(nrDoc.datarevisao), data.situacao, `#link=${nrDoc.attachment}`];
                        }}
                        validationSchema={yup.object().shape({
                            processo: yup.string(),
                            numero: yup.string(),
                            data: yup.string(),
                            origem: yup.string(),
                            tipo: yup.string(),
                            titulo: yup.string(),
                            formato: yup.string(),
                            meio: yup.string(),
                            armazenamento: yup.string(),
                            preservacao: yup.string(),
                            recuperacao: yup.string(),
                            retencao: yup.string(),
                            disposicao: yup.string(),
                            situacao: yup.string(),
                            proximaatualizacao: yup.string(),
                        })}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="processo" label="Processo" />
                                        <Input name="numero" label="Número" />
                                        <DateInput name="data" label="Data" className={'inline-block'} />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="origem" label="Origem" />
                                        <Input name="tipo" label="Tipo" />
                                    </GroupInput>
                                    <Input name="titulo" label="Título" />
                                    <GroupInput>
                                        <Input name="formato" label="Formato" />
                                        <Input name="meio" label="Meio" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="armazenamento" label="Armazenamento" />
                                        <Input name="preservacao" label="Preservação" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="recuperacao" label="Recuperação" />
                                        <Input name="retencao" label="Retenção" />
                                    </GroupInput>
                                    <Input name="disposicao" label="Disposição" />
                                    <GroupInput>
                                        <SelectInput
                                            name="situacao"
                                            label="Situação"
                                            className={'w-3/6'}
                                            listOptions={[
                                                { value: 'Ativo', option: 'Ativo' },
                                                { value: 'Inativo', option: 'Inativo' },
                                            ]}
                                        />
                                        <DateInput name="proximaatualizacao" label="Próxima Atualização" className={'inline-block'} />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Disponibilidade</div>;
                            },
                            () => {
                                return <div>Revisão</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Disponibilidade
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/rdc31-disponibilidade-${item._id}`}
                                            perms={'2003001'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Local', field: 'local', sort: true, className: 'w-1/2 text-left pl-1' },
                                                { title: 'Acesso', field: 'acesso', sort: false, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.local, data.acesso];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <Input name="local" label="Local" />
                                                        <SelectInput
                                                            name="acesso"
                                                            label="Acesso"
                                                            className={'w-3/6'}
                                                            listOptions={[{ value: 'Físico' }, { value: 'Eletrônico' }]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    local: yup.string(),
                                                    acesso: yup.string(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Revisão
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/rdc31-revisao-${item._id}`}
                                            perms={'2003001'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Número', field: 'numero', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Execução', field: 'responsavel', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Data Revisão', field: 'analise', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status Aprovação', field: 'status', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.numero, data.crew?.nome, dateToString(data.datarevisao), data.statusaprovacao, `#link=${data.attachment}`];
                                            }}
                                            onPrepareSaveRecord={(values) => {
                                                values.gridparent = item._id;
                                                let newForm = new FormData();
                                                for (let key in values) {
                                                    newForm.append(key, values[key]);
                                                }
                                                return newForm;
                                            }}
                                            onAfterSave={(data) => {
                                                GridRefresh();
                                            }}
                                            onRenderForm={(GridModes, setFieldValue, values, GridRefresh) => {
                                                return (
                                                    <div className="w-full">
                                                        <div className="w-full pl-2 bg-gray-300">Revisão</div>
                                                        <GroupInput>
                                                            <Input name="numero" label="Número" className={'w-1/4'} />
                                                            <DateInput name="datarevisao" label="Data Revisão" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Execução"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>

                                                        <div className="w-full pl-2 mt-2 bg-gray-300">Análise Crítica</div>
                                                        <DBSelectInput
                                                            name="analisecritica"
                                                            label="Análise Crítica"
                                                            disabled={
                                                                GridModes.insert_mode || System.property.user.level > 1 || System.property.user.crew_id === values.responsavel
                                                                    ? false
                                                                    : true
                                                            }
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <GroupInput>
                                                            <DateInput name="prazoanalise" label="Prazo Análise" className={'inline-block'} />
                                                            <SelectInput
                                                                name="statusanalise"
                                                                label="Status Análise"
                                                                className={'w-3/6'}
                                                                disabled={System.property.user.crew_id === values.analisecritica || System.property.user.level > 1 ? false : true}
                                                                defaultValue={'Pendente'}
                                                                listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]}
                                                            />
                                                            <DateInput
                                                                name="dataanalise"
                                                                label="Data Análise"
                                                                className={'inline-block'}
                                                                disabled={System.property.user.crew_id === values.analisecritica || System.property.user.level > 1 ? false : true}
                                                            />
                                                        </GroupInput>

                                                        <div className="w-full pl-2 mt-2 bg-gray-300">Aprovação</div>
                                                        <DBSelectInput
                                                            name="aprovacao"
                                                            label="Aprovação"
                                                            disabled={
                                                                GridModes.insert_mode ||
                                                                values.statusaprovacao === 'Pendente' ||
                                                                System.property.user.level > 1 ||
                                                                System.property.user.crew_id === values.responsavel
                                                                    ? false
                                                                    : true
                                                            }
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <GroupInput>
                                                            <DateInput name="prazoaprovacao" label="Prazo Aprovação" className={'inline-block'} />
                                                            <SelectInput
                                                                name="statusaprovacao"
                                                                label="Status Aprovação"
                                                                disabled={
                                                                    values.statusanalise === 'Concluído' &&
                                                                    (System.property.user.level > 1 || System.property.user.crew_id === values.aprovacao)
                                                                        ? false
                                                                        : true
                                                                }
                                                                className={'w-3/6'}
                                                                defaultValue={'Pendente'}
                                                                listOptions={[{ value: 'Concluído' }, { value: 'Pendente' }]}
                                                            />
                                                            <DateInput
                                                                name="dataaprovacao"
                                                                disabled={System.property.user.crew_id === values.aprovacao || System.property.user.level > 1 ? false : true}
                                                                label="Data Aprovação"
                                                                className={'inline-block'}
                                                            />
                                                        </GroupInput>

                                                        <div className="w-full pl-2 mt-2 bg-gray-300">Adicional</div>
                                                        <AttachmentInput
                                                            name="attachment"
                                                            disabled={System.property.user.crew_id !== values.responsavel && System.property.user.level <= 1}
                                                            label="Anexo"
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    numero: yup.string(),
                                                    responsavel: yup.string(),
                                                    analisecritica: yup.string(),
                                                    datarevisao: yup.date(),
                                                    statusrevisao: yup.string(),
                                                    aprovacao: yup.string(),
                                                    dataaprovacao: yup.date(),
                                                    statusaprovacao: yup.string(),
                                                    anexo: yup.string(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de bordo
                                return (
                                    <div className={'m-2'}>
                                        <GridDiario url={'/griddocument/rdc31-diariodebordo-' + item._id} perms={'2003001'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/rdc31-disponibilidade-' + item._id);
                            await System.apiDelete('/griddocument/rdc31-revisao-' + item._id);
                            await System.apiDelete('/griddocument/rdc31-diariodebordo-' + item._id);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC32 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc32"
                        perms={'2003002'}
                        title="Controle de documentos externos"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestão de Pessoas">
                    <Grid
                        url={'/griddocument/rdc32'}
                        perms={'2003002'}
                        clearAutoEdit={true}
                        defaultSortField="createdAt"
                        GridHeaders={[
                            { title: 'Categoria', field: 'categoria', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Empresa', field: 'empresa', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.categoria, data.empresa];
                        }}
                        validationSchema={yup.object().shape({
                            categoria: yup.string(),
                            empresa: yup.string(),
                        })}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="categoria" label="Categoria" />
                                    <Input name="empresa" label="Empresa" />
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Documentos</div>;
                            },
                            () => {
                                return <div>Condicionantes</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Documentos
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/rdc32-documentos-${item._id}`}
                                            perms={'2003002'}
                                            GridTitle={'Documentos'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Identificação', field: 'identificacao', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Títulos', field: 'titulo', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Vencimento', field: 'vencimento', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.identificacao, data.titulo, dateToString(data.vencimento), data.crew?.nome, data.status];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <Input name="identificacao" label="Número/Identificação" />
                                                        <Input name="titulo" label="Título" />
                                                        <Input name="emissor" label="Orgão emissor" />
                                                        <GroupInput>
                                                            <DateInput name="emissao" label="Data de emissão" />
                                                            <DateInput name="vencimento" label="Data de Vencimento" />
                                                            <DateInput name="alerta" label="Data de alerta" />
                                                        </GroupInput>
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Responsável pela atualização"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="status"
                                                                label="Status"
                                                                className={'inline-block'}
                                                                listOptions={[{ value: 'Válido' }, { value: 'A renovar' }, { value: 'Protocolado' }, { value: 'Vencido' }]}
                                                            />
                                                            <SelectInput
                                                                name="situacao"
                                                                label="Situação"
                                                                className={'inline-block'}
                                                                listOptions={[{ value: 'Ativo' }, { value: 'Inativo' }]}
                                                            />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                identificacao: yup.string(),
                                                titulo: yup.string(),
                                                emissor: yup.string(),
                                                emissao: yup.date(),
                                                vencimento: yup.date(),
                                                alerta: yup.date(),
                                                responsavel: yup.string(),
                                                status: yup.string(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Condicionantes
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/rdc32-condicionantes-${item._id}`}
                                            perms={'2003002'}
                                            GridTitle={'Documentos'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Identificação', field: 'identificacao', sort: true, className: 'w-8/12 text-left pl-1' },
                                                { title: 'Vencimento', field: 'vencimento', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.identificacao, dateToString(data.vencimento), data.crew?.nome, data.status];
                                            }}
                                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <Input name="identificacao" label="Número/Identificação" />
                                                        <TextInput name="condicionante" label="Descrição da condicionante" rows={5} />
                                                        <GroupInput>
                                                            <DateInput name="emissao" label="Data de emissão" />
                                                            <DateInput name="vencimento" label="Data de Vencimento" />
                                                            <DateInput name="alerta" label="Data de alerta" />
                                                        </GroupInput>
                                                        <DBSelectInput
                                                            name="responsavel"
                                                            label="Responsável pela atualização/atendimento"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="status"
                                                                label="Status"
                                                                className={'inline-block'}
                                                                listOptions={[{ value: 'Conforme' }, { value: 'A vencer' }, { value: 'Não conforme' }]}
                                                            />
                                                            <SelectInput
                                                                name="situacao"
                                                                label="Situação"
                                                                className={'inline-block'}
                                                                listOptions={[{ value: 'Ativo' }, { value: 'Inativo' }]}
                                                            />
                                                        </GroupInput>
                                                        <TextInput name="evidencia" label="Evidência do atendimento" rows={5} />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                identificacao: yup.string(),
                                                condicionante: yup.string(),
                                                emissao: yup.date(),
                                                vencimento: yup.date(),
                                                alerta: yup.date(),
                                                responsavel: yup.string(),
                                                status: yup.string(),
                                                evidencia: yup.string(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/rdc32-${item._id}`} perms={'2003002'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexos
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/rdc32-${item._id}`} disableModal={true} perms={'2003002'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/attachment/rdc32-${item._id}`);
                            await System.apiDelete(`/method5w2h/rdc32-${item._id}`);
                            await System.apiDelete(`/griddocument/rdc32-documentos-${item._id}`);
                            await System.apiDelete(`/griddocument/rdc32-condicionantes-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC33 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc33"
                        perms={'2003003'}
                        title={System.getMenuTitle(2003003)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc33'} perms={'2003003'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC34 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc34"
                        perms={'2003004'}
                        title={System.getMenuTitle(2003004)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc34'} perms={'2003004'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC41 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc41"
                        perms={'2004001'}
                        title="Controle de projeto"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestão">
                    <Grid
                        url={'/griddocument/rdc41'}
                        perms={'2004001'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Código', field: 'codigo', sort: true, className: 'w-1/6 text-left pl-1' },
                            { title: 'Título', field: 'titulo', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'De', field: 'de', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Até', field: 'ate', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.codigo, data.titulo, dateToString(data.de), dateToString(data.ate), data.crew?.nome, `#link=${data.attachment}`];
                        }}
                        onPrepareSaveRecord={(values) => {
                            let newForm = new FormData();
                            for (let key in values) {
                                newForm.append(key, values[key]);
                            }
                            return newForm;
                        }}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="codigo" label="Código" className={'w-1/4'} />
                                        <Input name="titulo" label="Título" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="de" label="Período de" />
                                        <DateInput name="ate" label="Até" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição" className="w-full" rows="4" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            codigo: yup.string(),
                            titulo: yup.string(),
                            de: yup.date(),
                            ate: yup.date(),
                            responsavel: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Entrada</div>;
                            },
                            () => {
                                return <div>Saída</div>;
                            },
                            () => {
                                return <div>Análise Crítica</div>;
                            },
                            () => {
                                return <div>Verificação</div>;
                            },
                            () => {
                                return <div>Validação</div>;
                            },
                            () => {
                                return <div>Controle de Alterações</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Entrada
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/rdc41/${item._id}`}
                                            perms={'2004001'}
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="entrada_inicio" label="Data Início" className={'inline-block'} />
                                                            <DateInput name="entrada_fim" label="Data Final" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="entrada_responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="entrada_status"
                                                                label="Status"
                                                                listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                                className={'w-44'}
                                                            />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            onPrepareSaveRecord={(values, sender) => {
                                                // requerido para anexos
                                                let newForm = new FormData();
                                                for (let key in values) {
                                                    newForm.append(key, values[key]);
                                                }
                                                return newForm;
                                            }}
                                            validationSchema={yup.object().shape({
                                                entrada_inicio: yup.date(),
                                                entrada_fim: yup.date(),
                                                entrada_responsavel: yup.string(),
                                                entrada_anexo: yup.string(),
                                                entrada_status: yup.string(),
                                            })}
                                        />
                                        <div className={'m-2'}>
                                            <Grid
                                                url={`/griddocument/rdc41-entrada-${item._id}`}
                                                perms={'2004001'}
                                                disableModal={true}
                                                GridHeaders={[
                                                    { title: 'Requisitos', field: 'requisitos', sort: true, className: 'w-1/2 text-left pl-1' },
                                                    { title: 'Análise Crítica', field: 'analise', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                                                ]}
                                                LineDataPrint={(data) => {
                                                    return [data.requisitos, data.analise];
                                                }}
                                                onRenderForm={() => {
                                                    return (
                                                        <div className="w-full">
                                                            <Input name="requisitos" label="Requisitos" />
                                                            <Input name="analise" label="Análize Crítica" />
                                                        </div>
                                                    );
                                                }}
                                                validationSchema={() => {
                                                    return yup.object().shape({
                                                        requisitos: yup.string(),
                                                        analise: yup.string(),
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Saida
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/rdc41/${item._id}`}
                                            perms={'2004001'}
                                            onRenderFields={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="saida_inicio" label="Data Início" className={'inline-block'} />
                                                            <DateInput name="saida_fim" label="Data Final" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="saida_responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <SelectInput
                                                            name="saida_status"
                                                            label="Status"
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                            className={'w-44'}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                saida_inicio: yup.date(),
                                                saida_fim: yup.date(),
                                                saida_responsavel: yup.string(),
                                                saida_status: yup.string(),
                                            })}
                                        />
                                        <div className={'m-2'}>
                                            <Grid
                                                url={`/griddocument/rdc41-saida-${item._id}`}
                                                perms={'2004001'}
                                                disableModal={true}
                                                GridHeaders={[
                                                    { title: 'Requisitos', field: 'requisitos', sort: true, className: 'w-1/2 text-left pl-1' },
                                                    { title: 'Análise Crítica', field: 'analise', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                                                ]}
                                                LineDataPrint={(data) => {
                                                    return [data.requisitos, data.analize];
                                                }}
                                                onRenderForm={() => {
                                                    return (
                                                        <div className="w-full">
                                                            <Input name="requisitos" label="Requisitos" />
                                                            <Input name="analise" label="Análise Crítica" />
                                                        </div>
                                                    );
                                                }}
                                                validationSchema={() => {
                                                    return yup.object().shape({
                                                        requisitos: yup.string(),
                                                        analise: yup.string(),
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Análise Crítica
                                var url = `/griddocument/rdc41-analise-${item._id}`;
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={url}
                                            perms={'2004001'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Inicio', field: 'inicio', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Fim', field: 'fim', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Titulo', field: 'titulo', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Responsável', field: 'reponsavel', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.inicio), dateToString(data.fim), data.titulo, data.status, data.crew?.nome];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="inicio" label="Início" />
                                                            <DateInput name="fim" label="Fim" />
                                                            <Input name="titulo" label="Titulo" />
                                                        </GroupInput>
                                                        <TextInput name="descricao" label="Descrição" />
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="status"
                                                                label="Status"
                                                                className={'w-36'}
                                                                listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                            />
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    requisitos: yup.string(),
                                                    analize: yup.string(),
                                                    status: yup.string(),
                                                });
                                            }}
                                            FormTabs={[
                                                () => {
                                                    return <div>Home</div>;
                                                },
                                                () => {
                                                    return <div>Presença</div>;
                                                },
                                                () => {
                                                    return <div>Anexo</div>;
                                                },
                                            ]}
                                            onRenderFormExtra={[
                                                (GridModes, subitem, GridRefresh) => {
                                                    // presença
                                                    return (
                                                        <Grid
                                                            url={`/griddocument/rdc41-analise-presenca-${subitem._id}`}
                                                            perms={'2004001'}
                                                            disableModal={true}
                                                            GridHeaders={[
                                                                { title: 'Nome', field: 'nome', sort: true, className: 'w-1/3 text-left pl-1' },
                                                                { title: 'Presença', field: 'presenca', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                                                                {
                                                                    title: 'Justificativa',
                                                                    field: 'justificativa',
                                                                    sort: true,
                                                                    className: 'w-1/2 pl-1 text-left border-l border-gray-300',
                                                                },
                                                            ]}
                                                            LineDataPrint={(data) => {
                                                                return [data.nome, data.presenca ? 'Sim' : 'Não', data.justificativa];
                                                            }}
                                                            onRenderForm={() => {
                                                                return (
                                                                    <div className="w-full">
                                                                        <GroupInput>
                                                                            <Input name="nome" label="Nome" />
                                                                            <CheckBoxInput name="presenca" label="Presença" />
                                                                        </GroupInput>
                                                                        <Input name="justificativa" label="Justificativa" />
                                                                    </div>
                                                                );
                                                            }}
                                                            validationSchema={() => {
                                                                return yup.object().shape({
                                                                    nome: yup.string(),
                                                                    presenca: yup.boolean(),
                                                                    justificativa: yup.string(),
                                                                });
                                                            }}
                                                        />
                                                    );
                                                },
                                                (GridModes, subitem, GridRefresh) => {
                                                    // anexo
                                                    return (
                                                        <FormInput
                                                            document={`${url}/${subitem._id}`}
                                                            perms={'2004001'}
                                                            onPrepareSaveRecord={(values, sender) => {
                                                                let newForm = new FormData();
                                                                for (let key in values) {
                                                                    newForm.append(key, values[key]);
                                                                }
                                                                return newForm;
                                                            }}
                                                            validationSchema={yup.object().shape({
                                                                arquivo: yup.string(),
                                                            })}
                                                            onAfterSave={() => {
                                                                GridRefresh();
                                                            }}
                                                            onRenderFields={(setFieldValue, values, callCommit) => {
                                                                return <AttachmentInput name="attachment" label="Anexo" callParentCommit={callCommit} />;
                                                            }}
                                                        />
                                                    );
                                                },
                                            ]}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Verificação
                                return (
                                    <FormInput
                                        document={`/griddocument/rdc41/${item._id}`}
                                        perms={'2004001'}
                                        onRenderFields={() => {
                                            return (
                                                <div className="w-full">
                                                    <GroupInput>
                                                        <DateInput name="verificacao_inicio" label="Data Início" className={'inline-block'} />
                                                        <DateInput name="verificacao_fim" label="Data Final" className={'inline-block'} />
                                                        <DBSelectInput
                                                            name="verificacao_responsavel"
                                                            label="Responsável"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                    </GroupInput>
                                                    <TextInput name="verificacao_descricao" label="Descrição" />
                                                    <SelectInput
                                                        name="verificacao_status"
                                                        label="Status"
                                                        listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        className={'inline-block'}
                                                    />
                                                </div>
                                            );
                                        }}
                                        validationSchema={yup.object().shape({
                                            verificacao_inicio: yup.date(),
                                            verificacao_fim: yup.date(),
                                            verificacao_responsavel: yup.string(),
                                            verificacao_status: yup.string(),
                                        })}
                                    />
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Validação
                                return (
                                    <FormInput
                                        document={`/griddocument/rdc41/${item._id}`}
                                        perms={'2004001'}
                                        onRenderFields={() => {
                                            return (
                                                <div className="w-full">
                                                    <GroupInput>
                                                        <DateInput name="validacao_inicio" label="Data Início" className={'inline-block'} />
                                                        <DateInput name="validacao_fim" label="Data Final" className={'inline-block'} />
                                                        <DBSelectInput
                                                            name="validacao_responsavel"
                                                            label="Responsável"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                    </GroupInput>
                                                    <TextInput name="validacao_descricao" label="Descrição" />
                                                    <SelectInput
                                                        name="validacao_status"
                                                        label="Status"
                                                        listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        className={'inline-block'}
                                                    />
                                                </div>
                                            );
                                        }}
                                        validationSchema={yup.object().shape({
                                            validacao_inicio: yup.date(),
                                            validacao_fim: yup.date(),
                                            validacao_responsavel: yup.string(),
                                            validacao_status: yup.string(),
                                        })}
                                    />
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Controle de Alterações
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/rdc41-alteracao-${item._id}`}
                                            perms={'2004001'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-2/12 text-left pl-1' },
                                                { title: 'Descrição', field: 'descricao', sort: true, className: 'w-5/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Observação', field: 'observacao', sort: true, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.crew?.nome, data.descricao, data.observacao, data.status];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="inicio" label="Data Início" className={'inline-block'} />
                                                            <DateInput name="fim" label="Data Final" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <TextInput name="descricao" label="Descrição" />
                                                        <TextInput name="observacao" label="Observação" />
                                                        <SelectInput
                                                            name="status"
                                                            label="Status"
                                                            className={'inline-block'}
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    responsavel: yup.string(),
                                                    descricao: yup.string(),
                                                    observacao: yup.string(),
                                                    status: yup.string(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexo
                                return <GridAnexos url={`/attachment/rdc41-${item._id}`} disableModal={true} perms={'2004001'} />;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/rdc41-entrada-' + item._id);
                            await System.apiDelete('/griddocument/rdc41-saida-' + item._id);
                            await System.apiDelete('/griddocument/rdc41-analise-' + item._id);
                            await System.apiDelete('/griddocument/rdc41-analise-presenca-' + item._id);
                            await System.apiDelete('/griddocument/rdc41-alteracao-' + item._id);
                            await System.apiDelete(`/attachment/rdc41-${item._id}`);
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/rdc41'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC42 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc42"
                        perms={'2004002'}
                        title={System.getMenuTitle(2004002)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc42'} perms={'2004002'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC51 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc51"
                        perms={'2005001'}
                        title={System.getMenuTitle(2005001)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc51'} perms={'2005001'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC52 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc52"
                        perms={'2005002'}
                        title="Infraestrutura / manutenção"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Infraestrtutura">
                    <Grid
                        url={'/griddocument/rdc52'}
                        perms={'2005002'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/4 text-left pl-1' },
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Frequência', field: 'frequencia', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao, data.tipo, data.frequencia, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" />
                                    <GroupInput>
                                        <SelectInput name="tipo" label="Tipo" listOptions={[{ value: 'Interno' }, { value: 'Externo' }]} />
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            listOptions={[
                                                { value: 'Diário' },
                                                { value: 'Semanal' },
                                                { value: 'Quinzenal' },
                                                { value: 'Mensal' },
                                                { value: 'Bimestral' },
                                                { value: 'Trimestral' },
                                                { value: 'Semestral' },
                                                { value: 'Anual' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="proxatualizacao" label="Próxima Atualização" className={'inline-block'} />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/rdc52-' + item._id} perms={'2005002'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item) => {
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={'/attachment/rdc52-' + item._id} disableModal={true} perms={'2005002'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/attachment/rdc52-' + item._id);
                            await System.apiDelete('/griddocument/rdc52-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            tipo: yup.string(),
                            frequencia: yup.string(),
                            responsavel: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC53 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc53"
                        perms={'2005003'}
                        title={System.getMenuTitle(2005003)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc53'} perms={'2005003'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC54 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc54"
                        perms={'2005004'}
                        title={System.getMenuTitle(2005004)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc54'} perms={'2005004'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC55 = () => {
    const System = useSystemContext();
    const refGrid = createRef();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc55"
                        perms={'2005005'}
                        title="Equipamentos de medição e testes"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Metrologia">
                    <Grid
                        url={'/griddocument/rdc55'}
                        perms={'2005005'}
                        clearAutoEdit={true}
                        ref={refGrid}
                        GridHeaders={[
                            { title: 'Código', field: 'codigo', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-10/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Frequência', field: 'frequencia', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.codigo, data.descricao, data.frequencia];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values, Refresh) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left w-1/ bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="codigo" label="Código" />
                                    <Input name="descricao" label="Descrição" />
                                    <Input name="local" label="Local da instalação" />
                                    <Input name="posicao" label="Posição da instalação" />
                                    <GroupInput>
                                        <Input name="modelo" label="Modelo/Fabricante" />
                                        <Input name="capacidade" label="Capacidade de medição" />
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            listOptions={[{ value: 'Semestral' }, { value: 'Anual' }, { value: 'Bienal' }, { value: '3 anos' }, { value: '5 anos' }]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="tolerancia" label="Tolerância Procedimento +/-" />
                                        <Input name="aprovacao" label="Tolerância de Aprovação" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="uso" label="Faixa de Uso" />
                                        <Input name="calibracao" label="Procedimento de Calibração" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="ultimacalibracao" label="Última calibração" className={'inline-block'} />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                    <DateInput name="proximacalibracao" label="Próxima calibração" className={'inline-block'} />
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/rdc55-' + item._id} perms={'2005005'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return <GridAnexos url={`/attachment/rdc55-${item._id}`} disableModal={true} perms={'2005005'} />;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/rdc55-' + item._id);
                            await System.apiDelete('/attachment/rdc55-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            codigo: yup.string(),
                            descricao: yup.string(),
                            local: yup.string(),
                            posicao: yup.string(),
                            modelo: yup.string(),
                            capacidade: yup.string(),
                            frequencia: yup.string().required(),
                            tolerancia: yup.string(),
                            aprovacao: yup.string(),
                            uso: yup.string(),
                            calibracao: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC56 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc56"
                        perms={'2005006'}
                        title={System.getMenuTitle(2005006)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc56'} perms={'2005006'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC57 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc57"
                        perms={'2005007'}
                        title="Planejamento de mudanças"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Ambiental">
                    <Grid
                        url={'/griddocument/rdc57'}
                        perms={'2005007'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Data', field: 'data', sort: true, className: 'w-48 text-left pl-1' },
                            { title: 'Mudança', field: 'mudanca', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-52 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [dateToString(data.data), data.mudanca, data.tipo, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <DateInput name="data" label="Data" className={'inline-block'} />
                                        <SelectInput
                                            name="mudanca"
                                            label="Mudança"
                                            listOptions={[
                                                { value: 'Sistema de Gestão' },
                                                { value: 'Novo Produto' },
                                                { value: 'Novo Processo' },
                                                { value: 'Novo Serviço' },
                                                { value: 'Mudança de requisito legal' },
                                                { value: 'Novo conhecimento ou tecnologia' },
                                                { value: 'Mudança em aspecto/impacto ambiental' },
                                                { value: 'Mudança em perigo/risco ocupacional' },
                                                { value: 'Alteração de Produto, Processo ou Serviço' },
                                                { value: 'Alteração da Razão Social/ Nome Fantasia' },
                                                { value: 'Alteração de endereço' },
                                                { value: 'Alteração do Representante Legal ou quadro societário' },
                                                { value: 'Outros' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <SelectInput name="tipo" label="Tipo" className={'w-48'} listOptions={[{ value: 'Permanente' }, { value: 'Temporário' }]} />
                                        <Input name="local" label="Local da mudança" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição da mudança" rows={4} />
                                    <CheckBoxInput name="avaliacao" label="Mudança na avaliação de riscos e oportunidades" />
                                    <CheckBoxInput name="requisito" label="Mudança na identificação de requisito legal ou outro requisito" />
                                    <CheckBoxInput name="aspecto" label="Mudança na identificação de aspecto/impacto ambiental ou ocupacional" />
                                    <CheckBoxInput name="comunicacao" label="Necessidade de comunicação interna ou externa" />
                                    <CheckBoxInput name="alteracao" label="Necessidade de alteração do programa de auditoria interna" />
                                    <TextInput name="participantes" label="Participantes da análise" rows={4} />
                                    <GroupInput>
                                        <DateInput name="dataanalise" label="Data da análise" className={'inline-block'} />
                                        <DBSelectInput
                                            name="responsavel"
                                            label="Responsável pela aprovação da mudança"
                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                            className={'inline-block'}
                                            displaySelectField="nome"
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return <Grid5W2H url={`/method5w2h/rdc57-${item._id}`} perms={'2005007'} />;
                            },
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/rdc57-' + item._id} perms={'2005007'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return <GridAnexos url={'/attachment/rdc57-' + item._id} perms={'2005007'} />;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/rdc57-' + item._id);
                        }}
                        validationSchema={yup.object().shape({
                            data: yup.string(),
                            mudanca: yup.string(),
                            tipo: yup.string(),
                            local: yup.string(),
                            descricao: yup.string(),
                            avaliacao: yup.boolean(),
                            requisito: yup.boolean(),
                            aspecto: yup.boolean(),
                            comunicacao: yup.boolean(),
                            alteracao: yup.boolean(),
                            participantes: yup.string(),
                            dataanalise: yup.string(),
                            responsavel: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC61 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc61"
                        perms={'2006001'}
                        title={System.getMenuTitle(2006001)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc61'} perms={'2006001'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC62 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc62"
                        perms={'2006002'}
                        title={System.getMenuTitle(2006002)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc62'} perms={'2006002'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC63 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc63"
                        perms={'2006003'}
                        title="Propriedade pertencente a clientes ou fornecedores"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Liberação de P&S">
                    <Grid
                        url={'/griddocument/rdc63'}
                        perms={'2006003'}
                        clearAutoEdit={true}
                        GridTitle={'Propriedade do cliente ou Provedor Externo'}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/4 text-left pl-1' },
                            { title: 'Cliente ou Provedor Externo', field: 'provedor', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Unidade de Medida', field: 'unidade', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao, data.provedor, data.unidade, dateToString(data.data)];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" />
                                    <Input name="provedor" label="Cliente ou Provedor Externo" />
                                    <GroupInput>
                                        <Input name="unidade" label="Unidade de Medida" />
                                        <DateInput name="data" label="Data" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Controle</div>;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/rdc63-' + item._id);
                        }}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <Grid
                                                    url={'/griddocument/rdc63-' + item._id}
                                                    perms={'2006003'}
                                                    disableModal={true}
                                                    GridTitle="Controle"
                                                    GridHeaders={[
                                                        { title: 'Data', field: 'Data', sort: true, className: 'w-1/4 text-left pl-1' },
                                                        { title: 'Nota Fiscal', field: 'nota', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                                                        { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                                                        {
                                                            title: 'Quantidade',
                                                            field: 'quantidade',
                                                            sort: false,
                                                            className: 'w-1/4 pl-1 text-left border-l border-gray-300',
                                                            totalize: true,
                                                            totalTitle: 'Estoque',
                                                        },
                                                    ]}
                                                    LineDataPrint={(data) => {
                                                        return [
                                                            dateToString(data.data),
                                                            data.nota,
                                                            data.tipo,
                                                            <div className="inline-flex text-center">
                                                                {data.tipo === 'Entrada' ? <BiPlusCircle className="w-4 h-4 mt-1" /> : <BiMinusCircle className="w-4 h-4 mt-1" />}
                                                                {data.quantidade}
                                                            </div>,
                                                        ];
                                                    }}
                                                    TotalLineDataPrint={(data, GridTotal) => {
                                                        return GridTotal.map((totalizador) => {
                                                            if (totalizador.field === 'quantidade') {
                                                                if (data.tipo === 'Entrada') totalizador.value += parseInt(data.quantidade);
                                                                else totalizador.value -= parseInt(data.quantidade);
                                                            }
                                                            return true;
                                                        });
                                                    }}
                                                    onRenderForm={(GridModes, setFieldValue, values) => {
                                                        return (
                                                            <div className="w-full">
                                                                <DateInput name="data" label="Data" />
                                                                <Input name="nota" label="Nota Fiscal" />
                                                                <SelectInput
                                                                    name="tipo"
                                                                    label="Tipo"
                                                                    className={'w-1/3'}
                                                                    onChange={(e) => {
                                                                        setFieldValue('tipo', e.target.value);
                                                                    }}
                                                                    listOptions={[{ value: 'Entrada' }, { value: 'Saida' }]}
                                                                />
                                                                <Input name="quantidade" label="Quantidade" />
                                                            </div>
                                                        );
                                                    }}
                                                    validationSchema={() => {
                                                        return yup.object().shape({});
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            provedor: yup.string(),
                            unidade: yup.string(),
                            data: yup.date(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC64 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc64"
                        perms={'2006004'}
                        title={System.getMenuTitle(2006004)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc64'} perms={'2006004'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};
export const RDC71 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc71"
                        perms={'2007001'}
                        title="Requisitos gerais / gerenciamento das reclamações"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <Grid
                        url={'/griddocument/rdc71'}
                        perms={'2007001'}
                        clearAutoEdit={true}
                        GridTitle={'Registro de Ocorrência'}
                        GridHeaders={[
                            { title: 'Número', field: 'numero', sort: true, className: 'w-1/12 text-left pl-1' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            { title: 'Descrição', field: 'descricaoresumida', sort: false, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Disposição', field: 'statusdisposicao', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            { title: 'Ação corretiva', field: 'statusacao', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            { title: 'Eficácia', field: 'statusverificacao', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                        ]}
                        onAfterLoadData={(data) => {
                            return data.map((item) => {
                                if ('acaocorretiva' in item && item.acaocorretiva === 'Não') {
                                    item.statusacao = 'Não aplicável';
                                    item.statusverificacao = 'Não aplicável';
                                }
                                return item;
                            });
                        }}
                        LineDataPrint={(data) => {
                            return [data.numero, dateToString(data.data), data.descricaoresumida, data.statusdisposicao, data.statusacao, data.statusverificacao];
                        }}
                        validationSchema={yup.object().shape({
                            numero: yup.string(),
                            data: yup.date(),
                            emitente: yup.string(),
                            origem: yup.string(),
                            descricaoresumida: yup.string(),
                            cliente: yup.string(),
                            provedorexterno: yup.string(),
                            descricao: yup.string(),
                        })}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="numero" label="Número" className="w-1/6" />
                                        <DateInput name="data" label="Data" className="inline-block" />
                                        <DBSelectInput name="emitente" label="Emitente" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                        <SelectInput
                                            name="origem"
                                            label="Origem"
                                            className="inline-block"
                                            listOptions={[
                                                { value: 'Auditoria interna' },
                                                { value: 'Auditoria externa' },
                                                { value: 'Provedor externo' },
                                                { value: 'Cliente' },
                                                { value: 'Processo interno' },
                                                { value: 'Mudança' },
                                                { value: 'Incidente' },
                                                { value: 'Acidente' },
                                                { value: 'Outros' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <Input name="descricaoresumida" label="Descrição resumida" className="w-full" />
                                    <GroupInput>
                                        <Input name="cliente" label="Cliente" />
                                        <Input name="provedorexterno" label="Provedor externo" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição completa" className="w-full" rows="5" />
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Disposição</div>;
                            },
                            () => {
                                return <div>Ação Corretiva</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Disposição
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/rdc71/${item._id}`}
                                            perms={'2007001'}
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div className="w-full">
                                                        <SelectInput
                                                            name="disposicao"
                                                            label="Disposição"
                                                            listOptions={[
                                                                { value: 'Rejeição' },
                                                                { value: 'Retrabalho' },
                                                                { value: 'Reclassificação' },
                                                                { value: 'Concessão' },
                                                                { value: 'Reposição' },
                                                                { value: 'Coleta e reposição' },
                                                                { value: 'Usar como está' },
                                                                { value: 'Outro' },
                                                            ]}
                                                        />
                                                        <TextInput name="detalhamento" label="Detalhamento/Operação" className="w-full" rows="5" />
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="prazodisposicao" label="Prazo" className="w-1/6" />
                                                            <SelectInput
                                                                name="statusdisposicao"
                                                                label="Status"
                                                                className={'w-44'}
                                                                listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                            />
                                                        </GroupInput>
                                                        <SelectInput
                                                            name="acaocorretiva"
                                                            label="Ação Corretiva"
                                                            className={'w-3/6'}
                                                            listOptions={[{ value: 'Não' }, { value: 'Sim' }]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            onAfterSave={() => {
                                                GridRefresh();
                                            }}
                                            validationSchema={yup.object().shape({
                                                disposicao: yup.string(),
                                                detalhamento: yup.string(),
                                                responsavel: yup.string(),
                                                status: yup.string(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Ação Corretiva
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/rdc71/${item._id}`}
                                            perms={'2007001'}
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div className="w-full">
                                                        <div className="pl-2 bg-amarelo-eq">Análise da Causa</div>
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="resposavelanalise"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="prazoanalise" label="Prazo" className="w-1/6" />
                                                            <SelectInput
                                                                name="statusanalise"
                                                                label="Status"
                                                                className={'w-44'}
                                                                listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                            />
                                                        </GroupInput>
                                                        <TextInput name="extensao" label="Extensão da Ocorrência" className="w-full" rows="2" />
                                                        <TextInput name="os5porques" label="5 Porquês" rows={5} />
                                                        <div className="mt-4 mb-2 ml-2">6M's</div>
                                                        <Input name="maquina" label="Máquina" />
                                                        <Input name="materiais" label="Materiais" />
                                                        <Input name="maodeobra" label="Mão de Obra" />
                                                        <Input name="meioambiente" label="Meio Ambiente" />
                                                        <Input name="metodo" label="Método" />
                                                        <Input name="medidas" label="Medidas" />
                                                        <TextInput name="causaraiz" label="Causa(s) Raiz" rows="2" />
                                                        <TextInput name="participanteanalise" label="Participantes da análise" rows="2" />
                                                        <DateInput name="dataanalize" label="Data da análise" />
                                                        <CheckBoxInput name="alteramapeamento" label="Altera mapeamento de processos?" />
                                                        <CheckBoxInput name="alterariscos" label="Altera gestão de riscos e oportunidades?" />
                                                        <CheckBoxInput name="alteraaspectoimpacto" label="Altera gestão de aspectos e impactos ambientais?" />
                                                        <CheckBoxInput name="alteragestaoriscoasaude" label="Altera gestão de riscos à saúde e segurança ocupacional?" />
                                                        <CheckBoxInput name="alteracomunicacao" label="Altera gestão de comunicação?" />
                                                        <CheckBoxInput name="mudancasgq" label="Necessidade de mudança do SGQ?" />
                                                        <div className="mt-2 ml-2 bg-amarelo-eq">Ação Corretiva</div>
                                                        <TextInput name="planoexecucao" label="Plano de Execução" className="w-full" rows={5} />
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="responsavelacao"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="prazoacao" label="Prazo" className="inline-block" />
                                                            <DateInput name="dataacao" label="Data" className="inline-block" />
                                                        </GroupInput>
                                                        <SelectInput
                                                            name="statusacao"
                                                            label="Status ação corretiva"
                                                            className={'inline-block'}
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        />
                                                        <div className="mt-2 ml-2 bg-amarelo-eq">Verificação</div>
                                                        <TextInput name="verificacaoplano" label="Verificação do Plano" rows={3} />
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="resposavelplano"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="dataplano" label="Data" className="inline-block" />
                                                            <DateInput name="prazoplano" label="Prazo para Eficácia" className="inline-block" />
                                                        </GroupInput>
                                                        <SelectInput
                                                            name="statusverificacao"
                                                            label="Status eficácia"
                                                            className={'inline-block'}
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        />
                                                        <div className="mt-2 ml-2 bg-amarelo-eq">Eficácia</div>
                                                        <TextInput name="eficaciaplano" label="Eficácia do Plano" rows={3} />
                                                        <GroupInput>
                                                            <DBSelectInput
                                                                name="resposaveleficacia"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                            <DateInput name="dataeficacia" label="Data" className="inline-block" />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                extensao: yup.string(),
                                                investigacao: yup.string(),
                                                planoexecucao: yup.string(),
                                                alteramapeamento: yup.boolean(),
                                                alterariscos: yup.boolean(),
                                                alteracomunicacao: yup.boolean(),
                                                mudancasgq: yup.boolean(),
                                                responsavelacao: yup.string(),
                                                dataacao: yup.date(),
                                                verificacaooperacao: yup.string(),
                                                resposaveloperacao: yup.string(),
                                                dataoperacao: yup.date(),
                                                eficacaiaoperacao: yup.string(),
                                                resposaveleficacia: yup.string(),
                                                dataeficacia: yup.date(),
                                            })}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/rdc71-${item._id}`} perms={'2007001'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexo
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/rdc71-${item._id}`} disableModal={true} perms={'2007001'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/attachment/rdc71-${item._id}`);
                            await System.apiDelete(`/method5w2h/form71-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC72 = () => {
    const System = useSystemContext();
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="px-2 rounded-md">Gestão</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc72"
                        perms={'2007002'}
                        title="Auditoria da qualidade"
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestao">
                    <Grid
                        url={'/griddocument/rdc72'}
                        perms={'2007002'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Número', field: 'numero', sort: true, className: 'w-1/5 text-left pl-1' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                            { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.numero, dateToString(data.data), data.descricao ? data.descricao.substr(0, 50) : '', data.crew?.nome, `#link=${data.attachment}`];
                        }}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="numero" label="Número" />
                                        <DateInput name="data" label="Data" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <AttachmentInput name="attachment" label="Anexo" />
                                </div>
                            );
                        }}
                        onPrepareSaveRecord={(values) => {
                            let newForm = new FormData();
                            for (let key in values) {
                                newForm.append(key, values[key]);
                            }
                            return newForm;
                        }}
                        validationSchema={yup.object().shape({
                            numero: yup.string(),
                            data: yup.string(),
                            descricao: yup.string(),
                            responsavel: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // 5W2H
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/rdc72-${item._id}`} perms={'2007002'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/rdc72-${item._id}`);
                        }}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const RDC81 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <Tabs>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">🔷</div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/rdc81"
                        perms={'2008001'}
                        title={System.getMenuTitle(2008001)}
                        initialValues={{
                            compromisso: '',
                        }}
                        validationSchema={yup.object().shape({
                            compromisso: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="termos" label="Conteúdo" className="w-full" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/rdc81'} perms={'2008001'} />
                </TabPanel>
            </Tabs>
        </div>
    );
};

export const RDC91 = () => {
    const System = useSystemContext();
    function mountBars(values) {
        let ret = [];
        if (values.frequencia === 'Mensal') {
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: 'Jan' },
                { Anterior: values.avalor2, Atual: values.valor2, label: 'Fev' },
                { Anterior: values.avalor3, Atual: values.valor3, label: 'Mar' },
                { Anterior: values.avalor4, Atual: values.valor4, label: 'Abr' },
                { Anterior: values.avalor5, Atual: values.valor5, label: 'Mai' },
                { Anterior: values.avalor6, Atual: values.valor6, label: 'Jun' },
                { Anterior: values.avalor7, Atual: values.valor7, label: 'Jul' },
                { Anterior: values.avalor8, Atual: values.valor8, label: 'Ago' },
                { Anterior: values.avalor9, Atual: values.valor9, label: 'Set' },
                { Anterior: values.avalor10, Atual: values.valor10, label: 'Out' },
                { Anterior: values.avalor11, Atual: values.valor11, label: 'Nov' },
                { Anterior: values.avalor12, Atual: values.valor12, label: 'Dez' },
            ];
        }
        if (values.frequencia === 'Bimestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Bim' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Bim' },
                { Anterior: values.avalor3, Atual: values.valor3, label: '3º Bim' },
                { Anterior: values.avalor4, Atual: values.valor4, label: '4º Bim' },
                { Anterior: values.avalor5, Atual: values.valor5, label: '5º Bim' },
                { Anterior: values.avalor6, Atual: values.valor6, label: '6º Bim' },
            ];
        if (values.frequencia === 'Trimestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Tri' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Tri' },
                { Anterior: values.avalor3, Atual: values.valor3, label: '3º Tri' },
                { Anterior: values.avalor4, Atual: values.valor4, label: '4º Tri' },
            ];
        if (values.frequencia === 'Semestral')
            ret = [
                { Anterior: values.avalor1, Atual: values.valor1, label: '1º Sem' },
                { Anterior: values.avalor2, Atual: values.valor2, label: '2º Sem' },
            ];
        if (values.frequencia === 'Anual') ret = [{ Anterior: values.avalor1, Atual: values.valor1, label: 'Ano' }];
        return ret;
    }

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="">🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/rdc91"
                        perms={'2009001'}
                        title={'Requisitos gerais'}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Objectivos">
                    <Grid
                        url={'/griddocument/rdc91'}
                        perms={'2009001'}
                        clearAutoEdit={true}
                        modalHeight={'55%'}
                        modalWidth={'60%'}
                        modalMargin={'5% auto'}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-2/4 text-left pl-1' },
                            { title: 'Etapa', field: 'etapa', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Frequência', field: 'frequencia', sort: true, className: 'w-1/6 w-32 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao.substr(0, 50), data.etapa, data.frequencia, data.crew?.nome];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" className="w-full" rows="8" />
                                    <Input name="etapa" label="Etapa" />
                                    <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    <GroupInput>
                                        <SelectInput
                                            name="frequencia"
                                            label="Frequência"
                                            className={'w-1/4'}
                                            listOptions={[{ value: 'Mensal' }, { value: 'Bimestral' }, { value: 'Trimestral' }, { value: 'Semestral' }, { value: 'Anual' }]}
                                        />
                                        <SelectInput
                                            name="operacao"
                                            label="Operação"
                                            className={'w-1/3'}
                                            listOptions={[
                                                { value: 'lt', option: '< (Menor quê)' },
                                                { value: 'le', option: '<= (Menor ou igual)' },
                                                { value: 'eq', option: '= (Igual)' },
                                                { value: 'ge', option: '>= (Maior ou igual)' },
                                                { value: 'gt', option: '> (Maior quê)' },
                                            ]}
                                        />
                                        <Input name="meta" label="Meta" className={'w-44'} />
                                    </GroupInput>
                                    <Input name="unidade" label="Unidade" />
                                    <TextInput name="memoria" label="Memória de Cálculo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            etapa: yup.string(),
                            responsavel: yup.string(),
                            frequencia: yup.string(),
                            operacao: yup.string(),
                            meta: yup.string(),
                            unidade: yup.string(),
                            memoria: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Gráfico</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de bordo</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Grafico
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/rdc91/${item._id}`}
                                            perms={'2009001'}
                                            title="Gráfico"
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div>
                                                        <GroupInput>
                                                            <div className="mt-8 ml-2 w-36">Anterior</div>
                                                            {values.frequencia === 'Mensal' && (
                                                                <div className="inline-flex ml-4">
                                                                    <Input name="avalor1" label="Jan" />
                                                                    <Input name="avalor2" label="Fev" />
                                                                    <Input name="avalor3" label="Mar" />
                                                                    <Input name="avalor4" label="Abr" />
                                                                    <Input name="avalor5" label="Mai" />
                                                                    <Input name="avalor6" label="Jun" />
                                                                    <Input name="avalor7" label="Jul" />
                                                                    <Input name="avalor8" label="Ago" />
                                                                    <Input name="avalor9" label="Set" />
                                                                    <Input name="avalor10" label="Out" />
                                                                    <Input name="avalor11" label="Nov" />
                                                                    <Input name="avalor12" label="Dez" />
                                                                </div>
                                                            )}
                                                            {values.frequencia === 'Bimestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Bimestre" />
                                                                    <Input name="avalor2" label="2º Bimestre" />
                                                                    <Input name="avalor3" label="3º Bimestre" />
                                                                    <Input name="avalor4" label="4º Bimestre" />
                                                                    <Input name="avalor5" label="5º Bimestre" />
                                                                    <Input name="avalor6" label="6º Bimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Trimestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Trimestre" />
                                                                    <Input name="avalor2" label="2º Trimestre" />
                                                                    <Input name="avalor3" label="3º Trimestre" />
                                                                    <Input name="avalor4" label="4º Trimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Semestral' && (
                                                                <>
                                                                    <Input name="avalor1" label="1º Semestre" />
                                                                    <Input name="avalor2" label="2º Semestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Anual' && (
                                                                <>
                                                                    <Input name="avalor1" label="Anual" />
                                                                </>
                                                            )}
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <div className="pl-5 mt-8 w-36">Atual</div>
                                                            {values.frequencia === 'Mensal' && (
                                                                <div className="inline-flex ml-4">
                                                                    <Input name="valor1" label="Jan" />
                                                                    <Input name="valor2" label="Fev" />
                                                                    <Input name="valor3" label="Mar" />
                                                                    <Input name="valor4" label="Abr" />
                                                                    <Input name="valor5" label="Mai" />
                                                                    <Input name="valor6" label="Jun" />
                                                                    <Input name="valor7" label="Jul" />
                                                                    <Input name="valor8" label="Ago" />
                                                                    <Input name="valor9" label="Set" />
                                                                    <Input name="valor10" label="Out" />
                                                                    <Input name="valor11" label="Nov" />
                                                                    <Input name="valor12" label="Dez" />
                                                                </div>
                                                            )}
                                                            {values.frequencia === 'Bimestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Bimestre" />
                                                                    <Input name="valor2" label="2º Bimestre" />
                                                                    <Input name="valor3" label="3º Bimestre" />
                                                                    <Input name="valor4" label="4º Bimestre" />
                                                                    <Input name="valor5" label="5º Bimestre" />
                                                                    <Input name="valor6" label="6º Bimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Trimestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Trimestre" />
                                                                    <Input name="valor2" label="2º Trimestre" />
                                                                    <Input name="valor3" label="3º Trimestre" />
                                                                    <Input name="valor4" label="4º Trimestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Semestral' && (
                                                                <>
                                                                    <Input name="valor1" label="1º Semestre" />
                                                                    <Input name="valor2" label="2º Semestre" />
                                                                </>
                                                            )}
                                                            {values.frequencia === 'Anual' && (
                                                                <>
                                                                    <Input name="valor1" label="Anual" />
                                                                </>
                                                            )}
                                                        </GroupInput>
                                                        <div className="w-full h-96">
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <BarChart
                                                                    width={500}
                                                                    height={300}
                                                                    data={mountBars(values)}
                                                                    margin={{
                                                                        top: 5,
                                                                        right: 30,
                                                                        left: 20,
                                                                        bottom: 5,
                                                                    }}
                                                                >
                                                                    <CartesianGrid strokeDasharray="3 3" />
                                                                    <XAxis dataKey="label" />
                                                                    <YAxis />
                                                                    <Tooltip />
                                                                    <Legend />

                                                                    <Bar dataKey="Anterior" legendType="none" fill="#83af83" />
                                                                    <Bar dataKey="Atual" legendType="none" fill="#93c6f9" />
                                                                    <ReferenceLine
                                                                        y={values.meta}
                                                                        stroke="black"
                                                                        strokeDasharray="5 5"
                                                                        label={{ value: 'Meta', position: 'top' }}
                                                                        ifOverflow="extendDomain"
                                                                    />
                                                                </BarChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({})}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/rdc91-${item._id}`} perms={'2009001'} />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div>
                                                <GridDiario url={'/griddocument/rdc91-' + item._id} perms={'2009001'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexos
                                return (
                                    <div>
                                        <GridAnexos url={`/attachment/rdc91-${item._id}`} disableModal={true} perms={'2009001'} />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/rdc91-${item._id}`);
                            await System.apiDelete(`/attachment/rdc91-${item._id}`);
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={`/attachment/rdc91`} disableModal={true} perms={'2009001'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
