import React, { useEffect } from 'react';
import { RiLogoutBoxRLine, RiUser2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useSystemContext } from '../Context/SystemContext';

export const DisplayUser = () => {
    const System = useSystemContext();
    let navigate = useNavigate();
    const refPopUpModal = React.useRef();
    const [menuUser, setMenuUser] = React.useState(false);

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Escape') {
                event.preventDefault();

                setMenuUser(false);
            }
        };
        document.addEventListener('keydown', keyDownHandler);

        // 👇️ clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <>
            <div
                className="inline-flex px-2 py-4 cursor-pointer"
                onClick={() => {
                    //navigate('/profile', { replace: false })
                    setMenuUser(!menuUser);
                }}
            >
                <RiUser2Line className="mt-1 mr-2" /> {System.property.user?.name}
            </div>
            <div
                id="PopUpModal"
                ref={refPopUpModal}
                style={{ display: menuUser ? 'block' : 'none' }}
                tabIndex="-1"
                className="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto text-gray-700 h-modal md:inset-0 md:h-full"
                onClick={() => {
                    setMenuUser(false);
                }}
            >
                <div className="absolute right-0 max-w-2xl py-2 bg-white border shadow-md top-10 w-44 md:h-auto">
                    {System.property.user.level <= 5 && (
                        <div
                            className="inline-flex w-full pl-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => {
                                navigate('/profile', { replace: false });
                            }}
                        >
                            <RiUser2Line className="mt-1 mr-2" /> Minha conta
                        </div>
                    )}
                    {System.property.user.level > 5 && (
                        <div
                            className="inline-flex w-full pl-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => {
                                navigate('/profileConsultor', { replace: false });
                            }}
                        >
                            <RiUser2Line className="mt-1 mr-2" /> Minha conta
                        </div>
                    )}
                    <div
                        className="inline-flex w-full pl-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => {
                            System.logout();
                            navigate('/login', { replace: false });
                        }}
                    >
                        <RiLogoutBoxRLine className="mt-1 mr-2" /> Logout
                    </div>
                </div>
            </div>
        </>
    );
};
