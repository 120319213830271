import Grid from '../../components/Grid';
import { AttachmentInput, DateInput, DBSelectInput, FormInput, GroupInput, Input, SelectInput, TabsInput, TextInput } from '../../components/Input';

import { Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { v4 as uuidv4 } from 'uuid';
import GUT from '../../assets/GUT.jpeg';
import INFLUENCIA_INTERESSE from '../../assets/INFLUENCIA_INTERESSE.jpeg';
import SIPOC from '../../assets/SIPOC.jpeg';
import SWOT from '../../assets/SWOT.jpeg';
import { Grid5W2H } from '../../components/Grid5W2H';
import { GridAnexos } from '../../components/GridAnexos';
import { useSystemContext } from '../../Context/SystemContext';
import { dateToString } from '../../helper/dates';
import { GridDiario } from '../../components/GridDiario';

const yup = require('../../interface/yup-br');

export const Form41 = () => {
    const System = useSystemContext();
    function converteParaInteiro(str) {
        const numero = parseInt(str);
        return isNaN(numero) ? 0 : numero;
    }
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab key={uuidv4()}>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab key={uuidv4()}>
                        <div className="inline-flex justify-center">
                            <div className="rounded-md px-2">Gestão</div>
                        </div>
                    </Tab>
                    <Tab key={uuidv4()}>
                        <div className="inline-flex justify-center">
                            <div className="rounded-md px-2">Anexos</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form41"
                        perms={'41'}
                        title={System.getMenuTitle(41)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <img src={SWOT} alt="swot" className="h-auto w-full" />
                    <img src={GUT} alt="GUT" className="h-auto w-full" />
                </TabPanel>
                <TabPanel name="Gestão">
                    <Grid
                        url={'/griddocument/grid41'}
                        perms={'41'}
                        clearAutoEdit={true}
                        showPDFExport={true}
                        showCSVExport={true}
                        defaultSortField={'!calculado'}
                        defaultSortOrder={'desc'}
                        modalHeight={'55%'}
                        modalWidth={'60%'}
                        modalMargin={'5% auto'}
                        onAfterLoadData={(data) => {
                            return data.map((item) => {
                                item.calculado = converteParaInteiro(item.g) * converteParaInteiro(item.u) * converteParaInteiro(item.t);
                                return item;
                            });
                        }}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'SWOT', field: 'swot', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'G', field: 'g', sort: false, className: 'w-32 pl-1 text-center border-l border-gray-300' },
                            { title: 'U', field: 'u', sort: false, className: 'w-32 pl-1 text-center border-l border-gray-300' },
                            { title: 'T', field: 't', sort: false, className: 'w-32 pl-1 text-center border-l border-gray-300' },
                            { title: 'R', field: '!calculado', sort: true, className: 'w-32 pl-1 text-center border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [
                                data.descricao.substr(0, 50), //
                                data.swot, //
                                data.g, //
                                data.u, //
                                data.t, //
                                `${converteParaInteiro(data.g) * converteParaInteiro(data.u) * converteParaInteiro(data.t)}`, //
                            ];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="justify-left inline-flex h-8 w-full bg-amarelo-eq py-1 pl-2">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <TextInput name="descricao" label="Descrição" className="w-full" rows="8" />
                                    <GroupInput>
                                        <SelectInput
                                            name="swot"
                                            label="SWOT"
                                            className={'w-1/4'}
                                            listOptions={[
                                                { value: 'Forças | Interno e Positivo' },
                                                { value: 'Oportunidades | Externo e Positivo' },
                                                { value: 'Ameaças | Externo E Negativo' },
                                                { value: 'Fraquezas | interno e Negativo' },
                                            ]}
                                        />
                                        <SelectInput
                                            name="g"
                                            label="G"
                                            className="w-1/4"
                                            listOptions={[
                                                { value: '0', option: '0' },
                                                { value: '1', option: '1 - Sem gravidade' },
                                                { value: '2', option: '2 - Pouco grave' },
                                                { value: '3', option: '3 - Grave' },
                                                { value: '4', option: '4 - Muito grave' },
                                                { value: '5', option: '5 - Extremamente grave' },
                                            ]}
                                        />
                                        <SelectInput
                                            name="u"
                                            label="U"
                                            className="w-1/4"
                                            listOptions={[
                                                { value: '0' },
                                                { value: '1', option: '1 - Pode esperar' },
                                                { value: '2', option: '2 - Pouco urgente' },
                                                { value: '3', option: '3 - O mais rápido possivel' },
                                                { value: '4', option: '4 - É urgente' },
                                                { value: '5', option: '5 - Precisa ser resolvido já' },
                                            ]}
                                        />
                                        <SelectInput
                                            name="t"
                                            label="T"
                                            className="w-1/4"
                                            listOptions={[
                                                { value: '0', option: '0' },
                                                { value: '1', option: '1 - Não mudar nada' },
                                                { value: '2', option: '2 - Piorar em longo prazo' },
                                                { value: '3', option: '3 - Piorar em médio prazo' },
                                                { value: '4', option: '4 - Piorar em curto prazo' },
                                                { value: '5', option: '5 - Piorar rapidamente' },
                                            ]}
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/form41-${item._id}`} perms="41" />
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete(`/method5w2h/form41-${item._id}`);
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            swot: yup.string(),
                            g: yup.string().min(0).max(5),
                            u: yup.string().min(0).max(5),
                            t: yup.string().min(0).max(5),
                            r: yup.string().min(0).max(5),
                            ordem: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form41'} perms={'41'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form42 = () => {
    const System = useSystemContext();
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="rounded-md px-2">Gestão</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="rounded-md px-2">Anexos</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/form42"
                        perms={'42'}
                        title={System.getMenuTitle(42)}
                        initialValues={{
                            necessidades: '',
                        }}
                        validationSchema={yup.object().shape({
                            normas: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="necessidades" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <img src={INFLUENCIA_INTERESSE} alt="INFLUENCIA_INTERESSE" className="h-auto w-full" />
                </TabPanel>
                <TabPanel>
                    <Grid
                        url={'/griddocument/grid42'}
                        perms={'42'}
                        clearAutoEdit={true}
                        modalHeight={'85%'}
                        modalWidth={'60%'}
                        modalMargin={'2% auto'}
                        modalHeightAdditional={'95%'}
                        modalWidthAdditional={'70%'}
                        modalMarginAdditional={'1% auto'}
                        GridHeaders={[
                            { title: 'Parte interessada', field: 'parteinteressada', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Influência X Interesse', field: 'influenciainteresse', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data?.parteinteressada ? data.parteinteressada.substr(0, 50) : '', data?.influenciainteresse];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="justify-left inline-flex h-8 w-full bg-amarelo-eq py-1 pl-2">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="parteinteressada" label="Parte Interessada" />
                                    <SelectInput
                                        name="influenciainteresse"
                                        label="Influência x Interesse"
                                        className={'w-3/6'}
                                        listOptions={[
                                            { value: 'Manter Satisfeito', option: 'Manter Satisfeito' },
                                            { value: 'Monitorar', option: 'Monitorar' },
                                            { value: 'Manter Informado', option: 'Manter Informado' },
                                            { value: 'Gerenciar de perto', option: 'Gerenciar de perto' },
                                        ]}
                                    />
                                    <TextInput name="necessidades" label="Necessidades" mask="4" />
                                    <TextInput name="expectativas" label="Expectativas" mask="4" />
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>5W2H</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        <Grid5W2H url={`/method5w2h/form42-${item._id}`} perms="42" />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid42-' + item._id} perms={'42'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid42-' + item._id);
                            await System.apiDelete(`/method5w2h/form42-${item._id}`);
                        }}
                        validationSchema={yup.object().shape({
                            parteinteressada: yup.string(),
                            incluenciainteresse: yup.string(),
                            necessidades: yup.string(),
                            expectativas: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/form42'} perms={'42'} />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form43 = () => {
    const System = useSystemContext();
    return (
        <div className="w-full">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="rounded-md px-2">Gestão</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/form43"
                        perms={'43'}
                        title={System.getMenuTitle(43)}
                        initialValues={{
                            necessidades: '',
                        }}
                        validationSchema={yup.object().shape({
                            normas: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="necessidades" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <FormInput
                        document="/document/form43"
                        perms={'43'}
                        validationSchema={yup.object().shape({
                            escopo: yup.string(),
                            requisito: yup.string(),
                            justificativa: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div>
                                    <TextInput name="escopo" label="Escopo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <Grid
                        url={'/griddocument/grid43'}
                        perms="43"
                        clearAutoEdit={true}
                        GridTitle="Exclusões"
                        GridHeaders={[
                            { title: 'Requisito', field: 'requisito', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Justificativa', field: 'justificativa', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.requisito, data.justificativa];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <Input name="requisito" label="Requisito" />
                                    <Input name="justificativa" label="Justificativa" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            requisito: yup.string(),
                            justificativa: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form44 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div className="rounded-md px-2">Anexos</div>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form44"
                        perms={'44'}
                        title={System.getMenuTitle(44)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            normas: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                    <img src={SIPOC} alt="SIPOC" className="h-auto w-full" />
                </TabPanel>
                <TabPanel name="Mapeamento de Processos">
                    <div className="h-80 w-full">
                        <Grid
                            url={'/griddocument/grid44'}
                            perms={'44'}
                            clearAutoEdit={true}
                            GridHeaders={[
                                { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/12 text-left pl-1' },
                                { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Processo', field: 'processo', sort: true, className: 'w-6/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Data', field: 'data', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                { title: 'Anexo', field: 'attachment', sort: false, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            ]}
                            LineDataPrint={(data) => {
                                return [data.descricao, data.tipo, data.processo?.substring(0, 90), dateToString(data.data), data.crew?.nome, `#link=${data.attachment}`];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <div className="justify-left inline-flex h-8 w-full bg-amarelo-eq py-1 pl-2">
                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                        </div>
                                        <GroupInput>
                                            <Input name="descricao" label="Descrição" />
                                            <SelectInput
                                                name="tipo"
                                                label="Tipo"
                                                className={'w-1/3'}
                                                listOptions={[{ value: 'Realização' }, { value: 'Gestão' }, { value: 'Apoio' }, { value: 'Terceiro' }]}
                                            />
                                        </GroupInput>
                                        <GroupInput>
                                            <Input name="subprocesso" label="Sub-Processo" />
                                        </GroupInput>
                                        <TextInput name="dadosentrada" label="Dados de Entrada" />
                                        <TextInput name="processo" label="Processo" />
                                        <TextInput name="dadossaida" label="Dados de Saida" />
                                        <TextInput name="riscosoportunidades" label="Riscos e Oportunidades" rows={3} />
                                        <TextInput name="metrologia" label="Metrologia" rows={3} />
                                        <TextInput name="recursos" label="Recursos Humanos" rows={3} />
                                        <TextInput name="ambiente" label="Ambiente de Trabalho" rows={3} />
                                        <TextInput name="infraestrutura" label="Infraestrutura" rows={3} />
                                        <TextInput name="indicadores" label="Indicadores" rows={3} />
                                        <TextInput name="pontoscriticos" label="Pontos Críticos" rows={3} />
                                        <TextInput name="informacao" label="Informação Documentada" rows={3} />
                                        <TextInput name="residuos" label="Gestão de Resíduos" rows={3} />

                                        <GroupInput>
                                            <DateInput name="data" label="Data" className={'w-1/3'} />
                                            <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                        </GroupInput>
                                    </div>
                                );
                            }}
                            FormTabs={[
                                () => {
                                    return <div>Home</div>;
                                },
                                () => {
                                    return (
                                        <div className="inline-flex justify-center">
                                            <span>Anexos</span>
                                        </div>
                                    );
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <FormInput
                                            document={`/griddocument/grid44/${item._id}`}
                                            perms={'44'}
                                            validationSchema={yup.object().shape({
                                                arquivo: yup.string(),
                                            })}
                                            onAfterSave={() => {
                                                GridRefresh();
                                            }}
                                            onRenderFields={(setFieldValue, values, callCommit) => {
                                                return <AttachmentInput name="attachment" label="Anexo" callParentCommit={callCommit} perms="44" />;
                                            }}
                                        />
                                    );
                                },
                            ]}
                            onBeforeDelete={async (item) => {
                                await System.apiDelete('/griddocument/grid44-' + item._id);
                            }}
                            validationSchema={yup.object().shape({
                                descricao: yup.string(),
                                tipo: yup.string(),
                                subprocesso: yup.string(),
                                dadosentrada: yup.string(),
                                processo: yup.string(),
                                dadossaida: yup.string(),
                                metrologia: yup.string(),
                                recursos: yup.string(),
                                ambiente: yup.string(),
                                infraestrutura: yup.string(),
                                indicadores: yup.string(),
                                pontoscriticos: yup.string(),
                                informacao: yup.string(),
                                residuos: yup.string(),
                                status: yup.string(),
                                data: yup.string(),
                                responsavel: yup.string(),
                            })}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/form44'} perms="44" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
