import Header from './Header';

// {process.env.REACT_APP_VERSION}

const Layout = ({ children, ...rest }) => {
    let revision = process.env.REACT_APP_VERSION.split('.').pop();
    return (
        <div className="min-w-screen -mb-12 bg-yellow-50">
            <Header />
            <div className="block">
                <div className="">
                    <div className="flex">{children}</div>
                </div>
                <div className="ext-xs inset-x-0 bottom-0 flex items-center justify-center">
                    <div className="h-4"></div>
                </div>
            </div>
            <div className="fixed inset-x-0 bottom-0 flex items-center justify-center text-xs">
                <div className="inline-flex rounded-t border-t border-l border-r border-gray-300 bg-yellow-50 px-2">
                    Direitos reservados à Kom Tecnologia - CNPJ: 29.508.924/0001-85 - Versão: 1.2 - #{revision}{' '}
                </div>
            </div>
        </div>
    );
};

export default Layout;
